/*theam btn start */
.theam_btn{
	display:inline-block;
	color:#fff;
	padding:10px 30px;
	border:2px solid #fff;
}
.theam_btn:hover{
	color:#fff;
}
/*theam btn end */
.theam_btn.btn2{
	padding:10px 75px;
	font-size:18px;
	border:none;
}
/*overlay start */
.overlay{
	position:relative;
}
.overlay:before{
	position:absolute;
	content:"";
	top:0px;
	left:0px;
	right:0px;
	bottom:0px;
	background-color:#000;
	opacity:0.6;
}
/*overlay end */

/*section heading start*/
.section_heading{
	float:left;
	width:100%;
	padding-left:20px;
	border-left:4px solid; 
	line-height: 15px;
	position:relative;
	margin-bottom:22px;
}
.section_heading span{
	line-height: 20px;
	margin: 0px 0px 0px;
	display: inline-block;
	position: relative;
	top:-4px;
}
.section_heading h2{
	margin:0px 0px;
	line-height:35px;
	font-weight:bold;
}
.border{
	position:relative;
}
.border:after,
.border:before{
	position:absolute;
	content:"";
	top:0px;
	left:auto;
	right:0px;
	bottom:0px;
	border-bottom:1px solid #cccccc;
	width:310px;
}
.border:after{
	bottom:5px;
}
/*section heading end*/
.section_heading h3{
	margin:0px 0px;
	font-weight:600;
	line-height: 22px;
}
/*heading full start*/
.heding_full{
	float:left;
	width:100%;
	margin-bottom:35px;
}
.heding_full .section_heading{
	margin-bottom:17px;
}
.heding_full > p{
	margin:0px 0px;
}
/*heading full end*/

/*section heading center start*/
.section_heading.center{
	text-align:center;
	border:none;
	position:relative;
	padding-bottom:34px;
	margin-bottom:35px;
}
.section_heading.center:before{
	position:absolute;
	content:"";
	top:0px;
	left:0px;
	right:0px;
	bottom:0px;
	border-bottom:4px solid;
	width:48px;
	margin:auto;
}
/* slick slider start */
.slick-prev,
.slick-next{
	top:50%;
	height:44px;
	width:44px;
	text-align:center;
	border:1px solid #c2c2c2;
}
.slick-prev:before,
.slick-next:before{
	line-height:44px;
	content:"\e94e";
	color:#a3a3a3;
	font-family: 'icomoon' !important;
}
.slick-prev:before{
	content:"\e94c";
}
/* slick slider start */
.margin0{
	margin-bottom:0px !important;
}
/* event heading start */
.event_heading{
	line-height:24px;
	font-weight:500;
	padding-left:22px;
	border-left:3px solid;
	margin-bottom:20px;
}