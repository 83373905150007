@charset "UTF-8";
/*
 * ImageHover.css - http://www.imagehover.io
 * Version 1.0
 * Author: Ciarán Walsh

 * Copyright (C) Imagehover.io

 */
[class^='imghvr-'], [class*=' imghvr-'] {
  position: relative;
  display: inline-block;
  margin: 0px;
  max-width: 100%;
  background-color: #2266a5;
  color: #fff;
  overflow: hidden;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  -moz-osx-font-smoothing: grayscale;
  -webkit-transform: translateZ(0);
  transform: translateZ(0);
}

[class^='imghvr-'] > img, [class*=' imghvr-'] > img {
  vertical-align: top;
  max-width: 100%;
}

[class^='imghvr-'] .hover-theme, [class*=' imghvr-'] .hover-theme {
  background-color: #135796;
  position: absolute;
  padding: 30px;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  color: #ffffff !important;
}

[class^='imghvr-'] .hover-theme h1, [class^='imghvr-'] .hover-theme h2, [class^='imghvr-'] .hover-theme h3, [class^='imghvr-'] .hover-theme h4, [class^='imghvr-'] .hover-theme h5, [class^='imghvr-'] .hover-theme h6, [class*=' imghvr-'] .hover-theme h1, [class*=' imghvr-'] .hover-theme h2, [class*=' imghvr-'] .hover-theme h3, [class*=' imghvr-'] .hover-theme h4, [class*=' imghvr-'] .hover-theme h5, [class*=' imghvr-'] .hover-theme h6 {
  color: #ffffff !important;
}


[class^='imghvr-'], [class*=' imghvr-'], [class^='imghvr-']:before,
[class^='imghvr-']:after, [class*=' imghvr-']:before, [class*=' imghvr-']:after,
[class^='imghvr-'] *, [class*=' imghvr-'] *, [class^='imghvr-'] *:before,
[class^='imghvr-'] *:after, [class*=' imghvr-'] *:before, [class*=' imghvr-'] *:after {
  box-sizing: border-box;
  -webkit-transition: all 0.35s ease;
  transition: all 0.35s ease;
}

/* imghvr-fade-*
   ----------------------------- */
[class^='imghvr-fade'] .hover-theme, [class*=' imghvr-fade'] .hover-theme {
  opacity: 0;
}

[class^='imghvr-fade']:hover > img, [class*=' imghvr-fade']:hover > img {
  opacity: 0;
}

[class^='imghvr-fade']:hover .hover-theme, [class*=' imghvr-fade']:hover .hover-theme {
  opacity: 1;
}

[class^='imghvr-fade']:hover > img, [class^='imghvr-fade']:hover .hover-theme, [class*=' imghvr-fade']:hover > img, [class*=' imghvr-fade']:hover .hover-theme {
  -webkit-transform: translate(0, 0);
  transform: translate(0, 0);
}

/* imghvr-fade
   ----------------------------- */
/* imghvr-fade-in-up
   ----------------------------- */
.imghvr-fade-in-up .hover-theme {
  -webkit-transform: translate(0, 15%);
  transform: translate(0, 15%);
}

/* imghvr-fade-in-down
   ----------------------------- */
.imghvr-fade-in-down .hover-theme {
  -webkit-transform: translate(0, -15%);
  transform: translate(0, -15%);
}

/* imghvr-fade-in-left
   ----------------------------- */
.imghvr-fade-in-left .hover-theme {
  -webkit-transform: translate(-15%, 0);
  transform: translate(-15%, 0);
}

/* imghvr-fade-in-right
   ----------------------------- */
.imghvr-fade-in-right .hover-theme {
  -webkit-transform: translate(15%, 0);
  transform: translate(15%, 0);
}

/* imghvr-push-*
   ----------------------------- */
[class^='imghvr-push-']:hover .hover-theme, [class*=' imghvr-push-']:hover .hover-theme {
  -webkit-transform: translate(0, 0);
  transform: translate(0, 0);
}

/* imghvr-push-up
   ----------------------------- */
.imghvr-push-up .hover-theme {
  -webkit-transform: translateY(100%);
  transform: translateY(100%);
}

.imghvr-push-up:hover > img {
  -webkit-transform: translateY(-100%);
  transform: translateY(-100%);
}

/* imghvr-push-down
   ----------------------------- */
.imghvr-push-down .hover-theme {
  -webkit-transform: translateY(-100%);
  transform: translateY(-100%);
}

.imghvr-push-down:hover > img {
  -webkit-transform: translateY(100%);
  transform: translateY(100%);
}

/* imghvr-push-left
   ----------------------------- */
.imghvr-push-left .hover-theme {
  -webkit-transform: translateX(100%);
  transform: translateX(100%);
}

.imghvr-push-left:hover > img {
  -webkit-transform: translateX(-100%);
  transform: translateX(-100%);
}

/* imghvr-push--right
   ----------------------------- */
.imghvr-push-right .hover-theme {
  -webkit-transform: translateX(-100%);
  transform: translateX(-100%);
}

.imghvr-push-right:hover > img {
  -webkit-transform: translateX(100%);
  transform: translateX(100%);
}

/* imghvr-slide-*
   ----------------------------- */
[class^='imghvr-slide-']:hover .hover-theme, [class*=' imghvr-slide-']:hover .hover-theme {
  -webkit-transform: translate(0, 0);
  transform: translate(0, 0);
}

/* imghvr-slide-up
   ----------------------------- */
.imghvr-slide-up .hover-theme {
  -webkit-transform: translateY(100%);
  transform: translateY(100%);
}

/* imghvr-slide-down
   ----------------------------- */
.imghvr-slide-down .hover-theme {
  -webkit-transform: translateY(-100%);
  transform: translateY(-100%);
}

/* imghvr-slide-left
   ----------------------------- */
.imghvr-slide-left .hover-theme {
  -webkit-transform: translateX(100%);
  transform: translateX(100%);
}

/* imghvr-slide-right
   ----------------------------- */
.imghvr-slide-right .hover-theme {
  -webkit-transform: translateX(-100%);
  transform: translateX(-100%);
}

/* imghvr-slide-top-left
   ----------------------------- */
.imghvr-slide-top-left .hover-theme {
  -webkit-transform: translate(-100%, -100%);
  transform: translate(-100%, -100%);
}

/* imghvr-slide-top-right
   ----------------------------- */
.imghvr-slide-top-right .hover-theme {
  -webkit-transform: translate(100%, -100%);
  transform: translate(100%, -100%);
}

/* imghvr-slide-bottom-left
   ----------------------------- */
.imghvr-slide-bottom-left .hover-theme {
  -webkit-transform: translate(-100%, 100%);
  transform: translate(-100%, 100%);
}

/* imghvr-slide-bottom-right
   ----------------------------- */
.imghvr-slide-bottom-right .hover-theme {
  -webkit-transform: translate(100%, 100%);
  transform: translate(100%, 100%);
}

/* imghvr-reveal-*
   ----------------------------- */
[class^='imghvr-reveal-']:before, [class*=' imghvr-reveal-']:before {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  content: '';
  background-color: #135796;
}

[class^='imghvr-reveal-'] .hover-theme, [class*=' imghvr-reveal-'] .hover-theme {
  opacity: 0;
}

[class^='imghvr-reveal-']:hover:before, [class*=' imghvr-reveal-']:hover:before {
  -webkit-transform: translate(0, 0);
  transform: translate(0, 0);
}

[class^='imghvr-reveal-']:hover .hover-theme, [class*=' imghvr-reveal-']:hover .hover-theme {
  opacity: 1;
  -webkit-transition-delay: 0.21s;
  transition-delay: 0.21s;
}

/* imghvr-reveal-up
   ----------------------------- */
.imghvr-reveal-up:before {
  -webkit-transform: translateY(100%);
  transform: translateY(100%);
}

/* imghvr-reveal-down
   ----------------------------- */
.imghvr-reveal-down:before {
  -webkit-transform: translateY(-100%);
  transform: translateY(-100%);
}

/* imghvr-reveal-left
   ----------------------------- */
.imghvr-reveal-left:before {
  -webkit-transform: translateX(100%);
  transform: translateX(100%);
}

/* imghvr-reveal-right
   ----------------------------- */
.imghvr-reveal-right:before {
  -webkit-transform: translateX(-100%);
  transform: translateX(-100%);
}

/* imghvr-reveal-top-left
   ----------------------------- */
.imghvr-reveal-top-left:before {
  -webkit-transform: translate(-100%, -100%);
  transform: translate(-100%, -100%);
}

/* imghvr-reveal-top-right
   ----------------------------- */
.imghvr-reveal-top-right:before {
  -webkit-transform: translate(100%, -100%);
  transform: translate(100%, -100%);
}

/* imghvr-reveal-bottom-left
   ----------------------------- */
.imghvr-reveal-bottom-left:before {
  -webkit-transform: translate(-100%, 100%);
  transform: translate(-100%, 100%);
}

/* imghvr-reveal-bottom-right
   ----------------------------- */
.imghvr-reveal-bottom-right:before {
  -webkit-transform: translate(100%, 100%);
  transform: translate(100%, 100%);
}

/* imghvr-hinge-*
   ----------------------------- */
[class^='imghvr-hinge-'], [class*=' imghvr-hinge-'] {
  -webkit-perspective: 50em;
  perspective: 50em;
}

[class^='imghvr-hinge-'] .hover-theme, [class*=' imghvr-hinge-'] .hover-theme {
  opacity: 0;
  z-index: 1;
}

[class^='imghvr-hinge-']:hover img, [class*=' imghvr-hinge-']:hover img {
  opacity: 0;
}

[class^='imghvr-hinge-']:hover .hover-theme, [class*=' imghvr-hinge-']:hover .hover-theme {
  opacity: 1;
  -webkit-transition-delay: 0.21s;
  transition-delay: 0.21s;
}

/* imghvr-hinge-up
   ----------------------------- */
.imghvr-hinge-up img {
  -webkit-transform-origin: 50% 0%;
  transform-origin: 50% 0%;
}

.imghvr-hinge-up .hover-theme {
  -webkit-transform: rotateX(90deg);
  transform: rotateX(90deg);
  -webkit-transform-origin: 50% 100%;
  transform-origin: 50% 100%;
}

.spa_product_item:hover .imghvr-hinge-up > img {
  -webkit-transform: rotateX(-90deg);
  transform: rotateX(-90deg);
}

.spa_product_item:hover .imghvr-hinge-up > .hover-theme {
  -webkit-transform: rotateX(0);
  transform: rotateX(0);
}

/* imghvr-hinge-down
   ----------------------------- */
.imghvr-hinge-down img {
  -webkit-transform-origin: 50% 100%;
  transform-origin: 50% 100%;
}

.imghvr-hinge-down .hover-theme {
  -webkit-transform: rotateX(-90deg);
  transform: rotateX(-90deg);
  -webkit-transform-origin: 50% -50%;
  transform-origin: 50% -50%;
}

.spa_product_item:hover .imghvr-hinge-down > img {
  -webkit-transform: rotateX(90deg);
  transform: rotateX(90deg);
  opacity: 0;
}

.spa_product_item:hover .imghvr-hinge-down > .hover-theme {
  -webkit-transform: rotateX(0);
  transform: rotateX(0);
}

/* imghvr-hinge-left
   ----------------------------- */
.imghvr-hinge-left img {
  -webkit-transform-origin: 0% 50%;
  transform-origin: 0% 50%;
}

.imghvr-hinge-left .hover-theme {
  -webkit-transform: rotateY(-90deg);
  transform: rotateY(-90deg);
  -webkit-transform-origin: 100% 50%;
  transform-origin: 100% 50%;
}

.spa_product_item:hover .imghvr-hinge-left > img {
  -webkit-transform: rotateY(90deg);
  transform: rotateY(90deg);
}

.spa_product_item:hover .imghvr-hinge-left > .hover-theme {
  -webkit-transform: rotateY(0);
  transform: rotateY(0);
}

/* imghvr-hinge-right
   ----------------------------- */
.imghvr-hinge-right img {
  -webkit-transform-origin: 100% 50%;
  transform-origin: 100% 50%;
}

.imghvr-hinge-right .hover-theme {
  -webkit-transform: rotateY(90deg);
  transform: rotateY(90deg);
  -webkit-transform-origin: 0 50%;
  transform-origin: 0 50%;
}

.spa_product_item:hover .imghvr-hinge-right > img {
  -webkit-transform: rotateY(-90deg);
  transform: rotateY(-90deg);
}

.spa_product_item:hover .imghvr-hinge-right > .hover-theme {
  -webkit-transform: rotateY(0);
  transform: rotateY(0);
}

/* imghvr-flip-*
   ----------------------------- */
[class^='imghvr-flip-'], [class*=' imghvr-flip-'] {
  -webkit-perspective: 50em;
  perspective: 50em;
}

[class^='imghvr-flip-'] img, [class*=' imghvr-flip-'] img {
  backface-visibility: hidden;
}

[class^='imghvr-flip-'] .hover-theme, [class*=' imghvr-flip-'] .hover-theme {
  opacity: 0;
}

[class^='imghvr-flip-']:hover > img, [class*=' imghvr-flip-']:hover > img {
  opacity: 0;
}

[class^='imghvr-flip-']:hover .hover-theme, [class*=' imghvr-flip-']:hover .hover-theme {
  opacity: 1;
  -webkit-transition-delay: 0.14s;
  transition-delay: 0.14s;
}

/* imghvr-flip-horiz
   ----------------------------- */
.imghvr-flip-horiz .hover-theme {
  -webkit-transform: rotateX(90deg);
  transform: rotateX(90deg);
  -webkit-transform-origin: 0% 50%;
  transform-origin: 0% 50%;
}

.imghvr-flip-horiz:hover img {
  -webkit-transform: rotateX(-180deg);
  transform: rotateX(-180deg);
}

.imghvr-flip-horiz:hover .hover-theme {
  -webkit-transform: rotateX(0deg);
  transform: rotateX(0deg);
}

/* imghvr-flip-vert
   ----------------------------- */
.imghvr-flip-vert .hover-theme {
  -webkit-transform: rotateY(90deg);
  transform: rotateY(90deg);
  -webkit-transform-origin: 50% 0%;
  transform-origin: 50% 0%;
}

.imghvr-flip-vert:hover > img {
  -webkit-transform: rotateY(-180deg);
  transform: rotateY(-180deg);
}

.imghvr-flip-vert:hover .hover-theme {
  -webkit-transform: rotateY(0deg);
  transform: rotateY(0deg);
}

/* imghvr-flip-diag-1
   ----------------------------- */
.imghvr-flip-diag-1 .hover-theme {
  -webkit-transform: rotate3d(1, -1, 0, 100deg);
  transform: rotate3d(1, -1, 0, 100deg);
}

.imghvr-flip-diag-1:hover > img {
  -webkit-transform: rotate3d(-1, 1, 0, 100deg);
  transform: rotate3d(-1, 1, 0, 100deg);
}

.imghvr-flip-diag-1:hover .hover-theme {
  -webkit-transform: rotate3d(0, 0, 0, 0deg);
  transform: rotate3d(0, 0, 0, 0deg);
}

/* imghvr-flip-diag-2
   ----------------------------- */
.imghvr-flip-diag-2 .hover-theme {
  -webkit-transform: rotate3d(1, 1, 0, 100deg);
  transform: rotate3d(1, 1, 0, 100deg);
}

.imghvr-flip-diag-2:hover > img {
  -webkit-transform: rotate3d(-1, -1, 0, 100deg);
  transform: rotate3d(-1, -1, 0, 100deg);
}

.imghvr-flip-diag-2:hover .hover-theme {
  -webkit-transform: rotate3d(0, 0, 0, 0deg);
  transform: rotate3d(0, 0, 0, 0deg);
}

/* imghvr-shutter-out-*
   ----------------------------- */
[class^='imghvr-shutter-out-']:before, [class*=' imghvr-shutter-out-']:before {
  background: #135796;
  position: absolute;
  content: '';
  -webkit-transition-delay: 0.105s;
  transition-delay: 0.105s;
}

[class^='imghvr-shutter-out-'] .hover-theme, [class*=' imghvr-shutter-out-'] .hover-theme {
  opacity: 0;
  -webkit-transition-delay: 0s;
  transition-delay: 0s;
}

[class^='imghvr-shutter-out-']:hover:before, [class*=' imghvr-shutter-out-']:hover:before {
  -webkit-transition-delay: 0s;
  transition-delay: 0s;
}

[class^='imghvr-shutter-out-']:hover .hover-theme, [class*=' imghvr-shutter-out-']:hover .hover-theme {
  opacity: 1;
  -webkit-transition-delay: 0.105s;
  transition-delay: 0.105s;
}

/* imghvr-shutter-out-horiz
   ----------------------------- */
.imghvr-shutter-out-horiz:before {
  left: 50%;
  right: 50%;
  top: 0;
  bottom: 0;
}

.imghvr-shutter-out-horiz:hover:before {
  left: 0;
  right: 0;
}

/* imghvr-shutter-out-vert
   ----------------------------- */
.imghvr-shutter-out-vert:before {
  top: 50%;
  bottom: 50%;
  left: 0;
  right: 0;
}

.imghvr-shutter-out-vert:hover:before {
  top: 0;
  bottom: 0;
}

/* imghvr-shutter-out-diag-1
   ----------------------------- */
.imghvr-shutter-out-diag-1:before {
  top: 50%;
  bottom: 50%;
  left: -35%;
  right: -35%;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
}

.imghvr-shutter-out-diag-1:hover:before {
  top: -35%;
  bottom: -35%;
}

/* imghvr-shutter-out-diag-2
   ----------------------------- */
.imghvr-shutter-out-diag-2:before {
  top: 50%;
  bottom: 50%;
  left: -35%;
  right: -35%;
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
}

.imghvr-shutter-out-diag-2:hover:before {
  top: -35%;
  bottom: -35%;
}

/* imghvr-shutter-in-*
   ----------------------------- */
[class^='imghvr-shutter-in-']:after, [class^='imghvr-shutter-in-']:before, [class*=' imghvr-shutter-in-']:after, [class*=' imghvr-shutter-in-']:before {
  background: #135796;
  position: absolute;
  content: '';
}

[class^='imghvr-shutter-in-']:after, [class*=' imghvr-shutter-in-']:after {
  top: 0;
  left: 0;
}

[class^='imghvr-shutter-in-']:before, [class*=' imghvr-shutter-in-']:before {
  right: 0;
  bottom: 0;
}

[class^='imghvr-shutter-in-'] .hover-theme, [class*=' imghvr-shutter-in-'] .hover-theme {
  opacity: 0;
  z-index: 1;
}

[class^='imghvr-shutter-in-']:hover .hover-theme, [class*=' imghvr-shutter-in-']:hover .hover-theme {
  opacity: 1;
  -webkit-transition-delay: 0.21s;
  transition-delay: 0.21s;
}

/* imghvr-shutter-in-horiz
   ----------------------------- */
.imghvr-shutter-in-horiz:after, .imghvr-shutter-in-horiz:before {
  width: 0;
  height: 100%;
}

.imghvr-shutter-in-horiz:hover:after, .imghvr-shutter-in-horiz:hover:before {
  width: 50%;
}

/* imghvr-shutter-in-vert
   ----------------------------- */
.imghvr-shutter-in-vert:after, .imghvr-shutter-in-vert:before {
  height: 0;
  width: 100%;
}

.imghvr-shutter-in-vert:hover:after, .imghvr-shutter-in-vert:hover:before {
  height: 50%;
}

/* imghvr-shutter-in-out-horiz
   ----------------------------- */
.imghvr-shutter-in-out-horiz:after, .imghvr-shutter-in-out-horiz:before {
  width: 0;
  height: 100%;
  opacity: 0.75;
}

.imghvr-shutter-in-out-horiz:hover:after, .imghvr-shutter-in-out-horiz:hover:before {
  width: 100%;
}

/* imghvr-shutter-in-out-vert
   ----------------------------- */
.imghvr-shutter-in-out-vert:after, .imghvr-shutter-in-out-vert:before {
  height: 0;
  width: 100%;
  opacity: 0.75;
}

.imghvr-shutter-in-out-vert:hover:after, .imghvr-shutter-in-out-vert:hover:before {
  height: 100%;
}

/* imghvr-shutter-in-out-diag-1
   ----------------------------- */
.imghvr-shutter-in-out-diag-1:after, .imghvr-shutter-in-out-diag-1:before {
  width: 200%;
  height: 200%;
  -webkit-transition: all 0.6s ease;
  transition: all 0.6s ease;
  opacity: 0.75;
}

.imghvr-shutter-in-out-diag-1:after {
  -webkit-transform: skew(-45deg) translateX(-150%);
  transform: skew(-45deg) translateX(-150%);
}

.imghvr-shutter-in-out-diag-1:before {
  -webkit-transform: skew(-45deg) translateX(150%);
  transform: skew(-45deg) translateX(150%);
}

.imghvr-shutter-in-out-diag-1:hover:after {
  -webkit-transform: skew(-45deg) translateX(-50%);
  transform: skew(-45deg) translateX(-50%);
}

.imghvr-shutter-in-out-diag-1:hover:before {
  -webkit-transform: skew(-45deg) translateX(50%);
  transform: skew(-45deg) translateX(50%);
}

/* imghvr-shutter-in-out-diag-2
   ----------------------------- */
.imghvr-shutter-in-out-diag-2:after, .imghvr-shutter-in-out-diag-2:before {
  width: 200%;
  height: 200%;
  -webkit-transition: all 0.6s ease;
  transition: all 0.6s ease;
  opacity: 0.75;
}

.imghvr-shutter-in-out-diag-2:after {
  -webkit-transform: skew(45deg) translateX(-100%);
  transform: skew(45deg) translateX(-100%);
}

.imghvr-shutter-in-out-diag-2:before {
  -webkit-transform: skew(45deg) translateX(100%);
  transform: skew(45deg) translateX(100%);
}

.imghvr-shutter-in-out-diag-2:hover:after {
  -webkit-transform: skew(45deg) translateX(0%);
  transform: skew(45deg) translateX(0%);
}

.imghvr-shutter-in-out-diag-2:hover:before {
  -webkit-transform: skew(45deg) translateX(0%);
  transform: skew(45deg) translateX(0%);
}

/* imghvr-fold*
   ----------------------------- */
[class^='imghvr-fold'], [class*=' imghvr-fold'] {
  -webkit-perspective: 50em;
  perspective: 50em;
}

[class^='imghvr-fold'] img, [class*=' imghvr-fold'] img {
  -webkit-transform-origin: 50% 0%;
  transform-origin: 50% 0%;
}

[class^='imghvr-fold'] .hover-theme, [class*=' imghvr-fold'] .hover-theme {
  z-index: 1;
  opacity: 0;
}

[class^='imghvr-fold']:hover > img, [class*=' imghvr-fold']:hover > img {
  opacity: 0;
  -webkit-transition-delay: 0;
  transition-delay: 0;
}

[class^='imghvr-fold']:hover .hover-theme, [class*=' imghvr-fold']:hover .hover-theme {
  -webkit-transform: rotateX(0) translate3d(0, 0%, 0) scale(1);
  transform: rotateX(0) translate3d(0, 0%, 0) scale(1);
  opacity: 1;
  -webkit-transition-delay: 0.21s;
  transition-delay: 0.21s;
}

/* imghvr-fold-up
   ----------------------------- */
.imghvr-fold-up > img {
  -webkit-transform-origin: 50% 0%;
  transform-origin: 50% 0%;
}

.imghvr-fold-up .hover-theme {
  -webkit-transform: rotateX(-90deg) translate3d(0%, -50%, 0) scale(0.6);
  transform: rotateX(-90deg) translate3d(0%, -50%, 0) scale(0.6);
  -webkit-transform-origin: 50% 100%;
  transform-origin: 50% 100%;
}

.imghvr-fold-up:hover > img {
  -webkit-transform: rotateX(90deg) scale(0.6) translateY(50%);
  transform: rotateX(90deg) scale(0.6) translateY(50%);
}

/* imghvr-fold-down
   ----------------------------- */
.imghvr-fold-down > img {
  -webkit-transform-origin: 50% 100%;
  transform-origin: 50% 100%;
}

.imghvr-fold-down .hover-theme {
  -webkit-transform: rotateX(90deg) translate3d(0%, 50%, 0) scale(0.6);
  transform: rotateX(90deg) translate3d(0%, 50%, 0) scale(0.6);
  -webkit-transform-origin: 50% 0%;
  transform-origin: 50% 0%;
}

.imghvr-fold-down:hover > img {
  -webkit-transform: rotateX(-90deg) scale(0.6) translateY(-50%);
  transform: rotateX(-90deg) scale(0.6) translateY(-50%);
}

/* imghvr-fold-left
   ----------------------------- */
.imghvr-fold-left > img {
  -webkit-transform-origin: 0% 50%;
  transform-origin: 0% 50%;
}

.imghvr-fold-left .hover-theme {
  -webkit-transform: rotateY(90deg) translate3d(-50%, 0%, 0) scale(0.6);
  transform: rotateY(90deg) translate3d(-50%, 0%, 0) scale(0.6);
  -webkit-transform-origin: 100% 50%;
  transform-origin: 100% 50%;
}

.imghvr-fold-left:hover > img {
  -webkit-transform: rotateY(-90deg) scale(0.6) translateX(50%);
  transform: rotateY(-90deg) scale(0.6) translateX(50%);
} 
/* imghvr-fold-right
   ----------------------------- */
.imghvr-fold-right > img {
  -webkit-transform-origin: 100% 50%;
  transform-origin: 100% 50%;
}

.imghvr-fold-right .hover-theme {
  -webkit-transform: rotateY(-90deg) translate3d(50%, 0%, 0) scale(0.6);
  transform: rotateY(-90deg) translate3d(50%, 0%, 0) scale(0.6);
  -webkit-transform-origin: 0 50%;
  transform-origin: 0 50%;
}

.imghvr-fold-right:hover > img {
  -webkit-transform: rotateY(90deg) scale(0.6) translateX(-50%);
  transform: rotateY(90deg) scale(0.6) translateX(-50%);
}

/* imghvr-zoom-in
   ----------------------------- */
.imghvr-zoom-in .hover-theme {
  opacity: 0;
  -webkit-transform: scale(0.5);
  transform: scale(0.5);
}

.imghvr-zoom-in:hover .hover-theme {
  -webkit-transform: scale(1);
  transform: scale(1);
  opacity: 1;
}

/* imghvr-zoom-out*
   ----------------------------- */
[class^='imghvr-zoom-out'] .hover-theme, [class*=' imghvr-zoom-out'] .hover-theme {
  -webkit-transform: scale(0.5);
  transform: scale(0.5);
  -webkit-transform-origin: 50% 50%;
  transform-origin: 50% 50%;
  opacity: 0;
}

[class^='imghvr-zoom-out']:hover .hover-theme, [class^='imghvr-zoom-out'].hover .hover-theme, [class*=' imghvr-zoom-out']:hover .hover-theme, [class*=' imghvr-zoom-out'].hover .hover-theme {
  -webkit-transform: scale(1);
  transform: scale(1);
  opacity: 1;
  -webkit-transition-delay: 0.35s;
  transition-delay: 0.35s;
}

/* imghvr-zoom-out
   ----------------------------- */
.imghvr-zoom-out:hover > img {
  -webkit-transform: scale(0.5);
  transform: scale(0.5);
  opacity: 0;
}

/* imghvr-zoom-out-up
   ----------------------------- */
.imghvr-zoom-out-up:hover > img, .imghvr-zoom-out-up.hover > img {
  -webkit-animation: imghvr-zoom-out-up 0.4025s linear;
  animation: imghvr-zoom-out-up 0.4025s linear;
  -webkit-animation-iteration-count: 1;
  animation-iteration-count: 1;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
}

@-webkit-keyframes imghvr-zoom-out-up
{
  50%
{
    -webkit-transform: scale(0.8) translateY(0%);
    transform: scale(0.8) translateY(0%);
    opacity: 0.5;
  }
  100%
{
    -webkit-transform: scale(0.8) translateY(-150%);
    transform: scale(0.8) translateY(-150%);
    opacity: 0.5;
  }
}

@keyframes imghvr-zoom-out-up
{
  50%
{
    -webkit-transform: scale(0.8) translateY(0%);
    transform: scale(0.8) translateY(0%);
    opacity: 0.5;
  }
  100%
{
    -webkit-transform: scale(0.8) translateY(-150%);
    transform: scale(0.8) translateY(-150%);
    opacity: 0.5;
  }
}

/* imghvr-zoom-out-down
   ----------------------------- */
.imghvr-zoom-out-down:hover > img, .imghvr-zoom-out-down.hover > img {
  -webkit-animation: imghvr-zoom-out-down 0.4025s linear;
  animation: imghvr-zoom-out-down 0.4025s linear;
  -webkit-animation-iteration-count: 1;
  animation-iteration-count: 1;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
}

@-webkit-keyframes imghvr-zoom-out-down
{
  50%
{
    -webkit-transform: scale(0.8) translateY(0%);
    transform: scale(0.8) translateY(0%);
    opacity: 0.5;
  }
  100%
{
    -webkit-transform: scale(0.8) translateY(150%);
    transform: scale(0.8) translateY(150%);
    opacity: 0.5;
  }
}

@keyframes imghvr-zoom-out-down
{
  50%
{
    -webkit-transform: scale(0.8) translateY(0%);
    transform: scale(0.8) translateY(0%);
    opacity: 0.5;
  }
  100%
{
    -webkit-transform: scale(0.8) translateY(150%);
    transform: scale(0.8) translateY(150%);
    opacity: 0.5;
  }
}

/* imghvr-zoom-out-left
   ----------------------------- */
.imghvr-zoom-out-left:hover > img, .imghvr-zoom-out-left.hover > img {
  -webkit-animation: imghvr-zoom-out-left 0.4025s linear;
  animation: imghvr-zoom-out-left 0.4025s linear;
  -webkit-animation-iteration-count: 1;
  animation-iteration-count: 1;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
}

@-webkit-keyframes imghvr-zoom-out-left
{
  50%
{
    -webkit-transform: scale(0.8) translateX(0%);
    transform: scale(0.8) translateX(0%);
    opacity: 0.5;
  }
  100%
{
    -webkit-transform: scale(0.8) translateX(-150%);
    transform: scale(0.8) translateX(-150%);
    opacity: 0.5;
  }
}

@keyframes imghvr-zoom-out-left
{
  50%
{
    -webkit-transform: scale(0.8) translateX(0%);
    transform: scale(0.8) translateX(0%);
    opacity: 0.5;
  }
  100%
{
    -webkit-transform: scale(0.8) translateX(-150%);
    transform: scale(0.8) translateX(-150%);
    opacity: 0.5;
  }
}

/* imghvr-zoom-out-right
   ----------------------------- */
.imghvr-zoom-out-right:hover > img, .imghvr-zoom-out-right.hover > img {
  -webkit-animation: imghvr-zoom-out-right 0.4025s linear;
  animation: imghvr-zoom-out-right 0.4025s linear;
  -webkit-animation-iteration-count: 1;
  animation-iteration-count: 1;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
}

@-webkit-keyframes imghvr-zoom-out-right
{
  50%
{
    -webkit-transform: scale(0.8) translateX(0%);
    transform: scale(0.8) translateX(0%);
    opacity: 0.5;
  }
  100%
{
    -webkit-transform: scale(0.8) translateX(150%);
    transform: scale(0.8) translateX(150%);
    opacity: 0.5;
  }
}

@keyframes imghvr-zoom-out-right
{
  50%
{
    -webkit-transform: scale(0.8) translateX(0%);
    transform: scale(0.8) translateX(0%);
    opacity: 0.5;
  }
  100%
{
    -webkit-transform: scale(0.8) translateX(150%);
    transform: scale(0.8) translateX(150%);
    opacity: 0.5;
  }
}

/* imghvr-zoom-out-flip-horiz
   ----------------------------- */
.imghvr-zoom-out-flip-horiz {
  -webkit-perspective: 50em;
  perspective: 50em;
}

.imghvr-zoom-out-flip-horiz .hover-theme {
  opacity: 0;
  -webkit-transform: rotateX(90deg) translateY(-100%) scale(0.5);
  transform: rotateX(90deg) translateY(-100%) scale(0.5);
}

.imghvr-zoom-out-flip-horiz:hover > img, .imghvr-zoom-out-flip-horiz.hover > img {
  -webkit-transform: rotateX(-100deg) translateY(50%) scale(0.5);
  transform: rotateX(-100deg) translateY(50%) scale(0.5);
  opacity: 0;
  -webkit-transition-delay: 0;
  transition-delay: 0;
}

.imghvr-zoom-out-flip-horiz:hover .hover-theme, .imghvr-zoom-out-flip-horiz.hover .hover-theme {
  -webkit-transform: rotateX(0) translateY(0%) scale(1);
  transform: rotateX(0) translateY(0%) scale(1);
  opacity: 1;
  -webkit-transition-delay: 0.35s;
  transition-delay: 0.35s;
}

/* imghvr-zoom-out-flip-vert
   ----------------------------- */
.imghvr-zoom-out-flip-vert {
  -webkit-perspective: 50em;
  perspective: 50em;
}

.imghvr-zoom-out-flip-vert .hover-theme {
  opacity: 0;
  -webkit-transform: rotateY(90deg) translate(50%, 0) scale(0.5);
  transform: rotateY(90deg) translate(50%, 0) scale(0.5);
}

.imghvr-zoom-out-flip-vert:hover > img, .imghvr-zoom-out-flip-vert.hover > img {
  -webkit-transform: rotateY(-100deg) translateX(50%) scale(0.5);
  transform: rotateY(-100deg) translateX(50%) scale(0.5);
  opacity: 0;
  -webkit-transition-delay: 0;
  transition-delay: 0;
}

.imghvr-zoom-out-flip-vert:hover .hover-theme, .imghvr-zoom-out-flip-vert.hover .hover-theme {
  -webkit-transform: rotateY(0) translate(0, 0) scale(1);
  transform: rotateY(0) translate(0, 0) scale(1);
  opacity: 1;
  -webkit-transition-delay: 0.35s;
  transition-delay: 0.35s;
}

/* imghvr-blur
   ----------------------------- */
.imghvr-blur .hover-theme {
  opacity: 0;
}

.imghvr-blur:hover > img {
  -webkit-filter: blur(30px);
  filter: blur(30px);
  -webkit-transform: scale(1.2);
  transform: scale(1.2);
  opacity: 0;
}

.imghvr-blur:hover .hover-theme {
  opacity: 1;
  -webkit-transition-delay: 0.21s;
  transition-delay: 0.21s;
}

/* imghvr-blocks*
   ----------------------------- */
[class^='imghvr-blocks']:before, [class^='imghvr-blocks']:after, 
[class^='imghvr-blocks'] .hover-theme:before, [class^='imghvr-blocks'] 
.hover-theme:after, [class*=' imghvr-blocks']:before, [class*=' imghvr-blocks']:after, 
[class*=' imghvr-blocks'] .hover-theme:before, [class*=' imghvr-blocks'] .hover-theme:after {
  background-color: #8c3fb2;
  opacity: 0;
  position: absolute;
  content: '';
  height: 50%;
  width: 50%;
}

[class^='imghvr-blocks']:before, [class^='imghvr-blocks']:after, [class*=' imghvr-blocks']:before, [class*=' imghvr-blocks']:after {
  z-index: 1;
}

[class^='imghvr-blocks']:before, [class*=' imghvr-blocks']:before {
  top: 0;
  right: 0;
}

[class^='imghvr-blocks']:after, [class*=' imghvr-blocks']:after {
  bottom: 0;
  left: 0;
}

[class^='imghvr-blocks'] .hover-theme, [class*=' imghvr-blocks'] .hover-theme {
  z-index: 3;
  background-color: transparent;
}

[class^='imghvr-blocks'] .hover-theme *, [class*=' imghvr-blocks'] .hover-theme * {
  opacity: 0;
  -webkit-transition-delay: 0s;
  transition-delay: 0s;
}

[class^='imghvr-blocks'] .hover-theme:before, [class^='imghvr-blocks'] .hover-theme:after, [class*=' imghvr-blocks'] .hover-theme:before, [class*=' imghvr-blocks'] .hover-theme:after {
  z-index: -1;
}

[class^='imghvr-blocks'] .hover-theme:before, [class*=' imghvr-blocks'] .hover-theme:before {
  top: 0;
  left: 0;
}

[class^='imghvr-blocks'] .hover-theme:after, [class*=' imghvr-blocks'] .hover-theme:after {
  bottom: 0;
  right: 0;
}

[class^='imghvr-blocks'] a, [class*=' imghvr-blocks'] a {
  z-index: 3;
}

[class^='imghvr-blocks']:hover:before, [class^='imghvr-blocks']:hover:after, [class^='imghvr-blocks']:hover .hover-theme:before, [class^='imghvr-blocks']:hover .hover-theme:after, [class*=' imghvr-blocks']:hover:before, [class*=' imghvr-blocks']:hover:after, [class*=' imghvr-blocks']:hover .hover-theme:before, [class*=' imghvr-blocks']:hover .hover-theme:after {
  -webkit-transform: translate(0, 0) scale(1);
  transform: translate(0, 0) scale(1);
  opacity: 1;
}

[class^='imghvr-blocks']:hover .hover-theme *, [class*=' imghvr-blocks']:hover .hover-theme * {
  opacity: 1;
  -webkit-transition-delay: 0.35s;
  transition-delay: 0.35s;
}

/* imghvr-blocks-rotate-left
   ----------------------------- */
.imghvr-blocks-rotate-left:before {
  -webkit-transform: translateY(100%);
  transform: translateY(100%);
}

.imghvr-blocks-rotate-left:after {
  -webkit-transform: translateY(-100%);
  transform: translateY(-100%);
}

.imghvr-blocks-rotate-left .hover-theme:before {
  -webkit-transform: translateX(100%);
  transform: translateX(100%);
}

.imghvr-blocks-rotate-left .hover-theme:after {
  -webkit-transform: translateX(-100%);
  transform: translateX(-100%);
}

/* imghvr-blocks-rotate-right
   ----------------------------- */
.imghvr-blocks-rotate-right:before {
  -webkit-transform: translateX(-100%);
  transform: translateX(-100%);
}

.imghvr-blocks-rotate-right:after {
  -webkit-transform: translateX(100%);
  transform: translateX(100%);
}

.imghvr-blocks-rotate-right .hover-theme:before {
  -webkit-transform: translateY(100%);
  transform: translateY(100%);
}

.imghvr-blocks-rotate-right .hover-theme:after {
  -webkit-transform: translateY(-100%);
  transform: translateY(-100%);
}

/* imghvr-blocks-rotate-in-left
   ----------------------------- */
.imghvr-blocks-rotate-in-left:before {
  -webkit-transform: translateY(-100%);
  transform: translateY(-100%);
}

.imghvr-blocks-rotate-in-left:after {
  -webkit-transform: translateY(100%);
  transform: translateY(100%);
}

.imghvr-blocks-rotate-in-left .hover-theme:before {
  -webkit-transform: translateX(-100%);
  transform: translateX(-100%);
}

.imghvr-blocks-rotate-in-left .hover-theme:after {
  -webkit-transform: translateX(100%);
  transform: translateX(100%);
}

/* imghvr-blocks-rotate-in-right
   ----------------------------- */
.imghvr-blocks-rotate-in-right:before {
  -webkit-transform: translateX(100%);
  transform: translateX(100%);
}

.imghvr-blocks-rotate-in-right:after {
  -webkit-transform: translateX(-100%);
  transform: translateX(-100%);
}

.imghvr-blocks-rotate-in-right .hover-theme:before {
  -webkit-transform: translateY(-100%);
  transform: translateY(-100%);
}

.imghvr-blocks-rotate-in-right .hover-theme:after {
  -webkit-transform: translateY(100%);
  transform: translateY(100%);
}

/* imghvr-blocks-in
   ----------------------------- */
.imghvr-blocks-in:before {
  -webkit-transform: translate(100%, -100%);
  transform: translate(100%, -100%);
}

.imghvr-blocks-in:after {
  -webkit-transform: translate(-100%, 100%);
  transform: translate(-100%, 100%);
}

.imghvr-blocks-in .hover-theme:before {
  -webkit-transform: translate(-100%, -100%);
  transform: translate(-100%, -100%);
}

.imghvr-blocks-in .hover-theme:after {
  -webkit-transform: translate(100%, 100%);
  transform: translate(100%, 100%);
}

/* imghvr-blocks-out
   ----------------------------- */
.imghvr-blocks-out:before {
  -webkit-transform: translate(-100%, 100%);
  transform: translate(-100%, 100%);
}

.imghvr-blocks-out:after {
  -webkit-transform: translate(100%, -100%);
  transform: translate(100%, -100%);
}

.imghvr-blocks-out .hover-theme:before {
  -webkit-transform: translate(100%, 100%);
  transform: translate(100%, 100%);
}

.imghvr-blocks-out .hover-theme:after {
  -webkit-transform: translate(-100%, -100%);
  transform: translate(-100%, -100%);
}

/* imghvr-blocks-float-up
   ----------------------------- */
.imghvr-blocks-float-up:before, .imghvr-blocks-float-up:after, .imghvr-blocks-float-up .hover-theme:before, .imghvr-blocks-float-up .hover-theme:after {
  -webkit-transform: translate(0, 200%);
  transform: translate(0, 200%);
}

.imghvr-blocks-float-up:before {
  -webkit-transition-delay: 0.35s;
  transition-delay: 0.35s;
}

.imghvr-blocks-float-up:after {
  -webkit-transition-delay: 0s;
  transition-delay: 0s;
}

.imghvr-blocks-float-up .hover-theme:before {
  -webkit-transition-delay: 0.21s;
  transition-delay: 0.21s;
}

.imghvr-blocks-float-up .hover-theme:after {
  -webkit-transition-delay: 0.14s;
  transition-delay: 0.14s;
}

.imghvr-blocks-float-up:hover:before {
  -webkit-transition-delay: 0.14s;
  transition-delay: 0.14s;
}

.imghvr-blocks-float-up:hover:after {
  -webkit-transition-delay: 0.21s;
  transition-delay: 0.21s;
}

.imghvr-blocks-float-up:hover .hover-theme:before {
  -webkit-transition-delay: 0s;
  transition-delay: 0s;
}

.imghvr-blocks-float-up:hover .hover-theme:after {
  -webkit-transition-delay: 0.35s;
  transition-delay: 0.35s;
}

.imghvr-blocks-float-up:hover .hover-theme * {
  -webkit-transition-delay: 0.49s;
  transition-delay: 0.49s;
}

/* imghvr-blocks-float-down
   ----------------------------- */
.imghvr-blocks-float-down:before, .imghvr-blocks-float-down:after, .imghvr-blocks-float-down .hover-theme:before, .imghvr-blocks-float-down .hover-theme:after {
  -webkit-transform: translate(0, -200%);
  transform: translate(0, -200%);
}

.imghvr-blocks-float-down:before {
  -webkit-transition-delay: 0s;
  transition-delay: 0s;
}

.imghvr-blocks-float-down:after {
  -webkit-transition-delay: 0.35s;
  transition-delay: 0.35s;
}

.imghvr-blocks-float-down .hover-theme:before {
  -webkit-transition-delay: 0.14s;
  transition-delay: 0.14s;
}

.imghvr-blocks-float-down .hover-theme:after {
  -webkit-transition-delay: 0.21s;
  transition-delay: 0.21s;
}

.imghvr-blocks-float-down:hover:before {
  -webkit-transition-delay: 0.35s;
  transition-delay: 0.35s;
}

.imghvr-blocks-float-down:hover:after {
  -webkit-transition-delay: 0s;
  transition-delay: 0s;
}

.imghvr-blocks-float-down:hover .hover-theme:before {
  -webkit-transition-delay: 0.21s;
  transition-delay: 0.21s;
}

.imghvr-blocks-float-down:hover .hover-theme:after {
  -webkit-transition-delay: 0.14s;
  transition-delay: 0.14s;
}

.imghvr-blocks-float-down:hover .hover-theme * {
  -webkit-transition-delay: 0.49s;
  transition-delay: 0.49s;
}

/* imghvr-blocks-float-left
   ----------------------------- */
.imghvr-blocks-float-left:before, .imghvr-blocks-float-left:after, .imghvr-blocks-float-left .hover-theme:before, .imghvr-blocks-float-left .hover-theme:after {
  -webkit-transform: translate(200%, 0);
  transform: translate(200%, 0);
}

.imghvr-blocks-float-left:before {
  -webkit-transition-delay: 0s;
  transition-delay: 0s;
}

.imghvr-blocks-float-left:after {
  -webkit-transition-delay: 0.35s;
  transition-delay: 0.35s;
}

.imghvr-blocks-float-left .hover-theme:before {
  -webkit-transition-delay: 0.105s;
  transition-delay: 0.105s;
}

.imghvr-blocks-float-left .hover-theme:after {
  -webkit-transition-delay: 0.21s;
  transition-delay: 0.21s;
}

.imghvr-blocks-float-left:hover:before {
  -webkit-transition-delay: 0.21s;
  transition-delay: 0.21s;
}

.imghvr-blocks-float-left:hover:after {
  -webkit-transition-delay: 0.14s;
  transition-delay: 0.14s;
}

.imghvr-blocks-float-left:hover .hover-theme:before {
  -webkit-transition-delay: 0s;
  transition-delay: 0s;
}

.imghvr-blocks-float-left:hover .hover-theme:after {
  -webkit-transition-delay: 0.35s;
  transition-delay: 0.35s;
}

.imghvr-blocks-float-left:hover .hover-theme * {
  -webkit-transition-delay: 0.49s;
  transition-delay: 0.49s;
}

/* imghvr-blocks-float-right
   ----------------------------- */
.imghvr-blocks-float-right:before, 
.imghvr-blocks-float-right:after, 
.imghvr-blocks-float-right .hover-theme:before, 
.imghvr-blocks-float-right .hover-theme:after {
  -webkit-transform: translate(-200%, 0);
  transform: translate(-200%, 0);
}

.imghvr-blocks-float-right:before {
  -webkit-transition-delay: 0.35s;
  transition-delay: 0.35s;
}

.imghvr-blocks-float-right:after {
  -webkit-transition-delay: 0s;
  transition-delay: 0s;
}

.imghvr-blocks-float-right .hover-theme:before {
  -webkit-transition-delay: 0.21s;
  transition-delay: 0.21s;
}

.imghvr-blocks-float-right .hover-theme:after {
  -webkit-transition-delay: 0.105s;
  transition-delay: 0.105s;
}

.spa_cart_fig:hover .imghvr-blocks-float-right:before {
  -webkit-transition-delay: 0s;
  transition-delay: 0s;
}

.spa_cart_fig:hover .imghvr-blocks-float-right:after {
  -webkit-transition-delay: 0.35s;
  transition-delay: 0.35s;
}

.spa_cart_fig:hover .imghvr-blocks-float-right .hover-theme:before {
  -webkit-transition-delay: 0.21s;
  transition-delay: 0.21s;
}

.spa_cart_fig:hover .imghvr-blocks-float-right .hover-theme:after {
  -webkit-transition-delay: 0.14s;
  transition-delay: 0.14s;
}

.spa_cart_fig:hover .imghvr-blocks-float-right .hover-theme{
  -webkit-transition-delay: 0.49s;
  transition-delay: 0.49s;
}

/* imghvr-blocks-zoom-top-left
   ----------------------------- */
.imghvr-blocks-zoom-top-left:before, .imghvr-blocks-zoom-top-left:after, .imghvr-blocks-zoom-top-left .hover-theme:before, .imghvr-blocks-zoom-top-left .hover-theme:after {
  -webkit-transform: scale(0.05);
  transform: scale(0.05);
}

.imghvr-blocks-zoom-top-left:hover:before {
  -webkit-transition-delay: 0.175s;
  transition-delay: 0.175s;
}

.imghvr-blocks-zoom-top-left:hover:after {
  -webkit-transition-delay: 0.175s;
  transition-delay: 0.175s;
}

.imghvr-blocks-zoom-top-left:hover .hover-theme:before {
  -webkit-transition-delay: 0s;
  transition-delay: 0s;
}

.imghvr-blocks-zoom-top-left:hover .hover-theme:after {
  -webkit-transition-delay: 0.35s;
  transition-delay: 0.35s;
}

.imghvr-blocks-zoom-top-left:hover .hover-theme * {
  -webkit-transition-delay: 0.49s;
  transition-delay: 0.49s;
}

/* imghvr-blocks-zoom-top-right
   ----------------------------- */
.imghvr-blocks-zoom-top-right:before, .imghvr-blocks-zoom-top-right:after, .imghvr-blocks-zoom-top-right .hover-theme:before, .imghvr-blocks-zoom-top-right .hover-theme:after {
  -webkit-transform: scale(0.05);
  transform: scale(0.05);
}

.imghvr-blocks-zoom-top-right:hover:before {
  -webkit-transition-delay: 0, 0s;
  transition-delay: 0, 0s;
}

.imghvr-blocks-zoom-top-right:hover:after {
  -webkit-transition-delay: 0.35s;
  transition-delay: 0.35s;
}

.imghvr-blocks-zoom-top-right:hover .hover-theme:before {
  -webkit-transition-delay: 0.175s;
  transition-delay: 0.175s;
}

.imghvr-blocks-zoom-top-right:hover .hover-theme:after {
  -webkit-transition-delay: 0.175s;
  transition-delay: 0.175s;
}

.imghvr-blocks-zoom-top-right:hover .hover-theme * {
  -webkit-transition-delay: 0.49s;
  transition-delay: 0.49s;
}

/* imghvr-blocks-zoom-bottom-left
   ----------------------------- */
.imghvr-blocks-zoom-bottom-left:before, .imghvr-blocks-zoom-bottom-left:after, .imghvr-blocks-zoom-bottom-left .hover-theme:before, .imghvr-blocks-zoom-bottom-left .hover-theme:after {
  -webkit-transform: scale(0.05);
  transform: scale(0.05);
}

.imghvr-blocks-zoom-bottom-left:hover:before {
  -webkit-transition-delay: 0.35s;
  transition-delay: 0.35s;
}

.imghvr-blocks-zoom-bottom-left:hover:after {
  -webkit-transition-delay: 0s;
  transition-delay: 0s;
}

.imghvr-blocks-zoom-bottom-left:hover .hover-theme:before {
  -webkit-transition-delay: 0.175s;
  transition-delay: 0.175s;
}

.imghvr-blocks-zoom-bottom-left:hover .hover-theme:after {
  -webkit-transition-delay: 0.175s;
  transition-delay: 0.175s;
}

.imghvr-blocks-zoom-bottom-left:hover .hover-theme * {
  -webkit-transition-delay: 0.49s;
  transition-delay: 0.49s;
}

/* imghvr-blocks-zoom-bottom-right
   ----------------------------- */
.imghvr-blocks-zoom-bottom-right:before, .imghvr-blocks-zoom-bottom-right:after, .imghvr-blocks-zoom-bottom-right .hover-theme:before, .imghvr-blocks-zoom-bottom-right .hover-theme:after {
  -webkit-transform: scale(0.05);
  transform: scale(0.05);
}

.imghvr-blocks-zoom-bottom-right:hover:before {
  -webkit-transition-delay: 0.175s;
  transition-delay: 0.175s;
}

.imghvr-blocks-zoom-bottom-right:hover:after {
  -webkit-transition-delay: 0.175s;
  transition-delay: 0.175s;
}

.imghvr-blocks-zoom-bottom-right:hover .hover-theme:before {
  -webkit-transition-delay: 0.35s;
  transition-delay: 0.35s;
}

.imghvr-blocks-zoom-bottom-right:hover .hover-theme:after {
  -webkit-transition-delay: 0s;
  transition-delay: 0s;
}

.imghvr-blocks-zoom-bottom-right:hover .hover-theme * {
  -webkit-transition-delay: 0.49s;
  transition-delay: 0.49s;
}

/* imghvr-strip-shutter-*
   ----------------------------- */
[class^='imghvr-strip-shutter']:before, [class^='imghvr-strip-shutter']:after, [class^='imghvr-strip-shutter'] .hover-theme:before, [class^='imghvr-strip-shutter'] .hover-theme:after, [class*=' imghvr-strip-shutter']:before, [class*=' imghvr-strip-shutter']:after, [class*=' imghvr-strip-shutter'] .hover-theme:before, [class*=' imghvr-strip-shutter'] .hover-theme:after {
  background-color: #135796;
  opacity: 0;
  position: absolute;
  content: '';
}

[class^='imghvr-strip-shutter']:before, [class^='imghvr-strip-shutter']:after, [class*=' imghvr-strip-shutter']:before, [class*=' imghvr-strip-shutter']:after {
  z-index: 1;
}

[class^='imghvr-strip-shutter'] .hover-theme, [class*=' imghvr-strip-shutter'] .hover-theme {
  z-index: 3;
  background-color: transparent;
}

[class^='imghvr-strip-shutter'] .hover-theme *, [class*=' imghvr-strip-shutter'] .hover-theme * {
  opacity: 0;
}

[class^='imghvr-strip-shutter'] .hover-theme:before, [class^='imghvr-strip-shutter'] .hover-theme:after, [class*=' imghvr-strip-shutter'] .hover-theme:before, [class*=' imghvr-strip-shutter'] .hover-theme:after {
  z-index: -1;
}

[class^='imghvr-strip-shutter'] a, [class*=' imghvr-strip-shutter'] a {
  z-index: 3;
}

[class^='imghvr-strip-shutter']:hover:before, [class^='imghvr-strip-shutter']:hover:after, [class^='imghvr-strip-shutter']:hover .hover-theme:before, [class^='imghvr-strip-shutter']:hover .hover-theme:after, [class*=' imghvr-strip-shutter']:hover:before, [class*=' imghvr-strip-shutter']:hover:after, [class*=' imghvr-strip-shutter']:hover .hover-theme:before, [class*=' imghvr-strip-shutter']:hover .hover-theme:after {
  -webkit-transform: scale(1);
  transform: scale(1);
  opacity: 1;
}

[class^='imghvr-strip-shutter']:hover .hover-theme *, [class*=' imghvr-strip-shutter']:hover .hover-theme * {
  opacity: 1;
  -webkit-transition-delay: 0.35s;
  transition-delay: 0.35s;
}

/* imghvr-strip-shutter-up
   ----------------------------- */
.imghvr-strip-shutter-up:before, .imghvr-strip-shutter-up:after, .imghvr-strip-shutter-up .hover-theme:before, .imghvr-strip-shutter-up .hover-theme:after {
  height: 26%;
  width: 100%;
  -webkit-transform: scaleX(0);
  transform: scaleX(0);
  left: 0;
}

.imghvr-strip-shutter-up:before {
  top: 0;
  -webkit-transition-delay: 0s;
  transition-delay: 0s;
}

.imghvr-strip-shutter-up:after {
  top: 25%;
  -webkit-transition-delay: 0.105s;
  transition-delay: 0.105s;
}

.imghvr-strip-shutter-up .hover-theme:before {
  top: 50%;
  -webkit-transition-delay: 0.21s;
  transition-delay: 0.21s;
}

.imghvr-strip-shutter-up .hover-theme:after {
  top: 75%;
  -webkit-transition-delay: 0.35s;
  transition-delay: 0.35s;
}

/* imghvr-strip-shutter-down
   ----------------------------- */
.imghvr-strip-shutter-down:before, .imghvr-strip-shutter-down:after, .imghvr-strip-shutter-down .hover-theme:before, .imghvr-strip-shutter-down .hover-theme:after {
  height: 26%;
  width: 100%;
  -webkit-transform: scaleX(0);
  transform: scaleX(0);
  left: 0;
}

.imghvr-strip-shutter-down:before {
  top: 0;
  -webkit-transition-delay: 0.35s;
  transition-delay: 0.35s;
}

.imghvr-strip-shutter-down:after {
  top: 25%;
  -webkit-transition-delay: 0.21s;
  transition-delay: 0.21s;
}

.imghvr-strip-shutter-down .hover-theme:before {
  top: 50%;
  -webkit-transition-delay: 0.105s;
  transition-delay: 0.105s;
}

.imghvr-strip-shutter-down .hover-theme:after {
  top: 75%;
  -webkit-transition-delay: 0s;
  transition-delay: 0s;
}

/* imghvr-strip-shutter-left
   ----------------------------- */
.imghvr-strip-shutter-left:before, .imghvr-strip-shutter-left:after, .imghvr-strip-shutter-left .hover-theme:before, .imghvr-strip-shutter-left .hover-theme:after {
  width: 26%;
  height: 100%;
  -webkit-transform: scaleY(0);
  transform: scaleY(0);
  top: 0;
}

.imghvr-strip-shutter-left:before {
  left: 0;
  -webkit-transition-delay: 0.35s;
  transition-delay: 0.35s;
}

.imghvr-strip-shutter-left:after {
  left: 25%;
  -webkit-transition-delay: 0.21s;
  transition-delay: 0.21s;
}

.imghvr-strip-shutter-left .hover-theme:before {
  left: 50%;
  -webkit-transition-delay: 0.105s;
  transition-delay: 0.105s;
}

.imghvr-strip-shutter-left .hover-theme:after {
  left: 75%;
  -webkit-transition-delay: 0s;
  transition-delay: 0s;
}

/* imghvr-strip-shutter-right
   ----------------------------- */
.imghvr-strip-shutter-right:before, .imghvr-strip-shutter-right:after, .imghvr-strip-shutter-right .hover-theme:before, .imghvr-strip-shutter-right .hover-theme:after {
  width: 26%;
  height: 100%;
  -webkit-transform: scaleY(0);
  transform: scaleY(0);
  top: 0;
}

.imghvr-strip-shutter-right:before {
  left: 0;
  -webkit-transition-delay: 0s;
  transition-delay: 0s;
}

.imghvr-strip-shutter-right:after {
  left: 25%;
  -webkit-transition-delay: 0.105s;
  transition-delay: 0.105s;
}

.imghvr-strip-shutter-right .hover-theme:before {
  left: 50%;
  -webkit-transition-delay: 0.21s;
  transition-delay: 0.21s;
}

.imghvr-strip-shutter-right .hover-theme:after {
  left: 75%;
  -webkit-transition-delay: 0.35s;
  transition-delay: 0.35s;
}

/* imghvr-strip-horiz-*
   ----------------------------- */
[class^='imghvr-strip-horiz']:before, [class^='imghvr-strip-horiz']:after, [class^='imghvr-strip-horiz'] .hover-theme:before, [class^='imghvr-strip-horiz'] .hover-theme:after, [class*=' imghvr-strip-horiz']:before, [class*=' imghvr-strip-horiz']:after, [class*=' imghvr-strip-horiz'] .hover-theme:before, [class*=' imghvr-strip-horiz'] .hover-theme:after {
  background-color: #135796;
  opacity: 0;
  position: absolute;
  content: '';
  height: 26%;
  width: 100%;
  -webkit-transform: scaleX(0);
  transform: scaleX(0);
  left: 0;
}

[class^='imghvr-strip-horiz']:before, [class^='imghvr-strip-horiz']:after, [class*=' imghvr-strip-horiz']:before, [class*=' imghvr-strip-horiz']:after {
  z-index: 1;
}

[class^='imghvr-strip-horiz']:before, [class*=' imghvr-strip-horiz']:before {
  top: 0;
}

[class^='imghvr-strip-horiz']:after, [class*=' imghvr-strip-horiz']:after {
  top: 25%;
}

[class^='imghvr-strip-horiz'] .hover-theme, [class*=' imghvr-strip-horiz'] .hover-theme {
  z-index: 3;
  background-color: transparent;
}

[class^='imghvr-strip-horiz'] .hover-theme *, [class*=' imghvr-strip-horiz'] .hover-theme * {
  opacity: 0;
}

[class^='imghvr-strip-horiz'] .hover-theme:before, [class^='imghvr-strip-horiz'] .hover-theme:after, [class*=' imghvr-strip-horiz'] .hover-theme:before, [class*=' imghvr-strip-horiz'] .hover-theme:after {
  z-index: -1;
}

[class^='imghvr-strip-horiz'] .hover-theme:before, [class*=' imghvr-strip-horiz'] .hover-theme:before {
  top: 50%;
}

[class^='imghvr-strip-horiz'] .hover-theme:after, [class*=' imghvr-strip-horiz'] .hover-theme:after {
  top: 75%;
}

[class^='imghvr-strip-horiz'] a, [class*=' imghvr-strip-horiz'] a {
  z-index: 3;
}

[class^='imghvr-strip-horiz']:hover:before, [class^='imghvr-strip-horiz']:hover:after, [class^='imghvr-strip-horiz']:hover .hover-theme:before, [class^='imghvr-strip-horiz']:hover .hover-theme:after, [class*=' imghvr-strip-horiz']:hover:before, [class*=' imghvr-strip-horiz']:hover:after, [class*=' imghvr-strip-horiz']:hover .hover-theme:before, [class*=' imghvr-strip-horiz']:hover .hover-theme:after {
  -webkit-transform: scale(1);
  transform: scale(1);
  opacity: 1;
}

[class^='imghvr-strip-horiz']:hover .hover-theme *, [class*=' imghvr-strip-horiz']:hover .hover-theme * {
  opacity: 1;
  -webkit-transition-delay: 0.35s;
  transition-delay: 0.35s;
}

/* imghvr-strip-horiz-up
   ----------------------------- */
.imghvr-strip-horiz-up:before, .imghvr-strip-horiz-up:after, .imghvr-strip-horiz-up .hover-theme:before, .imghvr-strip-horiz-up .hover-theme:after {
  -webkit-transform: scaleX(1);
  transform: scaleX(1);
  -webkit-transform: scaleY(0);
  transform: scaleY(0);
  -webkit-transform-origin: 50% 100%;
  transform-origin: 50% 100%;
}

/* imghvr-strip-horiz-down
   ----------------------------- */
.imghvr-strip-horiz-down:before, .imghvr-strip-horiz-down:after, .imghvr-strip-horiz-down .hover-theme:before, .imghvr-strip-horiz-down .hover-theme:after {
  -webkit-transform: scaleX(1);
  transform: scaleX(1);
  -webkit-transform: scaleY(0);
  transform: scaleY(0);
  -webkit-transform-origin: 50% 0%;
  transform-origin: 50% 0%;
}

/* imghvr-strip-horiz-top-left
   ----------------------------- */
.imghvr-strip-horiz-top-left:before, .imghvr-strip-horiz-top-left:after, .imghvr-strip-horiz-top-left .hover-theme:before, .imghvr-strip-horiz-top-left .hover-theme:after {
  -webkit-transform-origin: 0 0;
  transform-origin: 0 0;
}

.imghvr-strip-horiz-top-left:before {
  -webkit-transition-delay: 0s;
  transition-delay: 0s;
}

.imghvr-strip-horiz-top-left:after {
  -webkit-transition-delay: 0.105s;
  transition-delay: 0.105s;
}

.imghvr-strip-horiz-top-left .hover-theme:before {
  -webkit-transition-delay: 0.21s;
  transition-delay: 0.21s;
}

.imghvr-strip-horiz-top-left .hover-theme:after {
  -webkit-transition-delay: 0.35s;
  transition-delay: 0.35s;
}

/* imghvr-strip-horiz-top-right
   ----------------------------- */
.imghvr-strip-horiz-top-right:before, .imghvr-strip-horiz-top-right:after, .imghvr-strip-horiz-top-right .hover-theme:before, .imghvr-strip-horiz-top-right .hover-theme:after {
  -webkit-transform-origin: 100% 0;
  transform-origin: 100% 0;
}

.imghvr-strip-horiz-top-right:before {
  -webkit-transition-delay: 0s;
  transition-delay: 0s;
}

.imghvr-strip-horiz-top-right:after {
  -webkit-transition-delay: 0.105s;
  transition-delay: 0.105s;
}

.imghvr-strip-horiz-top-right .hover-theme:before {
  -webkit-transition-delay: 0.21s;
  transition-delay: 0.21s;
}

.imghvr-strip-horiz-top-right .hover-theme:after {
  -webkit-transition-delay: 0.35s;
  transition-delay: 0.35s;
}

/* imghvr-strip-horiz-bottom-left
   ----------------------------- */
.imghvr-strip-horiz-bottom-left:before, .imghvr-strip-horiz-bottom-left:after, .imghvr-strip-horiz-bottom-left .hover-theme:before, .imghvr-strip-horiz-bottom-left .hover-theme:after {
  -webkit-transform-origin: 0 0;
  transform-origin: 0 0;
}

.imghvr-strip-horiz-bottom-left:before {
  -webkit-transition-delay: 0.35s;
  transition-delay: 0.35s;
}

.imghvr-strip-horiz-bottom-left:after {
  -webkit-transition-delay: 0.21s;
  transition-delay: 0.21s;
}

.imghvr-strip-horiz-bottom-left .hover-theme:before {
  -webkit-transition-delay: 0.105s;
  transition-delay: 0.105s;
}

.imghvr-strip-horiz-bottom-left .hover-theme:after {
  -webkit-transition-delay: 0s;
  transition-delay: 0s;
}

/* imghvr-strip-horiz-bottom-right
   ----------------------------- */
.imghvr-strip-horiz-bottom-right:before, .imghvr-strip-horiz-bottom-right:after, .imghvr-strip-horiz-bottom-right .hover-theme:before, .imghvr-strip-horiz-bottom-right .hover-theme:after {
  -webkit-transform-origin: 100% 0;
  transform-origin: 100% 0;
}

.imghvr-strip-horiz-bottom-right:before {
  -webkit-transition-delay: 0.35s;
  transition-delay: 0.35s;
}

.imghvr-strip-horiz-bottom-right:after {
  -webkit-transition-delay: 0.21s;
  transition-delay: 0.21s;
}

.imghvr-strip-horiz-bottom-right .hover-theme:before {
  -webkit-transition-delay: 0.105s;
  transition-delay: 0.105s;
}

.imghvr-strip-horiz-bottom-right .hover-theme:after {
  -webkit-transition-delay: 0s;
  transition-delay: 0s;
}

/* imghvr-strip-vert-*
   ----------------------------- */
[class^='imghvr-strip-vert']:before, [class^='imghvr-strip-vert']:after, [class^='imghvr-strip-vert'] .hover-theme:before, [class^='imghvr-strip-vert'] .hover-theme:after, [class*=' imghvr-strip-vert']:before, [class*=' imghvr-strip-vert']:after, [class*=' imghvr-strip-vert'] .hover-theme:before, [class*=' imghvr-strip-vert'] .hover-theme:after {
  background-color: #135796;
  opacity: 0;
  position: absolute;
  content: '';
  width: 26%;
  height: 100%;
  -webkit-transform: scaleY(0);
  transform: scaleY(0);
  top: 0;
}

[class^='imghvr-strip-vert']:before, [class^='imghvr-strip-vert']:after, [class*=' imghvr-strip-vert']:before, [class*=' imghvr-strip-vert']:after {
  z-index: 1;
}

[class^='imghvr-strip-vert']:before, [class*=' imghvr-strip-vert']:before {
  left: 0;
}

[class^='imghvr-strip-vert']:after, [class*=' imghvr-strip-vert']:after {
  left: 25%;
}

[class^='imghvr-strip-vert'] .hover-theme, [class*=' imghvr-strip-vert'] .hover-theme {
  z-index: 3;
  background-color: transparent;
}

[class^='imghvr-strip-vert'] .hover-theme *, [class*=' imghvr-strip-vert'] .hover-theme * {
  opacity: 0;
}

[class^='imghvr-strip-vert'] .hover-theme:before, [class^='imghvr-strip-vert'] .hover-theme:after, [class*=' imghvr-strip-vert'] .hover-theme:before, [class*=' imghvr-strip-vert'] .hover-theme:after {
  z-index: -1;
}

[class^='imghvr-strip-vert'] .hover-theme:before, [class*=' imghvr-strip-vert'] .hover-theme:before {
  left: 50%;
}

[class^='imghvr-strip-vert'] .hover-theme:after, [class*=' imghvr-strip-vert'] .hover-theme:after {
  left: 75%;
}

[class^='imghvr-strip-vert'] a, [class*=' imghvr-strip-vert'] a {
  z-index: 3;
}

[class^='imghvr-strip-vert']:hover:before, [class^='imghvr-strip-vert']:hover:after, [class^='imghvr-strip-vert']:hover .hover-theme:before, [class^='imghvr-strip-vert']:hover .hover-theme:after, [class*=' imghvr-strip-vert']:hover:before, [class*=' imghvr-strip-vert']:hover:after, [class*=' imghvr-strip-vert']:hover .hover-theme:before, [class*=' imghvr-strip-vert']:hover .hover-theme:after {
  -webkit-transform: scale(1);
  transform: scale(1);
  opacity: 1;
}

[class^='imghvr-strip-vert']:hover .hover-theme *, [class*=' imghvr-strip-vert']:hover .hover-theme * {
  opacity: 1;
  -webkit-transition-delay: 0.35s;
  transition-delay: 0.35s;
}

/* imghvr-strip-vert-left
   ----------------------------- */
.imghvr-strip-vert-left:before, .imghvr-strip-vert-left:after, .imghvr-strip-vert-left .hover-theme:before, .imghvr-strip-vert-left .hover-theme:after {
  -webkit-transform: scaleY(1);
  transform: scaleY(1);
  -webkit-transform: scaleX(0);
  transform: scaleX(0);
  -webkit-transform-origin: 100% 50%;
  transform-origin: 100% 50%;
}

/* imghvr-strip-vert-right
   ----------------------------- */
.imghvr-strip-vert-right:before, .imghvr-strip-vert-right:after, .imghvr-strip-vert-right .hover-theme:before, .imghvr-strip-vert-right .hover-theme:after {
  -webkit-transform: scaleY(1);
  transform: scaleY(1);
  -webkit-transform: scaleX(0);
  transform: scaleX(0);
  -webkit-transform-origin: 0% 50%;
  transform-origin: 0% 50%;
}

/* imghvr-strip-vert-top-left
   ----------------------------- */
.imghvr-strip-vert-top-left:before, .imghvr-strip-vert-top-left:after, .imghvr-strip-vert-top-left .hover-theme:before, .imghvr-strip-vert-top-left .hover-theme:after {
  -webkit-transform-origin: 0 0;
  transform-origin: 0 0;
}

.imghvr-strip-vert-top-left:before {
  -webkit-transition-delay: 0s;
  transition-delay: 0s;
}

.imghvr-strip-vert-top-left:after {
  -webkit-transition-delay: 0.105s;
  transition-delay: 0.105s;
}

.imghvr-strip-vert-top-left .hover-theme:before {
  -webkit-transition-delay: 0.21s;
  transition-delay: 0.21s;
}

.imghvr-strip-vert-top-left .hover-theme:after {
  -webkit-transition-delay: 0.35s;
  transition-delay: 0.35s;
}

/* imghvr-strip-vert-top-right
   ----------------------------- */
.imghvr-strip-vert-top-right:before, .imghvr-strip-vert-top-right:after, .imghvr-strip-vert-top-right .hover-theme:before, .imghvr-strip-vert-top-right .hover-theme:after {
  -webkit-transform-origin: 0 0;
  transform-origin: 0 0;
}

.imghvr-strip-vert-top-right:before {
  -webkit-transition-delay: 0.35s;
  transition-delay: 0.35s;
}

.imghvr-strip-vert-top-right:after {
  -webkit-transition-delay: 0.21s;
  transition-delay: 0.21s;
}

.imghvr-strip-vert-top-right .hover-theme:before {
  -webkit-transition-delay: 0.105s;
  transition-delay: 0.105s;
}

.imghvr-strip-vert-top-right .hover-theme:after {
  -webkit-transition-delay: 0s;
  transition-delay: 0s;
}

/* imghvr-strip-vert-bottom-left
   ----------------------------- */
.imghvr-strip-vert-bottom-left:before, .imghvr-strip-vert-bottom-left:after, .imghvr-strip-vert-bottom-left .hover-theme:before, .imghvr-strip-vert-bottom-left .hover-theme:after {
  -webkit-transform-origin: 0 100%;
  transform-origin: 0 100%;
}

.imghvr-strip-vert-bottom-left:before {
  -webkit-transition-delay: 0s;
  transition-delay: 0s;
}

.imghvr-strip-vert-bottom-left:after {
  -webkit-transition-delay: 0.105s;
  transition-delay: 0.105s;
}

.imghvr-strip-vert-bottom-left .hover-theme:before {
  -webkit-transition-delay: 0.21s;
  transition-delay: 0.21s;
}

.imghvr-strip-vert-bottom-left .hover-theme:after {
  -webkit-transition-delay: 0.35s;
  transition-delay: 0.35s;
}

/* imghvr-strip-vert-bottom-right
   ----------------------------- */
.imghvr-strip-vert-bottom-right:before, .imghvr-strip-vert-bottom-right:after, .imghvr-strip-vert-bottom-right .hover-theme:before, .imghvr-strip-vert-bottom-right .hover-theme:after {
  -webkit-transform-origin: 0 100%;
  transform-origin: 0 100%;
}

.imghvr-strip-vert-bottom-right:before {
  -webkit-transition-delay: 0.35s;
  transition-delay: 0.35s;
}

.imghvr-strip-vert-bottom-right:after {
  -webkit-transition-delay: 0.21s;
  transition-delay: 0.21s;
}

.imghvr-strip-vert-bottom-right .hover-theme:before {
  -webkit-transition-delay: 0.105s;
  transition-delay: 0.105s;
}

.imghvr-strip-vert-bottom-right .hover-theme:after {
  -webkit-transition-delay: 0s;
  transition-delay: 0s;
}

/* imghvr-pixel-*
   ----------------------------- */
[class^='imghvr-pixel']:before, [class^='imghvr-pixel']:after, [class^='imghvr-pixel'] .hover-theme:before, [class^='imghvr-pixel'] .hover-theme:after, [class*=' imghvr-pixel']:before, [class*=' imghvr-pixel']:after, [class*=' imghvr-pixel'] .hover-theme:before, [class*=' imghvr-pixel'] .hover-theme:after {
  -webkit-transition: all 0.35s steps(4);
  transition: all 0.35s steps(4);
  background-color: #135796;
  opacity: 0;
  position: absolute;
  content: '';
  height: 26%;
  width: 100%;
  -webkit-transform: scaleX(0);
  transform: scaleX(0);
  left: 0;
}

[class^='imghvr-pixel']:before, [class^='imghvr-pixel']:after, [class*=' imghvr-pixel']:before, [class*=' imghvr-pixel']:after {
  z-index: 1;
}

[class^='imghvr-pixel']:before, [class*=' imghvr-pixel']:before {
  top: 0;
  -webkit-transition-delay: 0s;
  transition-delay: 0s;
}

[class^='imghvr-pixel']:after, [class*=' imghvr-pixel']:after {
  top: 25%;
  -webkit-transition-delay: 0.105s;
  transition-delay: 0.105s;
}

[class^='imghvr-pixel'] .hover-theme, [class*=' imghvr-pixel'] .hover-theme {
  z-index: 3;
  background-color: transparent;
}

[class^='imghvr-pixel'] .hover-theme *, [class*=' imghvr-pixel'] .hover-theme * {
  opacity: 0;
}

[class^='imghvr-pixel'] .hover-theme:before, [class^='imghvr-pixel'] .hover-theme:after, [class*=' imghvr-pixel'] .hover-theme:before, [class*=' imghvr-pixel'] .hover-theme:after {
  z-index: -1;
}

[class^='imghvr-pixel'] .hover-theme:before, [class*=' imghvr-pixel'] .hover-theme:before {
  top: 50%;
  -webkit-transition-delay: 0.21s;
  transition-delay: 0.21s;
}

[class^='imghvr-pixel'] .hover-theme:after, [class*=' imghvr-pixel'] .hover-theme:after {
  top: 75%;
  -webkit-transition-delay: 0.35s;
  transition-delay: 0.35s;
}

[class^='imghvr-pixel'] a, [class*=' imghvr-pixel'] a {
  z-index: 3;
}

[class^='imghvr-pixel']:hover:before, [class^='imghvr-pixel']:hover:after, [class^='imghvr-pixel']:hover .hover-theme:before, [class^='imghvr-pixel']:hover .hover-theme:after, [class*=' imghvr-pixel']:hover:before, [class*=' imghvr-pixel']:hover:after, [class*=' imghvr-pixel']:hover .hover-theme:before, [class*=' imghvr-pixel']:hover .hover-theme:after {
  -webkit-transform: scale(1);
  transform: scale(1);
  opacity: 1;
}

[class^='imghvr-pixel']:hover .hover-theme *, [class*=' imghvr-pixel']:hover .hover-theme * {
  opacity: 1;
  -webkit-transition-delay: 0.35s;
  transition-delay: 0.35s;
}

/* imghvr-pixel-up
   ----------------------------- */
.imghvr-pixel-up:before, .imghvr-pixel-up:after, .imghvr-pixel-up .hover-theme:before, .imghvr-pixel-up .hover-theme:after {
  -webkit-transform-origin: 50% 0;
  transform-origin: 50% 0;
}

.imghvr-pixel-up:before {
  -webkit-transition-delay: 0.35s;
  transition-delay: 0.35s;
}

.imghvr-pixel-up:after {
  -webkit-transition-delay: 0.21s;
  transition-delay: 0.21s;
}

.imghvr-pixel-up .hover-theme:before {
  -webkit-transition-delay: 0.105s;
  transition-delay: 0.105s;
}

.imghvr-pixel-up .hover-theme:after {
  -webkit-transition-delay: 0s;
  transition-delay: 0s;
}

/* imghvr-pixel-down
   ----------------------------- */
.imghvr-pixel-down:before, .imghvr-pixel-down:after, .imghvr-pixel-down .hover-theme:before, .imghvr-pixel-down .hover-theme:after {
  -webkit-transform-origin: 50% 0;
  transform-origin: 50% 0;
}

.imghvr-pixel-down:before {
  -webkit-transition-delay: 0s;
  transition-delay: 0s;
}

.imghvr-pixel-down:after {
  -webkit-transition-delay: 0.105s;
  transition-delay: 0.105s;
}

.imghvr-pixel-down .hover-theme:before {
  -webkit-transition-delay: 0.21s;
  transition-delay: 0.21s;
}

.imghvr-pixel-down .hover-theme:after {
  -webkit-transition-delay: 0.35s;
  transition-delay: 0.35s;
}

/* imghvr-pixel-left
   ----------------------------- */
.imghvr-pixel-left:before, .imghvr-pixel-left:after, .imghvr-pixel-left .hover-theme:before, .imghvr-pixel-left .hover-theme:after {
  width: 25.1%;
  height: 100%;
  -webkit-transform: scaleY(0);
  transform: scaleY(0);
  top: 0;
}

.imghvr-pixel-left:before {
  left: 0;
  -webkit-transition-delay: 0.35s;
  transition-delay: 0.35s;
}

.imghvr-pixel-left:after {
  left: 25%;
  -webkit-transition-delay: 0.21s;
  transition-delay: 0.21s;
}

.imghvr-pixel-left .hover-theme:before {
  left: 50%;
  -webkit-transition-delay: 0.105s;
  transition-delay: 0.105s;
}

.imghvr-pixel-left .hover-theme:after {
  left: 75%;
  -webkit-transition-delay: 0s;
  transition-delay: 0s;
}

/* imghvr-pixel-right
   ----------------------------- */
.imghvr-pixel-right:before, .imghvr-pixel-right:after, .imghvr-pixel-right .hover-theme:before, .imghvr-pixel-right .hover-theme:after {
  width: 25.1%;
  height: 100%;
  -webkit-transform: scaleY(0);
  transform: scaleY(0);
  top: 0;
}

.imghvr-pixel-right:before {
  left: 0;
  -webkit-transition-delay: 0s;
  transition-delay: 0s;
}

.imghvr-pixel-right:after {
  left: 25%;
  -webkit-transition-delay: 0.105s;
  transition-delay: 0.105s;
}

.imghvr-pixel-right .hover-theme:before {
  left: 50%;
  -webkit-transition-delay: 0.21s;
  transition-delay: 0.21s;
}

.imghvr-pixel-right .hover-theme:after {
  left: 75%;
  -webkit-transition-delay: 0.35s;
  transition-delay: 0.35s;
}

/* imghvr-pixel-top-left
   ----------------------------- */
.imghvr-pixel-top-left:before, .imghvr-pixel-top-left:after, .imghvr-pixel-top-left .hover-theme:before, .imghvr-pixel-top-left .hover-theme:after {
  -webkit-transform-origin: 0 0;
  transform-origin: 0 0;
}

.imghvr-pixel-top-left:before {
  -webkit-transition-delay: 0s;
  transition-delay: 0s;
}

.imghvr-pixel-top-left:after {
  -webkit-transition-delay: 0.105s;
  transition-delay: 0.105s;
}

.imghvr-pixel-top-left .hover-theme:before {
  -webkit-transition-delay: 0.21s;
  transition-delay: 0.21s;
}

.imghvr-pixel-top-left .hover-theme:after {
  -webkit-transition-delay: 0.35s;
  transition-delay: 0.35s;
}

/* imghvr-pixel-top-right
   ----------------------------- */
.imghvr-pixel-top-right:before, .imghvr-pixel-top-right:after, .imghvr-pixel-top-right .hover-theme:before, .imghvr-pixel-top-right .hover-theme:after {
  -webkit-transform-origin: 100% 0;
  transform-origin: 100% 0;
}

.imghvr-pixel-top-right:before {
  -webkit-transition-delay: 0s;
  transition-delay: 0s;
}

.imghvr-pixel-top-right:after {
  -webkit-transition-delay: 0.105s;
  transition-delay: 0.105s;
}

.imghvr-pixel-top-right .hover-theme:before {
  -webkit-transition-delay: 0.21s;
  transition-delay: 0.21s;
}

.imghvr-pixel-top-right .hover-theme:after {
  -webkit-transition-delay: 0.35s;
  transition-delay: 0.35s;
}

/* imghvr-pixel-bottom-left
   ----------------------------- */
.imghvr-pixel-bottom-left:before, .imghvr-pixel-bottom-left:after, .imghvr-pixel-bottom-left .hover-theme:before, .imghvr-pixel-bottom-left .hover-theme:after {
  -webkit-transform-origin: 0 0;
  transform-origin: 0 0;
}

.imghvr-pixel-bottom-left:before {
  -webkit-transition-delay: 0.35s;
  transition-delay: 0.35s;
}

.imghvr-pixel-bottom-left:after {
  -webkit-transition-delay: 0.21s;
  transition-delay: 0.21s;
}

.imghvr-pixel-bottom-left .hover-theme:before {
  -webkit-transition-delay: 0.105s;
  transition-delay: 0.105s;
}

.imghvr-pixel-bottom-left .hover-theme:after {
  -webkit-transition-delay: 0s;
  transition-delay: 0s;
}

/* imghvr-pixel-bottom-right
   ----------------------------- */
.imghvr-pixel-bottom-right:before, .imghvr-pixel-bottom-right:after, .imghvr-pixel-bottom-right .hover-theme:before, .imghvr-pixel-bottom-right .hover-theme:after {
  -webkit-transform-origin: 100% 0;
  transform-origin: 100% 0;
}

.imghvr-pixel-bottom-right:before {
  -webkit-transition-delay: 0.35s;
  transition-delay: 0.35s;
}

.imghvr-pixel-bottom-right:after {
  -webkit-transition-delay: 0.21s;
  transition-delay: 0.21s;
}

.imghvr-pixel-bottom-right .hover-theme:before {
  -webkit-transition-delay: 0.105s;
  transition-delay: 0.105s;
}

.imghvr-pixel-bottom-right .hover-theme:after {
  -webkit-transition-delay: 0s;
  transition-delay: 0s;
}

/* imghvr-pivot-in*
   ----------------------------- */
[class^='imghvr-pivot-in'] .hover-theme, [class*=' imghvr-pivot-in'] .hover-theme {
  background-color: #135796;
}

[class^='imghvr-pivot-in']:hover .hover-theme, [class*=' imghvr-pivot-in']:hover .hover-theme {
  -webkit-transform: rotate(0deg);
  transform: rotate(0deg);
}

/* imghvr-pivot-in-top-left*
   ----------------------------- */
.imghvr-pivot-in-top-left .hover-theme {
  -webkit-transform: rotate(90deg);
  transform: rotate(90deg);
  -webkit-transform-origin: 0 0;
  transform-origin: 0 0;
}

/* imghvr-pivot-in-top-right*
   ----------------------------- */
.imghvr-pivot-in-top-right .hover-theme {
  -webkit-transform: rotate(-90deg);
  transform: rotate(-90deg);
  -webkit-transform-origin: 100% 0;
  transform-origin: 100% 0;
}

/* imghvr-pivot-in-bottom-left*
   ----------------------------- */
.imghvr-pivot-in-bottom-left .hover-theme {
  -webkit-transform: rotate(-90deg);
  transform: rotate(-90deg);
  -webkit-transform-origin: 0 100%;
  transform-origin: 0 100%;
}

/* imghvr-pivot-in-bottom-right*
   ----------------------------- */
.imghvr-pivot-in-bottom-right .hover-theme {
  -webkit-transform: rotate(90deg);
  transform: rotate(90deg);
  -webkit-transform-origin: 100% 100%;
  transform-origin: 100% 100%;
}

/* imghvr-pivot-out-*
   ----------------------------- */
[class^='imghvr-pivot-out'] > img, [class*=' imghvr-pivot-out'] > img {
  -webkit-transform: rotate(0deg);
  transform: rotate(0deg);
}

[class^='imghvr-pivot-out'] .hover-theme, [class*=' imghvr-pivot-out'] .hover-theme {
  background-color: #135796;
  z-index: -1;
}

/* imghvr-pivot-out-top-left*
   ----------------------------- */
.imghvr-pivot-out-top-left > img {
  -webkit-transform-origin: 0 0;
  transform-origin: 0 0;
}

.imghvr-pivot-out-top-left:hover > img {
  -webkit-transform: rotate(90deg);
  transform: rotate(90deg);
}

/* imghvr-pivot-out-top-right*
   ----------------------------- */
.imghvr-pivot-out-top-right > img {
  -webkit-transform-origin: 100% 0;
  transform-origin: 100% 0;
}

.imghvr-pivot-out-top-right:hover > img {
  -webkit-transform: rotate(-90deg);
  transform: rotate(-90deg);
}

/* imghvr-pivot-out-bottom-left*
   ----------------------------- */
.imghvr-pivot-out-bottom-left > img {
  -webkit-transform-origin: 0 100%;
  transform-origin: 0 100%;
}

.imghvr-pivot-out-bottom-left:hover > img {
  -webkit-transform: rotate(-90deg);
  transform: rotate(-90deg);
}

/* imghvr-pivot-out-bottom-right*
   ----------------------------- */
.imghvr-pivot-out-bottom-right > img {
  -webkit-transform-origin: 100% 100%;
  transform-origin: 100% 100%;
}

.imghvr-pivot-out-bottom-right:hover > img {
  -webkit-transform: rotate(90deg);
  transform: rotate(90deg);
}

/* imghvr-throw-in-*
   ----------------------------- */
[class^='imghvr-throw-in'] .hover-theme, [class*=' imghvr-throw-in'] .hover-theme {
  background-color: #135796;
}

[class^='imghvr-throw-in']:hover .hover-theme, [class*=' imghvr-throw-in']:hover .hover-theme {
  -webkit-transform: rotate(0deg);
  transform: rotate(0deg);
}

/* imghvr-throw-in-up
   ----------------------------- */
.imghvr-throw-in-up .hover-theme {
  -webkit-transform: rotate(90deg);
  transform: rotate(90deg);
  -webkit-transform-origin: 0 100%;
  transform-origin: 0 100%;
}

.imghvr-throw-in-up:hover .hover-theme {
  -webkit-transform-origin: 100% 0;
  transform-origin: 100% 0;
}

/* imghvr-throw-in-down
   ----------------------------- */
.imghvr-throw-in-down .hover-theme {
  -webkit-transform: rotate(90deg);
  transform: rotate(90deg);
  -webkit-transform-origin: 100% 0%;
  transform-origin: 100% 0%;
}

.imghvr-throw-in-down:hover .hover-theme {
  -webkit-transform-origin: 0 100%;
  transform-origin: 0 100%;
}

/* imghvr-throw-in-left
   ----------------------------- */
.imghvr-throw-in-left .hover-theme {
  -webkit-transform: rotate(-90deg);
  transform: rotate(-90deg);
  -webkit-transform-origin: 100% 0%;
  transform-origin: 100% 0%;
}

.imghvr-throw-in-left:hover .hover-theme {
  -webkit-transform-origin: 0% 100%;
  transform-origin: 0% 100%;
}

/* imghvr-throw-in-right
   ----------------------------- */
.imghvr-throw-in-right .hover-theme {
  -webkit-transform: rotate(90deg);
  transform: rotate(90deg);
  -webkit-transform-origin: 0 0%;
  transform-origin: 0 0%;
}

.imghvr-throw-in-right:hover .hover-theme {
  -webkit-transform-origin: 100% 100%;
  transform-origin: 100% 100%;
}

/* imghvr-throw-out*
   ----------------------------- */
[class^='imghvr-throw-out'] > img, [class*=' imghvr-throw-out'] > img {
  -webkit-transform: rotate(0);
  transform: rotate(0);
}

[class^='imghvr-throw-out'] .hover-theme, [class*=' imghvr-throw-out'] .hover-theme {
  background-color: #135796;
  z-index: -1;
}

/* imghvr-throw-out-up
   ----------------------------- */
.imghvr-throw-out-up > img {
  -webkit-transform-origin: 0% 100%;
  transform-origin: 0% 100%;
}

.imghvr-throw-out-up:hover > img {
  -webkit-transform: rotate(90deg);
  transform: rotate(90deg);
  -webkit-transform-origin: 100% 0;
  transform-origin: 100% 0;
}

/* imghvr-throw-out-down
   ----------------------------- */
.imghvr-throw-out-down > img {
  -webkit-transform-origin: 0% 100%;
  transform-origin: 0% 100%;
}

.imghvr-throw-out-down:hover > img {
  -webkit-transform: rotate(-90deg);
  transform: rotate(-90deg);
  -webkit-transform-origin: 100% 100%;
  transform-origin: 100% 100%;
}

/* imghvr-throw-out-left
   ----------------------------- */
.imghvr-throw-out-left > img {
  -webkit-transform-origin: 100% 0;
  transform-origin: 100% 0;
}

.imghvr-throw-out-left:hover > img {
  -webkit-transform: rotate(90deg);
  transform: rotate(90deg);
  -webkit-transform-origin: 0 0;
  transform-origin: 0 0;
}

/* imghvr-throw-out-right
   ----------------------------- */
.imghvr-throw-out-right > img {
  -webkit-transform-origin: 0% 0;
  transform-origin: 0% 0;
}

.imghvr-throw-out-right:hover > img {
  -webkit-transform: rotate(-90deg);
  transform: rotate(-90deg);
  -webkit-transform-origin: 100% 0;
  transform-origin: 100% 0;
}

/* imghvr-blinds-*
   ----------------------------- */
[class^='imghvr-blinds']:before, [class^='imghvr-blinds']:after, [class^='imghvr-blinds'] .hover-theme:before, [class^='imghvr-blinds'] .hover-theme:after, [class*=' imghvr-blinds']:before, [class*=' imghvr-blinds']:after, [class*=' imghvr-blinds'] .hover-theme:before, [class*=' imghvr-blinds'] .hover-theme:after {
  background-color: #135796;
  opacity: 0;
  position: absolute;
  content: '';
}

[class^='imghvr-blinds']:before, [class^='imghvr-blinds']:after, [class*=' imghvr-blinds']:before, [class*=' imghvr-blinds']:after {
  z-index: 1;
}

[class^='imghvr-blinds']:before, [class*=' imghvr-blinds']:before {
  top: 0;
  -webkit-transition-delay: 0s;
  transition-delay: 0s;
}

[class^='imghvr-blinds']:after, [class*=' imghvr-blinds']:after {
  top: 25%;
  -webkit-transition-delay: 0.105s;
  transition-delay: 0.105s;
}

[class^='imghvr-blinds'] .hover-theme, [class*=' imghvr-blinds'] .hover-theme {
  z-index: 3;
  background-color: transparent;
}

[class^='imghvr-blinds'] .hover-theme *, [class*=' imghvr-blinds'] .hover-theme * {
  opacity: 0;
  -webkit-transition-delay: 0s;
  transition-delay: 0s;
}

[class^='imghvr-blinds'] .hover-theme:before, [class^='imghvr-blinds'] .hover-theme:after, [class*=' imghvr-blinds'] .hover-theme:before, [class*=' imghvr-blinds'] .hover-theme:after {
  z-index: -1;
}

[class^='imghvr-blinds'] a, [class*=' imghvr-blinds'] a {
  z-index: 3;
}

[class^='imghvr-blinds']:hover:before, [class^='imghvr-blinds']:hover:after, [class^='imghvr-blinds']:hover .hover-theme:before, [class^='imghvr-blinds']:hover .hover-theme:after, [class*=' imghvr-blinds']:hover:before, [class*=' imghvr-blinds']:hover:after, [class*=' imghvr-blinds']:hover .hover-theme:before, [class*=' imghvr-blinds']:hover .hover-theme:after {
  -webkit-transform: scale(1);
  transform: scale(1);
  opacity: 1;
}

[class^='imghvr-blinds']:hover .hover-theme *, [class*=' imghvr-blinds']:hover .hover-theme * {
  opacity: 1;
  -webkit-transition-delay: 0.4025s;
  transition-delay: 0.4025s;
}

/* imghvr-blinds-horiz
   ----------------------------- */
.imghvr-blinds-horiz:before, .imghvr-blinds-horiz:after, .imghvr-blinds-horiz .hover-theme:before, .imghvr-blinds-horiz .hover-theme:after {
  height: 26%;
  width: 100%;
  -webkit-transform: scaleY(0);
  transform: scaleY(0);
  left: 0;
}

.imghvr-blinds-horiz:before {
  top: 0;
}

.imghvr-blinds-horiz:after {
  top: 25%;
}

.imghvr-blinds-horiz .hover-theme:before {
  top: 50%;
}

.imghvr-blinds-horiz .hover-theme:after {
  top: 75%;
}

.imghvr-blinds-horiz:hover .hover-theme * {
  -webkit-transition-delay: 0.35s;
  transition-delay: 0.35s;
}

/* imghvr-blinds-vert
   ----------------------------- */
.imghvr-blinds-vert:before, .imghvr-blinds-vert:after, .imghvr-blinds-vert .hover-theme:before, .imghvr-blinds-vert .hover-theme:after {
  width: 26%;
  height: 100%;
  -webkit-transform: scaleX(0);
  transform: scaleX(0);
  top: 0;
}

.imghvr-blinds-vert:before {
  left: 0;
}

.imghvr-blinds-vert:after {
  left: 25%;
}

.imghvr-blinds-vert .hover-theme:before {
  left: 50%;
}

.imghvr-blinds-vert .hover-theme:after {
  left: 75%;
}

.imghvr-blinds-vert:hover .hover-theme * {
  -webkit-transition-delay: 0.35s;
  transition-delay: 0.35s;
}

/* imghvr-blinds-up
   ----------------------------- */
.imghvr-blinds-up:before, .imghvr-blinds-up:after, .imghvr-blinds-up .hover-theme:before, .imghvr-blinds-up .hover-theme:after {
  height: 26%;
  width: 100%;
  -webkit-transform: scaleY(0);
  transform: scaleY(0);
  left: 0;
}

.imghvr-blinds-up:before {
  top: 0;
  -webkit-transition-delay: 0.35s;
  transition-delay: 0.35s;
}

.imghvr-blinds-up:after {
  top: 25%;
  -webkit-transition-delay: 0.21s;
  transition-delay: 0.21s;
}

.imghvr-blinds-up .hover-theme:before {
  top: 50%;
  -webkit-transition-delay: 0.105s;
  transition-delay: 0.105s;
}

.imghvr-blinds-up .hover-theme:after {
  top: 75%;
  -webkit-transition-delay: 0s;
  transition-delay: 0s;
}

/* imghvr-blinds-down
   ----------------------------- */
.imghvr-blinds-down:before, .imghvr-blinds-down:after, .imghvr-blinds-down .hover-theme:before, .imghvr-blinds-down .hover-theme:after {
  height: 26%;
  width: 100%;
  -webkit-transform: scaleY(0);
  transform: scaleY(0);
  left: 0;
}

.imghvr-blinds-down:before {
  top: 0;
  -webkit-transition-delay: 0s;
  transition-delay: 0s;
}

.imghvr-blinds-down:after {
  top: 25%;
  -webkit-transition-delay: 0.105s;
  transition-delay: 0.105s;
}

.imghvr-blinds-down .hover-theme:before {
  top: 50%;
  -webkit-transition-delay: 0.21s;
  transition-delay: 0.21s;
}

.imghvr-blinds-down .hover-theme:after {
  top: 75%;
  -webkit-transition-delay: 0.35s;
  transition-delay: 0.35s;
}

/* imghvr-blinds-left
   ----------------------------- */
.imghvr-blinds-left:before, .imghvr-blinds-left:after, .imghvr-blinds-left .hover-theme:before, .imghvr-blinds-left .hover-theme:after {
  width: 26%;
  height: 100%;
  -webkit-transform: scaleX(0);
  transform: scaleX(0);
  top: 0;
}

.imghvr-blinds-left:before {
  left: 0;
  -webkit-transition-delay: 0.35s;
  transition-delay: 0.35s;
}

.imghvr-blinds-left:after {
  left: 25%;
  -webkit-transition-delay: 0.21s;
  transition-delay: 0.21s;
}

.imghvr-blinds-left .hover-theme:before {
  left: 50%;
  -webkit-transition-delay: 0.105s;
  transition-delay: 0.105s;
}

.imghvr-blinds-left .hover-theme:after {
  left: 75%;
  -webkit-transition-delay: 0s;
  transition-delay: 0s;
}

/* imghvr-blinds-right
   ----------------------------- */
.imghvr-blinds-right:before, .imghvr-blinds-right:after, .imghvr-blinds-right .hover-theme:before, .imghvr-blinds-right .hover-theme:after {
  width: 26%;
  height: 100%;
  -webkit-transform: scaleX(0);
  transform: scaleX(0);
  top: 0;
}

.imghvr-blinds-right:before {
  left: 0;
  -webkit-transition-delay: 0s;
  transition-delay: 0s;
}

.imghvr-blinds-right:after {
  left: 25%;
  -webkit-transition-delay: 0.105s;
  transition-delay: 0.105s;
}

.imghvr-blinds-right .hover-theme:before {
  left: 50%;
  -webkit-transition-delay: 0.21s;
  transition-delay: 0.21s;
}

.imghvr-blinds-right .hover-theme:after {
  left: 75%;
  -webkit-transition-delay: 0.35s;
  transition-delay: 0.35s;
}

/* imghvr-border-reveal-*
   ----------------------------- */
[class^='imghvr-border-reveal'], [class*=' imghvr-border-reveal'] {
  background-color: #135796;
}

[class^='imghvr-border-reveal']:before, [class^='imghvr-border-reveal']:after, [class^='imghvr-border-reveal'] .hover-theme:before, [class^='imghvr-border-reveal'] .hover-theme:after, [class*=' imghvr-border-reveal']:before, [class*=' imghvr-border-reveal']:after, [class*=' imghvr-border-reveal'] .hover-theme:before, [class*=' imghvr-border-reveal'] .hover-theme:after {
  background-color: #ffffff;
  position: absolute;
  content: '';
}

[class^='imghvr-border-reveal']:before, [class^='imghvr-border-reveal']:after, [class*=' imghvr-border-reveal']:before, [class*=' imghvr-border-reveal']:after {
  z-index: 1;
  height: 4px;
  left: 5px;
  right: 5px;
  -webkit-transform: scaleX(0);
  transform: scaleX(0);
}

[class^='imghvr-border-reveal']:before, [class*=' imghvr-border-reveal']:before {
  top: 5px;
}

[class^='imghvr-border-reveal']:after, [class*=' imghvr-border-reveal']:after {
  bottom: 5px;
}

[class^='imghvr-border-reveal'] .hover-theme, [class*=' imghvr-border-reveal'] .hover-theme {
  z-index: 3;
  background-color: transparent;
}

[class^='imghvr-border-reveal'] .hover-theme *, [class*=' imghvr-border-reveal'] .hover-theme * {
  opacity: 0;
}

[class^='imghvr-border-reveal'] .hover-theme:before, [class^='imghvr-border-reveal'] .hover-theme:after, [class*=' imghvr-border-reveal'] .hover-theme:before, [class*=' imghvr-border-reveal'] .hover-theme:after {
  z-index: -1;
  width: 4px;
  top: 5px;
  bottom: 5px;
  -webkit-transform: scaleY(0);
  transform: scaleY(0);
}

[class^='imghvr-border-reveal'] .hover-theme:before, [class*=' imghvr-border-reveal'] .hover-theme:before {
  left: 5px;
}

[class^='imghvr-border-reveal'] .hover-theme:after, [class*=' imghvr-border-reveal'] .hover-theme:after {
  right: 5px;
}

[class^='imghvr-border-reveal'] a, [class*=' imghvr-border-reveal'] a {
  z-index: 3;
}

[class^='imghvr-border-reveal']:hover > img, [class*=' imghvr-border-reveal']:hover > img {
  opacity: 0;
}

[class^='imghvr-border-reveal']:hover:before, [class^='imghvr-border-reveal']:hover:after, [class^='imghvr-border-reveal']:hover .hover-theme:before, [class^='imghvr-border-reveal']:hover .hover-theme:after, [class*=' imghvr-border-reveal']:hover:before, [class*=' imghvr-border-reveal']:hover:after, [class*=' imghvr-border-reveal']:hover .hover-theme:before, [class*=' imghvr-border-reveal']:hover .hover-theme:after {
  -webkit-transform: scale(1);
  transform: scale(1);
}

[class^='imghvr-border-reveal']:hover .hover-theme *, [class*=' imghvr-border-reveal']:hover .hover-theme * {
  opacity: 1;
  -webkit-transition-delay: 0.35s;
  transition-delay: 0.35s;
}

/* imghvr-border-reveal-vert
   ----------------------------- */
.imghvr-border-reveal-vert:before, .imghvr-border-reveal-vert:after {
  -webkit-transition-duration: 0s;
  transition-duration: 0s;
}

.imghvr-border-reveal-vert:hover:before, .imghvr-border-reveal-vert:hover:after {
  -webkit-transition-delay: 0.23333s;
  transition-delay: 0.23333s;
}

/* imghvr-border-reveal-horiz
   ----------------------------- */
.imghvr-border-reveal-horiz .hover-theme:before, .imghvr-border-reveal-horiz .hover-theme:after {
  -webkit-transition-duration: 0s;
  transition-duration: 0s;
}

.imghvr-border-reveal-horiz:hover .hover-theme:before, .imghvr-border-reveal-horiz:hover .hover-theme:after {
  -webkit-transition-delay: 0.23333s;
  transition-delay: 0.23333s;
}

/* imghvr-border-reveal-corners-1
   ----------------------------- */
.imghvr-border-reveal-corners-1:before, .imghvr-border-reveal-corners-1 .hover-theme:before {
  -webkit-transform-origin: 0 0;
  transform-origin: 0 0;
}

.imghvr-border-reveal-corners-1:after, .imghvr-border-reveal-corners-1 .hover-theme:after {
  -webkit-transform-origin: 100% 100%;
  transform-origin: 100% 100%;
}

/* imghvr-border-reveal-corners-2
   ----------------------------- */
.imghvr-border-reveal-corners-2:before, .imghvr-border-reveal-corners-2 .hover-theme:before {
  -webkit-transform-origin: 100% 100%;
  transform-origin: 100% 100%;
}

.imghvr-border-reveal-corners-2:after, .imghvr-border-reveal-corners-2 .hover-theme:after {
  -webkit-transform-origin: 0% 0%;
  transform-origin: 0% 0%;
}

/* imghvr-border-reveal-top-left
   ----------------------------- */
.imghvr-border-reveal-top-left:before, .imghvr-border-reveal-top-left:after, .imghvr-border-reveal-top-left .hover-theme:before, .imghvr-border-reveal-top-left .hover-theme:after {
  -webkit-transform-origin: 0 0;
  transform-origin: 0 0;
}

.imghvr-border-reveal-top-left:before {
  -webkit-transition-delay: 0.28s;
  transition-delay: 0.28s;
}

.imghvr-border-reveal-top-left:after {
  -webkit-transition-delay: 0s;
  transition-delay: 0s;
}

.imghvr-border-reveal-top-left .hover-theme:before {
  -webkit-transition-delay: 0.28s;
  transition-delay: 0.28s;
}

.imghvr-border-reveal-top-left .hover-theme:after {
  -webkit-transition-delay: 0s;
  transition-delay: 0s;
}

.imghvr-border-reveal-top-left:hover:before {
  -webkit-transition-delay: 0s;
  transition-delay: 0s;
}

.imghvr-border-reveal-top-left:hover:after {
  -webkit-transition-delay: 0.28s;
  transition-delay: 0.28s;
}

.imghvr-border-reveal-top-left:hover .hover-theme:before {
  -webkit-transition-delay: 0s;
  transition-delay: 0s;
}

.imghvr-border-reveal-top-left:hover .hover-theme:after {
  -webkit-transition-delay: 0.28s;
  transition-delay: 0.28s;
}

/* imghvr-border-reveal-top-right
   ----------------------------- */
.imghvr-border-reveal-top-right:before, .imghvr-border-reveal-top-right:after, .imghvr-border-reveal-top-right .hover-theme:before, .imghvr-border-reveal-top-right .hover-theme:after {
  -webkit-transform-origin: 100% 0%;
  transform-origin: 100% 0%;
}

.imghvr-border-reveal-top-right:before {
  -webkit-transition-delay: 0.28s;
  transition-delay: 0.28s;
}

.imghvr-border-reveal-top-right:after {
  -webkit-transition-delay: 0s;
  transition-delay: 0s;
}

.imghvr-border-reveal-top-right .hover-theme:before {
  -webkit-transition-delay: 0s;
  transition-delay: 0s;
}

.imghvr-border-reveal-top-right .hover-theme:after {
  -webkit-transition-delay: 0.28s;
  transition-delay: 0.28s;
}

.imghvr-border-reveal-top-right:hover:before {
  -webkit-transition-delay: 0s;
  transition-delay: 0s;
}

.imghvr-border-reveal-top-right:hover:after {
  -webkit-transition-delay: 0.28s;
  transition-delay: 0.28s;
}

.imghvr-border-reveal-top-right:hover .hover-theme:before {
  -webkit-transition-delay: 0.28s;
  transition-delay: 0.28s;
}

.imghvr-border-reveal-top-right:hover .hover-theme:after {
  -webkit-transition-delay: 0s;
  transition-delay: 0s;
}

/* imghvr-border-reveal-bottom-left
   ----------------------------- */
.imghvr-border-reveal-bottom-left:before, .imghvr-border-reveal-bottom-left:after, .imghvr-border-reveal-bottom-left .hover-theme:before, .imghvr-border-reveal-bottom-left .hover-theme:after {
  -webkit-transform-origin: 0% 100%;
  transform-origin: 0% 100%;
}

.imghvr-border-reveal-bottom-left:before {
  -webkit-transition-delay: 0s;
  transition-delay: 0s;
}

.imghvr-border-reveal-bottom-left:after {
  -webkit-transition-delay: 0.28s;
  transition-delay: 0.28s;
}

.imghvr-border-reveal-bottom-left .hover-theme:before {
  -webkit-transition-delay: 0.28s;
  transition-delay: 0.28s;
}

.imghvr-border-reveal-bottom-left .hover-theme:after {
  -webkit-transition-delay: 0s;
  transition-delay: 0s;
}

.imghvr-border-reveal-bottom-left:hover:before {
  -webkit-transition-delay: 0.28s;
  transition-delay: 0.28s;
}

.imghvr-border-reveal-bottom-left:hover:after {
  -webkit-transition-delay: 0s;
  transition-delay: 0s;
}

.imghvr-border-reveal-bottom-left:hover .hover-theme:before {
  -webkit-transition-delay: 0s;
  transition-delay: 0s;
}

.imghvr-border-reveal-bottom-left:hover .hover-theme:after {
  -webkit-transition-delay: 0.28s;
  transition-delay: 0.28s;
}

/* imghvr-border-reveal-bottom-right
   ----------------------------- */
.imghvr-border-reveal-bottom-right:before, .imghvr-border-reveal-bottom-right:after, .imghvr-border-reveal-bottom-right .hover-theme:before, .imghvr-border-reveal-bottom-right .hover-theme:after {
  -webkit-transform-origin: 100% 100%;
  transform-origin: 100% 100%;
}

.imghvr-border-reveal-bottom-right:before {
  -webkit-transition-delay: 0s;
  transition-delay: 0s;
}

.imghvr-border-reveal-bottom-right:after {
  -webkit-transition-delay: 0.28s;
  transition-delay: 0.28s;
}

.imghvr-border-reveal-bottom-right .hover-theme:before {
  -webkit-transition-delay: 0s;
  transition-delay: 0s;
}

.imghvr-border-reveal-bottom-right .hover-theme:after {
  -webkit-transition-delay: 0.28s;
  transition-delay: 0.28s;
}

.imghvr-border-reveal-bottom-right:hover:before {
  -webkit-transition-delay: 0.28s;
  transition-delay: 0.28s;
}

.imghvr-border-reveal-bottom-right:hover:after {
  -webkit-transition-delay: 0s;
  transition-delay: 0s;
}

.imghvr-border-reveal-bottom-right:hover .hover-theme:before {
  -webkit-transition-delay: 0.28s;
  transition-delay: 0.28s;
}

.imghvr-border-reveal-bottom-right:hover .hover-theme:after {
  -webkit-transition-delay: 0s;
  transition-delay: 0s;
}

/* imghvr-border-reveal-cc-1
   ----------------------------- */
.imghvr-border-reveal-cc-1:before, .imghvr-border-reveal-cc-1:after, .imghvr-border-reveal-cc-1 .hover-theme:before, .imghvr-border-reveal-cc-1 .hover-theme:after {
  -webkit-transition-duration: 0.23333s;
  transition-duration: 0.23333s;
}

.imghvr-border-reveal-cc-1:before, .imghvr-border-reveal-cc-1 .hover-theme:after {
  -webkit-transform-origin: 0 0;
  transform-origin: 0 0;
}

.imghvr-border-reveal-cc-1:after, .imghvr-border-reveal-cc-1 .hover-theme:before {
  -webkit-transform-origin: 100% 100%;
  transform-origin: 100% 100%;
}

.imghvr-border-reveal-cc-1:before {
  -webkit-transition-delay: 0.525s;
  transition-delay: 0.525s;
}

.imghvr-border-reveal-cc-1:after {
  -webkit-transition-delay: 0.175s;
  transition-delay: 0.175s;
}

.imghvr-border-reveal-cc-1 .hover-theme:before {
  -webkit-transition-delay: 0s;
  transition-delay: 0s;
}

.imghvr-border-reveal-cc-1 .hover-theme:after {
  -webkit-transition-delay: 0.35s;
  transition-delay: 0.35s;
}

.imghvr-border-reveal-cc-1:hover:before {
  -webkit-transition-delay: 0s;
  transition-delay: 0s;
}

.imghvr-border-reveal-cc-1:hover:after {
  -webkit-transition-delay: 0.35s;
  transition-delay: 0.35s;
}

.imghvr-border-reveal-cc-1:hover .hover-theme:before {
  -webkit-transition-delay: 0.525s;
  transition-delay: 0.525s;
}

.imghvr-border-reveal-cc-1:hover .hover-theme:after {
  -webkit-transition-delay: 0.175s;
  transition-delay: 0.175s;
}

/* imghvr-border-reveal-ccc-1
   ----------------------------- */
.imghvr-border-reveal-ccc-1:before, .imghvr-border-reveal-ccc-1:after, .imghvr-border-reveal-ccc-1 .hover-theme:before, .imghvr-border-reveal-ccc-1 .hover-theme:after {
  -webkit-transition-duration: 0.23333s;
  transition-duration: 0.23333s;
}

.imghvr-border-reveal-ccc-1:before, .imghvr-border-reveal-ccc-1 .hover-theme:after {
  -webkit-transform-origin: 100% 100%;
  transform-origin: 100% 100%;
}

.imghvr-border-reveal-ccc-1:after, .imghvr-border-reveal-ccc-1 .hover-theme:before {
  -webkit-transform-origin: 0% 0%;
  transform-origin: 0% 0%;
}

.imghvr-border-reveal-ccc-1:before {
  -webkit-transition-delay: 0;
  transition-delay: 0;
}

.imghvr-border-reveal-ccc-1:after {
  -webkit-transition-delay: 0.35s;
  transition-delay: 0.35s;
}

.imghvr-border-reveal-ccc-1 .hover-theme:before {
  -webkit-transition-delay: 0.525s;
  transition-delay: 0.525s;
}

.imghvr-border-reveal-ccc-1 .hover-theme:after {
  -webkit-transition-delay: 0.175s;
  transition-delay: 0.175s;
}

.imghvr-border-reveal-ccc-1:hover:before {
  -webkit-transition-delay: 0.525s;
  transition-delay: 0.525s;
}

.imghvr-border-reveal-ccc-1:hover:after {
  -webkit-transition-delay: 0.175s;
  transition-delay: 0.175s;
}

.imghvr-border-reveal-ccc-1:hover .hover-theme:before {
  -webkit-transition-delay: 0s;
  transition-delay: 0s;
}

.imghvr-border-reveal-ccc-1:hover .hover-theme:after {
  -webkit-transition-delay: 0.35s;
  transition-delay: 0.35s;
}

/* imghvr-border-reveal-cc-2
   ----------------------------- */
.imghvr-border-reveal-cc-2:before, .imghvr-border-reveal-cc-2 .hover-theme:after {
  -webkit-transform-origin: 0 0;
  transform-origin: 0 0;
}

.imghvr-border-reveal-cc-2:after, .imghvr-border-reveal-cc-2 .hover-theme:before {
  -webkit-transform-origin: 100% 100%;
  transform-origin: 100% 100%;
}

/* imghvr-border-reveal-ccc-2
   ----------------------------- */
.imghvr-border-reveal-ccc-2:before, .imghvr-border-reveal-ccc-2 .hover-theme:after {
  -webkit-transform-origin: 100% 100%;
  transform-origin: 100% 100%;
}

.imghvr-border-reveal-ccc-2:after, .imghvr-border-reveal-ccc-2 .hover-theme:before {
  -webkit-transform-origin: 0% 0%;
  transform-origin: 0% 0%;
}

/* imghvr-border-reveal-cc-3
   ----------------------------- */
.imghvr-border-reveal-cc-3:before, .imghvr-border-reveal-cc-3 .hover-theme:after {
  -webkit-transform-origin: 0 0;
  transform-origin: 0 0;
}

.imghvr-border-reveal-cc-3:after, .imghvr-border-reveal-cc-3 .hover-theme:before {
  -webkit-transform-origin: 100% 100%;
  transform-origin: 100% 100%;
}

.imghvr-border-reveal-cc-3:before {
  right: 14px;
  left: 0;
}

.imghvr-border-reveal-cc-3:after {
  left: 14px;
  right: 0;
}

.imghvr-border-reveal-cc-3 .hover-theme:before {
  top: 14px;
  bottom: 0;
}

.imghvr-border-reveal-cc-3 .hover-theme:after {
  bottom: 14px;
  top: 0;
}

/* imghvr-border-reveal-ccc-3
   ----------------------------- */
.imghvr-border-reveal-ccc-3:before, .imghvr-border-reveal-ccc-3 .hover-theme:after {
  -webkit-transform-origin: 100% 100%;
  transform-origin: 100% 100%;
}

.imghvr-border-reveal-ccc-3:after, .imghvr-border-reveal-ccc-3 .hover-theme:before {
  -webkit-transform-origin: 0% 0%;
  transform-origin: 0% 0%;
}

.imghvr-border-reveal-ccc-3:before {
  left: 14px;
  right: 0;
}

.imghvr-border-reveal-ccc-3:after {
  right: 14px;
  left: 0;
}

.imghvr-border-reveal-ccc-3 .hover-theme:before {
  bottom: 14px;
  top: 0;
}

.imghvr-border-reveal-ccc-3 .hover-theme:after {
  top: 14px;
  bottom: 0;
}

/* imghvr-image-*
   ----------------------------- */
[class^='imghvr-image-'] .hover-theme, [class*=' imghvr-image-'] .hover-theme {
  opacity: 0;
  -webkit-transition-delay: 0s;
  transition-delay: 0s;
}

[class^='imghvr-image-']:hover .hover-theme, [class*=' imghvr-image-']:hover .hover-theme {
  opacity: 1;
  -webkit-transition-delay: 0.14s;
  transition-delay: 0.14s;
}

/* imghvr-image-zoom-center
   ----------------------------- */
.imghvr-image-zoom-center:hover > img {
  -webkit-transform: scale(1.25);
  transform: scale(1.25);
}

/* imghvr-image-zoom-out
   ----------------------------- */
.imghvr-image-zoom-out:hover > img {
  -webkit-transform: scale(0.7);
  transform: scale(0.7);
}

/* imghvr-image-rotate-left
   ----------------------------- */
.imghvr-image-rotate-left:hover > img {
  -webkit-transform: scale(1.3) rotate(-15deg);
  transform: scale(1.3) rotate(-15deg);
}

/* imghvr-image-rotate-right
   ----------------------------- */
.imghvr-image-rotate-right:hover > img {
  -webkit-transform: scale(1.3) rotate(15deg);
  transform: scale(1.3) rotate(15deg);
}

/* imghvr-book-open-*
   ----------------------------- */
*[class^='imghvr-book-open-'], *[class*=' imghvr-book-open-'] {
  overflow: visible;
}

*[class^='imghvr-book-open-'] .hover-theme, *[class*=' imghvr-book-open-'] .hover-theme {
  background-color: transparent;
  border: none;
}

*[class^='imghvr-book-open-'] .hover-theme:before, *[class^='imghvr-book-open-'] .hover-theme:after, *[class*=' imghvr-book-open-'] .hover-theme:before, *[class*=' imghvr-book-open-'] .hover-theme:after {
  z-index: -1;
  background-color: black;
  content: '';
  position: absolute;
  opacity: 0;
  -webkit-transition: all 0.45s ease;
  transition: all 0.45s ease;
}

*[class^='imghvr-book-open-']:hover .hover-theme:before, *[class^='imghvr-book-open-']:hover .hover-theme:after, *[class*=' imghvr-book-open-']:hover .hover-theme:before, *[class*=' imghvr-book-open-']:hover .hover-theme:after {
  opacity: 1;
  background-color: #135796;
  -webkit-transform: rotateY(0deg);
  transform: rotateY(0deg);
}

/* imghvr-book-open-horiz
   ----------------------------- */
.imghvr-book-open-horiz .hover-theme {
  -webkit-perspective: 50em;
  perspective: 50em;
}

.imghvr-book-open-horiz .hover-theme * {
  opacity: 0;
}

.imghvr-book-open-horiz .hover-theme:before, .imghvr-book-open-horiz .hover-theme:after {
  height: 100%;
  width: 50%;
  top: 0;
}

.imghvr-book-open-horiz .hover-theme:before {
  background-image: -webkit-linear-gradient( left , transparent 60%, rgba(0, 0, 0, 0.1) 99%, rgba(0, 0, 0, 0.15) 100%);
  background-image: linear-gradient(to right, transparent 60%, rgba(0, 0, 0, 0.1) 99%, rgba(0, 0, 0, 0.15) 100%);
  left: 0;
  -webkit-transform: rotateY(90deg);
  transform: rotateY(90deg);
  -webkit-transform-origin: 100% 50%;
  transform-origin: 100% 50%;
}

.imghvr-book-open-horiz .hover-theme:after {
  background-image: -webkit-linear-gradient( right , transparent 60%, rgba(0, 0, 0, 0.12) 99%, rgba(0, 0, 0, 0.15) 100%);
  background-image: linear-gradient(to left, transparent 60%, rgba(0, 0, 0, 0.12) 99%, rgba(0, 0, 0, 0.15) 100%);
  right: 0;
  -webkit-transform: rotateY(-90deg);
  transform: rotateY(-90deg);
  -webkit-transform-origin: 0% 50%;
  transform-origin: 0% 50%;
}

.imghvr-book-open-horiz:hover .hover-theme * {
  opacity: 1;
  -webkit-transition-delay: 0.21s;
  transition-delay: 0.21s;
}

/* imghvr-book-open-vert
   ----------------------------- */
.imghvr-book-open-vert .hover-theme {
  -webkit-perspective: 50em;
  perspective: 50em;
}

.imghvr-book-open-vert .hover-theme * {
  opacity: 0;
}

.imghvr-book-open-vert .hover-theme:before, .imghvr-book-open-vert .hover-theme:after {
  height: 50%;
  width: 100%;
  left: 0;
}

.imghvr-book-open-vert .hover-theme:before {
  background-image: -webkit-linear-gradient( top , transparent 60%, rgba(0, 0, 0, 0.1) 99%, rgba(0, 0, 0, 0.15) 100%);
  background-image: linear-gradient(to bottom, transparent 60%, rgba(0, 0, 0, 0.1) 99%, rgba(0, 0, 0, 0.15) 100%);
  top: 0;
  -webkit-transform: rotateX(-90deg);
  transform: rotateX(-90deg);
  -webkit-transform-origin: 50% 100%;
  transform-origin: 50% 100%;
}

.imghvr-book-open-vert .hover-theme:after {
  background-image: -webkit-linear-gradient( bottom , transparent 60%, rgba(0, 0, 0, 0.13) 99%, rgba(0, 0, 0, 0.15) 100%);
  background-image: linear-gradient(to top, transparent 60%, rgba(0, 0, 0, 0.13) 99%, rgba(0, 0, 0, 0.15) 100%);
  bottom: 0;
  -webkit-transform: rotateX(90deg);
  transform: rotateX(90deg);
  -webkit-transform-origin: 50% 0%;
  transform-origin: 50% 0%;
}

.imghvr-book-open-vert:hover .hover-theme * {
  opacity: 1;
  -webkit-transition-delay: 0.21s;
  transition-delay: 0.21s;
}

/* imghvr-book-open-up
   ----------------------------- */
.imghvr-book-open-up {
  -webkit-perspective: 50em;
  perspective: 50em;
}

.imghvr-book-open-up > img {
  -webkit-transform-origin: 50% 0%;
  transform-origin: 50% 0%;
}

.imghvr-book-open-up .hover-theme {
  z-index: -1;
}

.imghvr-book-open-up .hover-theme * {
  opacity: 1;
}

.imghvr-book-open-up .hover-theme:after {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-image: -webkit-linear-gradient( bottom , transparent 80%, rgba(0, 0, 0, 0.2) 99%, rgba(0, 0, 0, 0.3) 100%);
  background-image: linear-gradient(to top, transparent 80%, rgba(0, 0, 0, 0.2) 99%, rgba(0, 0, 0, 0.3) 100%);
  left: 0;
}

.imghvr-book-open-up:hover > img {
  -webkit-transform: rotateX(86deg);
  transform: rotateX(86deg);
}

/* imghvr-book-open-down
   ----------------------------- */
.imghvr-book-open-down {
  -webkit-perspective: 50em;
  perspective: 50em;
}

.imghvr-book-open-down > img {
  -webkit-transform-origin: 50% 100%;
  transform-origin: 50% 100%;
}

.imghvr-book-open-down .hover-theme {
  z-index: -1;
}

.imghvr-book-open-down .hover-theme * {
  opacity: 1;
}

.imghvr-book-open-down .hover-theme:after {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-image: -webkit-linear-gradient( top , transparent 80%, rgba(0, 0, 0, 0.2) 99%, rgba(0, 0, 0, 0.3) 100%);
  background-image: linear-gradient(to bottom, transparent 80%, rgba(0, 0, 0, 0.2) 99%, rgba(0, 0, 0, 0.3) 100%);
  left: 0;
}

.imghvr-book-open-down:hover > img {
  -webkit-transform: rotateX(-86deg);
  transform: rotateX(-86deg);
}

/* imghvr-book-open-left
   ----------------------------- */
.imghvr-book-open-left {
  -webkit-perspective: 50em;
  perspective: 50em;
}

.imghvr-book-open-left > img {
  -webkit-transform-origin: 0% 50%;
  transform-origin: 0% 50%;
}

.imghvr-book-open-left .hover-theme {
  z-index: -1;
}

.imghvr-book-open-left .hover-theme * {
  opacity: 1;
}

.imghvr-book-open-left .hover-theme:after {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-image: -webkit-linear-gradient( right , transparent 80%, rgba(0, 0, 0, 0.2) 99%, rgba(0, 0, 0, 0.3) 100%);
  background-image: linear-gradient(to left, transparent 80%, rgba(0, 0, 0, 0.2) 99%, rgba(0, 0, 0, 0.3) 100%);
  left: 0;
}

.imghvr-book-open-left:hover > img {
  -webkit-transform: rotateY(-86deg);
  transform: rotateY(-86deg);
}

/* imghvr-book-open-right
   ----------------------------- */
.imghvr-book-open-right {
  -webkit-perspective: 50em;
  perspective: 50em;
}

.imghvr-book-open-right > img {
  -webkit-transform-origin: 100% 50%;
  transform-origin: 100% 50%;
}

.imghvr-book-open-right .hover-theme {
  z-index: -1;
}

.imghvr-book-open-right .hover-theme * {
  opacity: 1;
}

.imghvr-book-open-right .hover-theme:after {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-image: -webkit-linear-gradient( left , transparent 80%, rgba(0, 0, 0, 0.2) 99%, rgba(0, 0, 0, 0.3) 100%);
  background-image: linear-gradient(to right, transparent 80%, rgba(0, 0, 0, 0.2) 99%, rgba(0, 0, 0, 0.3) 100%);
  left: 0;
}

.imghvr-book-open-right:hover > img {
  -webkit-transform: rotateY(86deg);
  transform: rotateY(86deg);
}

/* imghvr-circle-*
   ----------------------------- */
[class^='imghvr-circle-']:before, [class*=' imghvr-circle-']:before {
  position: absolute;
  top: -25%;
  bottom: -25%;
  left: -25%;
  right: -25%;
  content: '';
  background-color: #135796;
  border-radius: 50%;
  -webkit-transition: all 0.35s linear;
  transition: all 0.35s linear;
}

[class^='imghvr-circle-'] .hover-theme, [class*=' imghvr-circle-'] .hover-theme {
  opacity: 0;
  background-color: transparent;
}

[class^='imghvr-circle-']:hover:before, [class*=' imghvr-circle-']:hover:before {
  -webkit-transform: translate(0, 0);
  transform: translate(0, 0);
}

[class^='imghvr-circle-']:hover .hover-theme, [class*=' imghvr-circle-']:hover .hover-theme {
  opacity: 1;
  -webkit-transition-delay: 0.35s;
  transition-delay: 0.35s;
}

/* imghvr-circle-up
   ----------------------------- */
.imghvr-circle-up:before {
  -webkit-transform: translateY(100%);
  transform: translateY(100%);
}

/* imghvr-circle-down
   ----------------------------- */
.imghvr-circle-down:before {
  -webkit-transform: translateY(-100%);
  transform: translateY(-100%);
}

/* imghvr-circle-left
   ----------------------------- */
.imghvr-circle-left:before {
  -webkit-transform: translateX(100%);
  transform: translateX(100%);
}

/* imghvr-circle-right
   ----------------------------- */
.imghvr-circle-right:before {
  -webkit-transform: translateX(-100%);
  transform: translateX(-100%);
}

/* imghvr-circle-top-left
   ----------------------------- */
.imghvr-circle-top-left:before {
  -webkit-transform: translate(-100%, -100%);
  transform: translate(-100%, -100%);
}

/* imghvr-circle-top-right
   ----------------------------- */
.imghvr-circle-top-right:before {
  -webkit-transform: translate(100%, -100%);
  transform: translate(100%, -100%);
}

/* imghvr-circle-bottom-left
   ----------------------------- */
.imghvr-circle-bottom-left:before {
  -webkit-transform: translate(-100%, 100%);
  transform: translate(-100%, 100%);
}

/* imghvr-circle-bottom-right
   ----------------------------- */
.imghvr-circle-bottom-right:before {
  -webkit-transform: translate(100%, 100%);
  transform: translate(100%, 100%);
}

/* imghvr-shift-*
   ----------------------------- */
[class^='imghvr-shift'], [class*=' imghvr-shift'] {
  overflow: visible;
}

[class^='imghvr-shift'] .hover-theme, [class*=' imghvr-shift'] .hover-theme {
  background-color: #135796;
  opacity: 0;
}

/* imghvr-shift-top-left
   ----------------------------- */
.imghvr-shift-top-left:hover .hover-theme {
  opacity: 1;
  -webkit-transform: translate(-10px, -10px);
  transform: translate(-10px, -10px);
}

/* imghvr-shift-top-right
   ----------------------------- */
.imghvr-shift-top-right:hover .hover-theme {
  opacity: 1;
  -webkit-transform: translate(10px, -10px);
  transform: translate(10px, -10px);
}

/* imghvr-shift-bottom-left
   ----------------------------- */
.imghvr-shift-bottom-left:hover .hover-theme {
  opacity: 1;
  -webkit-transform: translate(-10px, 10px);
  transform: translate(-10px, 10px);
}

/* imghvr-shift-bottom-right
   ----------------------------- */
.imghvr-shift-bottom-right:hover .hover-theme {
  opacity: 1;
  -webkit-transform: translate(10px, 10px);
  transform: translate(10px, 10px);
}

/* imghvr-bounce-*
   ----------------------------- */
[class^='imghvr-bounce'] .hover-theme, [class*=' imghvr-bounce'] .hover-theme {
  background-color: #135796;
  opacity: 1;
}

[class^='imghvr-bounce']:hover .hover-theme, [class*=' imghvr-bounce']:hover .hover-theme {
  -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
}

/* imghvr-bounce-in
   ----------------------------- */
.imghvr-bounce-in .hover-theme {
  opacity: 0;
}

.imghvr-bounce-in:hover .hover-theme {
  -webkit-animation: imghvr-bounce-in 0.6s ease-in forwards;
  animation: imghvr-bounce-in 0.6s ease-in forwards;
  -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  opacity: 1;
}

@-webkit-keyframes imghvr-bounce-in {
  0% {
    opacity: 0;
    -webkit-transform: scale3d(0.3, 0.3, 0.3);
    transform: scale3d(0.3, 0.3, 0.3);
  }
  20% {
    -webkit-transform: scale3d(1.1, 1.1, 1.1);
    transform: scale3d(1.1, 1.1, 1.1);
  }
  40% {
    -webkit-transform: scale3d(0.9, 0.9, 0.9);
    transform: scale3d(0.9, 0.9, 0.9);
  }
  60% {
    opacity: 1;
    -webkit-transform: scale3d(1.03, 1.03, 1.03);
    transform: scale3d(1.03, 1.03, 1.03);
  }
  80% {
    -webkit-transform: scale3d(0.97, 0.97, 0.97);
    transform: scale3d(0.97, 0.97, 0.97);
  }
  to {
    opacity: 1;
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }
}

@keyframes imghvr-bounce-in {
  0% {
    opacity: 0;
    -webkit-transform: scale3d(0.3, 0.3, 0.3);
    transform: scale3d(0.3, 0.3, 0.3);
  }
  20% {
    -webkit-transform: scale3d(1.1, 1.1, 1.1);
    transform: scale3d(1.1, 1.1, 1.1);
  }
  40% {
    -webkit-transform: scale3d(0.9, 0.9, 0.9);
    transform: scale3d(0.9, 0.9, 0.9);
  }
  60% {
    opacity: 1;
    -webkit-transform: scale3d(1.03, 1.03, 1.03);
    transform: scale3d(1.03, 1.03, 1.03);
  }
  80% {
    -webkit-transform: scale3d(0.97, 0.97, 0.97);
    transform: scale3d(0.97, 0.97, 0.97);
  }
  to {
    opacity: 1;
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }
}

/* imghvr-bounce-in-up
   ----------------------------- */
.imghvr-bounce-in-up .hover-theme {
  opacity: 1;
  -webkit-transform: translateY(100%);
  transform: translateY(100%);
}

.imghvr-bounce-in-up:hover .hover-theme {
  -webkit-animation: imghvr-bounce-in-up 0.8s ease-in forwards;
  animation: imghvr-bounce-in-up 0.8s ease-in forwards;
}

@-webkit-keyframes imghvr-bounce-in-up
{
  25%, 50%, 80%, 100% {
    -webkit-transform: translateY(0);
  }
  40% {
    -webkit-transform: translateY(25px);
  }
  65% {
    -webkit-transform: translateY(10px);
  }
  90% {
    -webkit-transform: translateY(2px);
  }
}

@keyframes imghvr-bounce-in-up
{
  25%, 50%, 80%, 100% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(25px);
  }
  65% {
    transform: translateY(10px);
  }
  90% {
    transform: translateY(2px);
  }
}

/* imghvr-bounce-in-down
   ----------------------------- */
.imghvr-bounce-in-down .hover-theme {
  opacity: 1;
  -webkit-transform: translateY(-100%);
  transform: translateY(-100%);
}

.imghvr-bounce-in-down:hover .hover-theme {
  -webkit-animation: imghvr-bounce-in-down 0.8s ease-in forwards;
  animation: imghvr-bounce-in-down 0.8s ease-in forwards;
}

@-webkit-keyframes imghvr-bounce-in-down
{
  25%, 50%, 80%, 100% {
    -webkit-transform: translateY(0);
  }
  40% {
    -webkit-transform: translateY(-25px);
  }
  65% {
    -webkit-transform: translateY(-10px);
  }
  90% {
    -webkit-transform: translateY(-2px);
  }
}

@keyframes imghvr-bounce-in-down
{
  25%, 50%, 80%, 100% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(-25px);
  }
  65% {
    transform: translateY(-10px);
  }
  90% {
    transform: translateY(-2px);
  }
}

/* imghvr-bounce-in-left
   ----------------------------- */
.imghvr-bounce-in-left .hover-theme {
  opacity: 1;
  -webkit-transform: translateX(100%);
  transform: translateX(100%);
}

.imghvr-bounce-in-left:hover .hover-theme {
  -webkit-animation: imghvr-bounce-in-left 0.8s ease-in forwards;
  animation: imghvr-bounce-in-left 0.8s ease-in forwards;
}

@-webkit-keyframes imghvr-bounce-in-left
{
  25%, 50%, 80%, 100% {
    -webkit-transform: translateX(0);
  }
  40% {
    -webkit-transform: translateX(25px);
  }
  65% {
    -webkit-transform: translateX(10px);
  }
  90% {
    -webkit-transform: translateX(2px);
  }
}

@keyframes imghvr-bounce-in-left
{
  25%, 50%, 80%, 100% {
    transform: translateX(0);
  }
  40% {
    transform: translateX(25px);
  }
  65% {
    transform: translateX(10px);
  }
  90% {
    transform: translateX(2px);
  }
}

/* imghvr-bounce-in-right
   ----------------------------- */
.imghvr-bounce-in-right .hover-theme {
  opacity: 1;
  -webkit-transform: translateX(-100%);
  transform: translateX(-100%);
}

.imghvr-bounce-in-right:hover .hover-theme {
  -webkit-animation: imghvr-bounce-in-right 0.8s ease-in forwards;
  animation: imghvr-bounce-in-right 0.8s ease-in forwards;
}

@-webkit-keyframes imghvr-bounce-in-right
{
  25%, 50%, 80%, 100% {
    -webkit-transform: translateX(0);
  }
  40% {
    -webkit-transform: translateX(-25px);
  }
  65% {
    -webkit-transform: translateX(-10px);
  }
  90% {
    -webkit-transform: translateX(-2px);
  }
}

@keyframes imghvr-bounce-in-right
{
  25%, 50%, 80%, 100% {
    transform: translateX(0);
  }
  40% {
    transform: translateX(-25px);
  }
  65% {
    transform: translateX(-10px);
  }
  90% {
    transform: translateX(-2px);
  }
}

/* imghvr-bounce-out
   ----------------------------- */
.imghvr-bounce-out .hover-theme {
  opacity: 0;
}

.imghvr-bounce-out:hover > img {
  -webkit-animation: imghvr-bounce-out 0.4025s ease-in forwards;
  animation: imghvr-bounce-out 0.4025s ease-in forwards;
}

.imghvr-bounce-out:hover .hover-theme {
  opacity: 1;
  -webkit-transition-delay: 0.35s;
  transition-delay: 0.35s;
}

@-webkit-keyframes imghvr-bounce-out {
  20% {
    -webkit-transform: scale3d(0.9, 0.9, 0.9);
    transform: scale3d(0.9, 0.9, 0.9);
  }
  50%, 55% {
    opacity: 1;
    -webkit-transform: scale3d(1.1, 1.1, 1.1);
    transform: scale3d(1.1, 1.1, 1.1);
  }
  to {
    opacity: 0;
    -webkit-transform: scale3d(0.3, 0.3, 0.3);
    transform: scale3d(0.3, 0.3, 0.3);
  }
}

@keyframes imghvr-bounce-out {
  20% {
    -webkit-transform: scale3d(0.9, 0.9, 0.9);
    transform: scale3d(0.9, 0.9, 0.9);
  }
  50%, 55% {
    opacity: 1;
    -webkit-transform: scale3d(1.1, 1.1, 1.1);
    transform: scale3d(1.1, 1.1, 1.1);
  }
  to {
    opacity: 0;
    -webkit-transform: scale3d(0.3, 0.3, 0.3);
    transform: scale3d(0.3, 0.3, 0.3);
  }
}

/* imghvr-bounce-out-up
   ----------------------------- */
.imghvr-bounce-out-up .hover-theme {
  opacity: 0;
}

.imghvr-bounce-out-up:hover > img {
  -webkit-animation: imghvr-bounce-out-up 0.4025s ease-in forwards;
  animation: imghvr-bounce-out-up 0.4025s ease-in forwards;
}

.imghvr-bounce-out-up:hover .hover-theme {
  opacity: 1;
  -webkit-transition-delay: 0.35s;
  transition-delay: 0.35s;
}

@-webkit-keyframes imghvr-bounce-out-up {
  20% {
    -webkit-transform: translate3d(0, -10px, 0);
    transform: translate3d(0, -10px, 0);
  }
  40%, 45% {
    opacity: 1;
    -webkit-transform: translate3d(0, 20px, 0);
    transform: translate3d(0, 20px, 0);
  }
  to {
    opacity: 0;
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0);
  }
}

@keyframes imghvr-bounce-out-up {
  20% {
    -webkit-transform: translate3d(0, -10px, 0);
    transform: translate3d(0, -10px, 0);
  }
  40%, 45% {
    opacity: 1;
    -webkit-transform: translate3d(0, 20px, 0);
    transform: translate3d(0, 20px, 0);
  }
  to {
    opacity: 0;
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0);
  }
}

/* imghvr-bounce-out-down
   ----------------------------- */
.imghvr-bounce-out-down .hover-theme {
  opacity: 0;
}

.imghvr-bounce-out-down:hover > img {
  -webkit-animation: imghvr-bounce-out-down 0.4025s ease-in forwards;
  animation: imghvr-bounce-out-down 0.4025s ease-in forwards;
}

.imghvr-bounce-out-down:hover .hover-theme {
  opacity: 1;
  -webkit-transition-delay: 0.35s;
  transition-delay: 0.35s;
}

@-webkit-keyframes imghvr-bounce-out-down {
  20% {
    -webkit-transform: translate3d(0, 10px, 0);
    transform: translate3d(0, 10px, 0);
  }
  40%, 45% {
    opacity: 1;
    -webkit-transform: translate3d(0, -20px, 0);
    transform: translate3d(0, -20px, 0);
  }
  to {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0);
  }
}

@keyframes imghvr-bounce-out-down {
  20% {
    -webkit-transform: translate3d(0, 10px, 0);
    transform: translate3d(0, 10px, 0);
  }
  40%, 45% {
    opacity: 1;
    -webkit-transform: translate3d(0, -20px, 0);
    transform: translate3d(0, -20px, 0);
  }
  to {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0);
  }
}

/* imghvr-bounce-out-left
   ----------------------------- */
.imghvr-bounce-out-left .hover-theme {
  opacity: 0;
}

.imghvr-bounce-out-left:hover > img {
  -webkit-animation: imghvr-bounce-out-left 0.4025s ease-in forwards;
  animation: imghvr-bounce-out-left 0.4025s ease-in forwards;
}

.imghvr-bounce-out-left:hover .hover-theme {
  opacity: 1;
  -webkit-transition-delay: 0.35s;
  transition-delay: 0.35s;
}

@-webkit-keyframes imghvr-bounce-out-left {
  20% {
    opacity: 1;
    -webkit-transform: translate3d(15%, 0, 0);
    transform: translate3d(15%, 0, 0);
  }
  to {
    opacity: 0;
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0);
  }
}

@keyframes imghvr-bounce-out-left {
  20% {
    opacity: 1;
    -webkit-transform: translate3d(15%, 0, 0);
    transform: translate3d(15%, 0, 0);
  }
  to {
    opacity: 0;
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0);
  }
}

/* imghvr-bounce-out-right
   ----------------------------- */
.imghvr-bounce-out-right .hover-theme {
  opacity: 0;
}

.imghvr-bounce-out-right:hover > img {
  -webkit-animation: imghvr-bounce-out-right 0.4025s ease-in forwards;
  animation: imghvr-bounce-out-right 0.4025s ease-in forwards;
}

.imghvr-bounce-out-right:hover .hover-theme {
  opacity: 1;
  -webkit-transition-delay: 0.35s;
  transition-delay: 0.35s;
}

@-webkit-keyframes imghvr-bounce-out-right {
  20% {
    opacity: 1;
    -webkit-transform: translate3d(-15%, 0, 0);
    transform: translate3d(-15%, 0, 0);
  }
  to {
    opacity: 0;
    -webkit-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0);
  }
}

@keyframes imghvr-bounce-out-right {
  20% {
    opacity: 1;
    -webkit-transform: translate3d(-15%, 0, 0);
    transform: translate3d(-15%, 0, 0);
  }
  to {
    opacity: 0;
    -webkit-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0);
  }
}

/* imghvr-fall-away-*
   ----------------------------- */
*[class^='imghvr-fall-away-'], *[class*=' imghvr-fall-away-'] {
  -webkit-perspective: 50em;
  perspective: 50em;
}

*[class^='imghvr-fall-away-'] img, *[class*=' imghvr-fall-away-'] img {
  -webkit-transform-origin: 50% 50%;
  transform-origin: 50% 50%;
  -webkit-transition: all 0.455s ease-in;
  transition: all 0.455s ease-in;
}

*[class^='imghvr-fall-away-'] .hover-theme, *[class*=' imghvr-fall-away-'] .hover-theme {
  opacity: 0;
  background-color: #135796;
}

*[class^='imghvr-fall-away-']:hover img, *[class*=' imghvr-fall-away-']:hover img {
  opacity: 0;
}

*[class^='imghvr-fall-away-']:hover .hover-theme, *[class*=' imghvr-fall-away-']:hover .hover-theme {
  opacity: 1;
  -webkit-transition-delay: 0.455s;
  transition-delay: 0.455s;
}

/* imghvr-fall-away-horiz
   ----------------------------- */
.imghvr-fall-away-horiz:hover img {
  -webkit-transform: rotateX(-360deg) scale(0.2);
  transform: rotateX(-360deg) scale(0.2);
}

/* imghvr-fall-away-vert
   ----------------------------- */
.imghvr-fall-away-vert:hover img {
  -webkit-transform: rotateY(-360deg) scale(0.2);
  transform: rotateY(-360deg) scale(0.2);
}

/* imghvr-fall-away-cc
   ----------------------------- */
.imghvr-fall-away-cc:hover img {
  -webkit-transform: rotate(360deg) scale(0.2);
  transform: rotate(360deg) scale(0.2);
}

/* imghvr-fall-away-ccc
   ----------------------------- */
.imghvr-fall-away-ccc:hover img {
  -webkit-transform: rotate(-360deg) scale(0.2);
  transform: rotate(-360deg) scale(0.2);
}

/* imghvr-modal-slide-*
   ----------------------------- */
[class^='imghvr-modal'] .hover-theme, [class*=' imghvr-modal'] .hover-theme {
  opacity: 1;
}

[class^='imghvr-modal']:hover > img, [class*=' imghvr-modal']:hover > img {
  opacity: 0.7;
}

[class^='imghvr-modal']:hover .hover-theme, [class*=' imghvr-modal']:hover .hover-theme {
  -webkit-transform: translate(0, 0);
  transform: translate(0, 0);
}

/* imghvr-modal-slide-up
   ----------------------------- */
.imghvr-modal-slide-up .hover-theme {
  top: auto;
  padding-top: 15px;
  padding-bottom: 15px;
  -webkit-transform: translate(0, 100%);
  transform: translate(0, 100%);
}

/* imghvr-modal-slide-down
   ----------------------------- */
.imghvr-modal-slide-down .hover-theme {
  bottom: auto;
  padding-top: 15px;
  padding-bottom: 15px;
  -webkit-transform: translate(0, -100%);
  transform: translate(0, -100%);
}

/* imghvr-modal-slide-left
   ----------------------------- */
.imghvr-modal-slide-left .hover-theme {
  left: auto;
  max-width: 70%;
  padding-left: 15px;
  padding-right: 15px;
  -webkit-transform: translate(100%, 0%);
  transform: translate(100%, 0%);
}

/* imghvr-modal-slide-right
   ----------------------------- */
.imghvr-modal-slide-right .hover-theme {
  right: auto;
  max-width: 70%;
  padding-left: 15px;
  padding-right: 15px;
  -webkit-transform: translate(-100%, 0%);
  transform: translate(-100%, 0%);
}

/* imghvr-modal-hinge-*
   ----------------------------- */
[class^='imghvr-modal'], [class*=' imghvr-modal'] {
  -webkit-perspective: 50em;
  perspective: 50em;
}

[class^='imghvr-modal'] .hover-theme, [class*=' imghvr-modal'] .hover-theme {
  opacity: 1;
}

[class^='imghvr-modal']:hover > img, [class*=' imghvr-modal']:hover > img {
  opacity: 0.7;
}

[class^='imghvr-modal']:hover .hover-theme, [class*=' imghvr-modal']:hover .hover-theme {
  -webkit-transform: rotate(0);
  transform: rotate(0);
}

/* imghvr-modal-hinge-up
   ----------------------------- */
.imghvr-modal-hinge-up .hover-theme {
  top: auto;
  padding-top: 15px;
  padding-bottom: 15px;
  -webkit-transform: rotateX(100deg);
  transform: rotateX(100deg);
  -webkit-transform-origin: 50% 100%;
  transform-origin: 50% 100%;
}

/* imghvr-modal-hinge-down
   ----------------------------- */
.imghvr-modal-hinge-down .hover-theme {
  bottom: auto;
  padding-top: 15px;
  padding-bottom: 15px;
  -webkit-transform: rotateX(-100deg);
  transform: rotateX(-100deg);
  -webkit-transform-origin: 50% -50%;
  transform-origin: 50% -50%;
}

/* imghvr-modal-hinge-left
   ----------------------------- */
.imghvr-modal-hinge-left .hover-theme {
  left: auto;
  max-width: 70%;
  padding-left: 15px;
  padding-right: 15px;
  -webkit-transform: rotateY(-100deg);
  transform: rotateY(-100deg);
  -webkit-transform-origin: 100% 50%;
  transform-origin: 100% 50%;
}

/* imghvr-modal-hinge-right
   ----------------------------- */
.imghvr-modal-hinge-right .hover-theme {
  right: auto;
  max-width: 70%;
  padding-left: 15px;
  padding-right: 15px;
  -webkit-transform: rotateY(100deg);
  transform: rotateY(100deg);
  -webkit-transform-origin: 0 50%;
  transform-origin: 0 50%;
}

/* imghvr-lightspeed-in-left
   ----------------------------- */
.imghvr-lightspeed-in-left .hover-theme {
  -webkit-transform: translate(100%, 0);
  transform: translate(100%, 0);
  -webkit-transform-origin: 50% 100%;
  transform-origin: 50% 100%;
  opacity: 0;
}

.imghvr-lightspeed-in-left:hover .hover-theme {
  -webkit-animation: imghvr-lightspeed-in-left 0.4025s ease-in forwards;
  animation: imghvr-lightspeed-in-left 0.4025s ease-in forwards;
  opacity: 1;
}

@-webkit-keyframes imghvr-lightspeed-in-left {
  from {
    -webkit-transform: translate(100%, 0) skewX(-20deg);
    transform: translate(100%, 0) skewX(-20deg);
  }
  50% {
    -webkit-transform: translate(0, 0) skewX(-20deg);
    transform: translate(0, 0) skewX(-20deg);
  }
  75% {
    -webkit-transform: translate(0, 0) skewX(10deg);
    transform: translate(0, 0) skewX(10deg);
  }
  to {
    -webkit-transform: translate(0, 0) skewX(0deg);
    transform: translate(0, 0) skewX(0deg);
  }
}

@keyframes imghvr-lightspeed-in-left {
  from {
    -webkit-transform: translate(100%, 0) skewX(-20deg);
    transform: translate(100%, 0) skewX(-20deg);
  }
  50% {
    -webkit-transform: translate(0, 0) skewX(-20deg);
    transform: translate(0, 0) skewX(-20deg);
  }
  75% {
    -webkit-transform: translate(0, 0) skewX(10deg);
    transform: translate(0, 0) skewX(10deg);
  }
  to {
    -webkit-transform: translate(0, 0) skewX(0deg);
    transform: translate(0, 0) skewX(0deg);
  }
}

/* imghvr-lightspeed-in-right
   ----------------------------- */
.imghvr-lightspeed-in-right .hover-theme {
  -webkit-transform: translate(-100%, 0);
  transform: translate(-100%, 0);
  -webkit-transform-origin: 50% 100%;
  transform-origin: 50% 100%;
  opacity: 0;
}

.imghvr-lightspeed-in-right:hover .hover-theme {
  -webkit-animation: imghvr-lightspeed-in-right 0.4025s ease-in forwards;
  animation: imghvr-lightspeed-in-right 0.4025s ease-in forwards;
  opacity: 1;
}

@-webkit-keyframes imghvr-lightspeed-in-right {
  from {
    -webkit-transform: translate(-100%, 0) skewX(20deg);
    transform: translate(-100%, 0) skewX(20deg);
  }
  50% {
    -webkit-transform: translate(0, 0) skewX(20deg);
    transform: translate(0, 0) skewX(20deg);
  }
  75% {
    -webkit-transform: translate(0, 0) skewX(-10deg);
    transform: translate(0, 0) skewX(-10deg);
  }
  to {
    -webkit-transform: translate(0, 0) skewX(0deg);
    transform: translate(0, 0) skewX(0deg);
  }
}

@keyframes imghvr-lightspeed-in-right {
  from {
    -webkit-transform: translate(-100%, 0) skewX(20deg);
    transform: translate(-100%, 0) skewX(20deg);
  }
  50% {
    -webkit-transform: translate(0, 0) skewX(20deg);
    transform: translate(0, 0) skewX(20deg);
  }
  75% {
    -webkit-transform: translate(0, 0) skewX(-10deg);
    transform: translate(0, 0) skewX(-10deg);
  }
  to {
    -webkit-transform: translate(0, 0) skewX(0deg);
    transform: translate(0, 0) skewX(0deg);
  }
}

/* imghvr-lightspeed-out-left
   ----------------------------- */
.imghvr-lightspeed-out-left .hover-theme {
  z-index: -1;
  opacity: 1;
}

.imghvr-lightspeed-out-left:hover > img {
  -webkit-animation: imghvr-lightspeed-out-left 0.4025s ease-in forwards;
  animation: imghvr-lightspeed-out-left 0.4025s ease-in forwards;
  -webkit-transform-origin: 50% 100%;
  transform-origin: 50% 100%;
  opacity: 1;
}

@-webkit-keyframes imghvr-lightspeed-out-left {
  from {
    -webkit-transform: translate(0, 0) skewX(0deg);
    transform: translate(0, 0) skewX(0deg);
  }
  25% {
    -webkit-transform: translate(10%, 0) skewX(0deg);
    transform: translate(10%, 0) skewX(0deg);
  }
  50% {
    -webkit-transform: translate(0, 0) skewX(-20deg);
    transform: translate(0, 0) skewX(-20deg);
  }
  to {
    -webkit-transform: translate(-150%, 0) skewX(-20deg);
    transform: translate(-150%, 0) skewX(-20deg);
  }
}

@keyframes imghvr-lightspeed-out-left {
  from {
    -webkit-transform: translate(0, 0) skewX(0deg);
    transform: translate(0, 0) skewX(0deg);
  }
  25% {
    -webkit-transform: translate(10%, 0) skewX(0deg);
    transform: translate(10%, 0) skewX(0deg);
  }
  50% {
    -webkit-transform: translate(0, 0) skewX(-20deg);
    transform: translate(0, 0) skewX(-20deg);
  }
  to {
    -webkit-transform: translate(-150%, 0) skewX(-20deg);
    transform: translate(-150%, 0) skewX(-20deg);
  }
}

/* imghvr-lightspeed-out-right
   ----------------------------- */
.imghvr-lightspeed-out-right .hover-theme {
  z-index: -1;
  opacity: 1;
}

.imghvr-lightspeed-out-right:hover > img {
  -webkit-animation: imghvr-lightspeed-out-right 0.4025s ease-in forwards;
  animation: imghvr-lightspeed-out-right 0.4025s ease-in forwards;
  -webkit-transform-origin: 50% 100%;
  transform-origin: 50% 100%;
  opacity: 1;
}

@-webkit-keyframes imghvr-lightspeed-out-right {
  from {
    -webkit-transform: translate(0, 0) skewX(0deg);
    transform: translate(0, 0) skewX(0deg);
  }
  25% {
    -webkit-transform: translate(10%, 0) skewX(0deg);
    transform: translate(10%, 0) skewX(0deg);
  }
  50% {
    -webkit-transform: translate(0, 0) skewX(-20deg);
    transform: translate(0, 0) skewX(-20deg);
  }
  to {
    -webkit-transform: translate(-150%, 0) skewX(-20deg);
    transform: translate(-150%, 0) skewX(-20deg);
  }
}

@keyframes imghvr-lightspeed-out-right {
  from {
    -webkit-transform: translate(0, 0) skewX(0deg);
    transform: translate(0, 0) skewX(0deg);
  }
  25% {
    -webkit-transform: translate(-10%, 0) skewX(0deg);
    transform: translate(-10%, 0) skewX(0deg);
  }
  50% {
    -webkit-transform: translate(0, 0) skewX(20deg);
    transform: translate(0, 0) skewX(20deg);
  }
  to {
    -webkit-transform: translate(150%, 0) skewX(20deg);
    transform: translate(150%, 0) skewX(20deg);
  }
}

/* imghvr-grad-*
   ----------------------------- */
[class^='imghvr-grad-']:before, [class*=' imghvr-grad-']:before {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  content: '';
}

[class^='imghvr-grad-'] .hover-theme, [class*=' imghvr-grad-'] .hover-theme {
  z-index: 1;
  opacity: 0;
  background-color: transparent;
}

[class^='imghvr-grad-']:hover:before, [class*=' imghvr-grad-']:hover:before {
  -webkit-transform: translate(0, 0);
  transform: translate(0, 0);
}

[class^='imghvr-grad-']:hover .hover-theme, [class*=' imghvr-grad-']:hover .hover-theme {
  opacity: 1;
  -webkit-transition-delay: 0.21s;
  transition-delay: 0.21s;
}

/* imghvr-grad-radial-in
   ----------------------------- */
.imghvr-grad-radial-in:before {
  background-image: -webkit-radial-gradient(transparent 0%, #135796 100%);
  background-image: radial-gradient(transparent 0%, #135796 100%);
  -webkit-transform: scale(2);
  transform: scale(2);
  opacity: 0;
}

.imghvr-grad-radial-in:after {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  content: '';
  opacity: 0;
  background-color: #135796;
}

.imghvr-grad-radial-in:hover:before {
  opacity: 1;
  -webkit-transform: scale(1);
  transform: scale(1);
}

.imghvr-grad-radial-in:hover:after {
  opacity: 0.75;
  -webkit-transition-delay: 0.105s;
  transition-delay: 0.105s;
}

/* imghvr-grad-radial-out
   ----------------------------- */
.imghvr-grad-radial-out:before {
  background-image: -webkit-radial-gradient(#135796 0%, transparent 100%);
  background-image: radial-gradient(#135796 0%, transparent 100%);
  position: absolute;
  top: -75%;
  bottom: -75%;
  left: -75%;
  right: -75%;
  -webkit-transform: scale(0);
  transform: scale(0);
  opacity: 0;
}

.imghvr-grad-radial-out:hover:before {
  opacity: 1;
  -webkit-transform: scale(1);
  transform: scale(1);
}

/* imghvr-grad-up
   ----------------------------- */
.imghvr-grad-up:before {
  -webkit-transform: translateY(100%);
  transform: translateY(100%);
  background-image: -webkit-linear-gradient( top , transparent 0%, #135796 100%);
  background-image: linear-gradient(to bottom, transparent 0%, #135796 100%);
  top: -75%;
}

/* imghvr-grad-down
   ----------------------------- */
.imghvr-grad-down:before {
  -webkit-transform: translateY(-100%);
  transform: translateY(-100%);
  background-image: -webkit-linear-gradient( bottom , transparent 0%, #135796 100%);
  background-image: linear-gradient(to top, transparent 0%, #135796 100%);
  bottom: -75%;
}

/* imghvr-grad-left
   ----------------------------- */
.imghvr-grad-left:before {
  -webkit-transform: translateX(100%);
  transform: translateX(100%);
  background-image: -webkit-linear-gradient( left , transparent 0%, #135796 100%);
  background-image: linear-gradient(to right, transparent 0%, #135796 100%);
  left: -75%;
}

/* imghvr-grad-right
   ----------------------------- */
.imghvr-grad-right:before {
  -webkit-transform: translateX(-100%);
  transform: translateX(-100%);
  background-image: -webkit-linear-gradient( right , transparent 0%, #135796 100%);
  background-image: linear-gradient(to left, transparent 0%, #135796 100%);
  right: -75%;
}

/* imghvr-grad-top-left
   ----------------------------- */
.imghvr-grad-top-left:before {
  background-image: -webkit-linear-gradient(-45deg, transparent 0%, #135796 100%);
  background-image: linear-gradient(-45deg, transparent 0%, #135796 100%);
  -webkit-transform: scale(2);
  transform: scale(2);
  opacity: 0;
  bottom: -75%;
  right: -75%;
}

.imghvr-grad-top-left:hover:before {
  opacity: 1;
  -webkit-transform: scale(1);
  transform: scale(1);
}

/* imghvr-grad-top-right
   ----------------------------- */
.imghvr-grad-top-right:before {
  background-image: -webkit-linear-gradient(-315deg, transparent 0%, #135796 100%);
  background-image: linear-gradient(45deg, transparent 0%, #135796 100%);
  -webkit-transform: scale(2);
  transform: scale(2);
  opacity: 0;
  bottom: -75%;
  left: -75%;
}

.imghvr-grad-top-right:hover:before {
  opacity: 1;
  -webkit-transform: scale(1);
  transform: scale(1);
}

/* imghvr-grad-bottom-left
   ----------------------------- */
.imghvr-grad-bottom-left:before {
  background-image: -webkit-linear-gradient(-135deg, transparent 0%, #135796 100%);
  background-image: linear-gradient(-135deg, transparent 0%, #135796 100%);
  -webkit-transform: scale(2);
  transform: scale(2);
  opacity: 0;
  top: -75%;
  right: -75%;
}

.imghvr-grad-bottom-left:hover:before {
  opacity: 1;
  -webkit-transform: scale(1);
  transform: scale(1);
}

/* imghvr-grad-bottom-right
   ----------------------------- */
.imghvr-grad-bottom-right:before {
  background-image: -webkit-linear-gradient(-405deg, transparent 0%, #135796 100%);
  background-image: linear-gradient(135deg, transparent 0%, #135796 100%);
  -webkit-transform: scale(2);
  transform: scale(2);
  opacity: 0;
  top: -75%;
  left: -75%;
}

.imghvr-grad-bottom-right:hover:before {
  opacity: 1;
  -webkit-transform: scale(1);
  transform: scale(1);
}

/* imghvr-parallax-*
   ----------------------------- */
[class^='imghvr-parallax-']:hover .hover-theme, [class*=' imghvr-parallax-']:hover .hover-theme {
  -webkit-transform: translate(0, 0);
  transform: translate(0, 0);
}

/* imghvr-parallax-up
   ----------------------------- */
.imghvr-parallax-up .hover-theme {
  -webkit-transform: translateY(100%);
  transform: translateY(100%);
}

.imghvr-parallax-up:hover > img {
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
}

/* imghvr-parallax-down
   ----------------------------- */
.imghvr-parallax-down .hover-theme {
  -webkit-transform: translateY(-100%);
  transform: translateY(-100%);
}

.imghvr-parallax-down:hover > img {
  -webkit-transform: translateY(50%);
  transform: translateY(50%);
}

/* imghvr-parallax-left
   ----------------------------- */
.imghvr-parallax-left .hover-theme {
  -webkit-transform: translateX(100%);
  transform: translateX(100%);
}

.imghvr-parallax-left:hover > img {
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
}

/* imghvr-parallax--right
   ----------------------------- */
.imghvr-parallax-right .hover-theme {
  -webkit-transform: translateX(-100%);
  transform: translateX(-100%);
}

.imghvr-parallax-right:hover > img {
  -webkit-transform: translateX(50%);
  transform: translateX(50%);
}

/* imghvr-stack-*
   ----------------------------- */
[class^='imghvr-stack-'] > img, [class^='imghvr-stack-'] .hover-theme, [class*=' imghvr-stack-'] > img, [class*=' imghvr-stack-'] .hover-theme {
  -webkit-transition-timing-function: cubic-bezier(0.645, 0.045, 0.355, 1);
  transition-timing-function: cubic-bezier(0.645, 0.045, 0.355, 1);
}

[class^='imghvr-stack-']:hover > img, [class*=' imghvr-stack-']:hover > img {
  -webkit-transform: scale(0.75);
  transform: scale(0.75);
  opacity: 0.5;
}

[class^='imghvr-stack-']:hover .hover-theme, [class*=' imghvr-stack-']:hover .hover-theme {
  -webkit-transform: translate(0, 0);
  transform: translate(0, 0);
}

/* imghvr-stack-up
   ----------------------------- */
.imghvr-stack-up .hover-theme {
  -webkit-transform: translateY(100%);
  transform: translateY(100%);
}

/* imghvr-stack-down
   ----------------------------- */
.imghvr-stack-down .hover-theme {
  -webkit-transform: translateY(-100%);
  transform: translateY(-100%);
}

/* imghvr-stack-left
   ----------------------------- */
.imghvr-stack-left .hover-theme {
  -webkit-transform: translateX(100%);
  transform: translateX(100%);
}

/* imghvr-stack-right
   ----------------------------- */
.imghvr-stack-right .hover-theme {
  -webkit-transform: translateX(-100%);
  transform: translateX(-100%);
}

/* imghvr-stack-top-left
   ----------------------------- */
.imghvr-stack-top-left .hover-theme {
  -webkit-transform: translate(-100%, -100%);
  transform: translate(-100%, -100%);
}

/* imghvr-stack-top-right
   ----------------------------- */
.imghvr-stack-top-right .hover-theme {
  -webkit-transform: translate(100%, -100%);
  transform: translate(100%, -100%);
}

/* imghvr-stack-bottom-left
   ----------------------------- */
.imghvr-stack-bottom-left .hover-theme {
  -webkit-transform: translate(-100%, 100%);
  transform: translate(-100%, 100%);
}

/* imghvr-stack-bottom-right
   ----------------------------- */
.imghvr-stack-bottom-right .hover-theme {
  -webkit-transform: translate(100%, 100%);
  transform: translate(100%, 100%);
}

/* imghvr-cube-*
   ----------------------------- */
[class^='imghvr-cube-'], [class*=' imghvr-cube-'] {
  -webkit-perspective: 50em;
  perspective: 50em;
  -webkit-transform-style: preserve-3d;
  transform-style: preserve-3d;
  overflow: visible;
  background-color: transparent;
}

[class^='imghvr-cube-'] > img, [class*=' imghvr-cube-'] > img {
  -webkit-transition-delay: 0.05s;
  transition-delay: 0.05s;
}

[class^='imghvr-cube-'] .hover-theme, [class*=' imghvr-cube-'] .hover-theme {
  opacity: 0;
  -webkit-transition-delay: 0s;
  transition-delay: 0s;
  background-color: #135796;
}

[class^='imghvr-cube-']:hover > img, [class*=' imghvr-cube-']:hover > img {
  opacity: 0;
  -webkit-transition-delay: 0s;
  transition-delay: 0s;
}

[class^='imghvr-cube-']:hover .hover-theme, [class*=' imghvr-cube-']:hover .hover-theme {
  -webkit-transform: translateY(0%) rotateX(0deg);
  transform: translateY(0%) rotateX(0deg);
  opacity: 1;
  -webkit-transition-delay: 0.05s;
  transition-delay: 0.05s;
}

/* imghvr-cube-up
   ----------------------------- */
.imghvr-cube-up .hover-theme {
  -webkit-transform: translateY(50%) rotateX(-90deg);
  transform: translateY(50%) rotateX(-90deg);
}

.imghvr-cube-up:hover > img {
  -webkit-transform: translateY(-50%) rotateX(90deg);
  transform: translateY(-50%) rotateX(90deg);
}

/* imghvr-cube-down
   ----------------------------- */
.imghvr-cube-down .hover-theme {
  -webkit-transform: translateY(-50%) rotateX(90deg);
  transform: translateY(-50%) rotateX(90deg);
}

.imghvr-cube-down:hover > img {
  -webkit-transform: translateY(50%) rotateX(-90deg);
  transform: translateY(50%) rotateX(-90deg);
}

/* imghvr-cube-left
   ----------------------------- */
.imghvr-cube-left .hover-theme {
  -webkit-transform: translateX(-50%) rotateY(-90deg);
  transform: translateX(-50%) rotateY(-90deg);
}

.imghvr-cube-left:hover > img {
  -webkit-transform: translateX(50%) rotateY(90deg);
  transform: translateX(50%) rotateY(90deg);
}

/* imghvr-cube-right
   ----------------------------- */
.imghvr-cube-right .hover-theme {
  -webkit-transform: translateX(50%) rotateY(90deg);
  transform: translateX(50%) rotateY(90deg);
}

.imghvr-cube-right:hover > img {
  -webkit-transform: translateX(-50%) rotateY(-90deg);
  transform: translateX(-50%) rotateY(-90deg);
}

/* imghvr-dive-*
   ----------------------------- */
[class^='imghvr-dive'] .hover-theme, [class*=' imghvr-dive'] .hover-theme {
  opacity: 0;
  -webkit-transition-delay: 0s;
  transition-delay: 0s;
}

[class^='imghvr-dive']:hover > img, [class*=' imghvr-dive']:hover > img {
  opacity: 0;
  -webkit-transition-delay: 0s;
  transition-delay: 0s;
}

[class^='imghvr-dive']:hover .hover-theme, [class*=' imghvr-dive']:hover .hover-theme {
  opacity: 1;
  -webkit-transition-delay: 0.175s;
  transition-delay: 0.175s;
}

/* imghvr-dive
   ----------------------------- */
.imghvr-dive .hover-theme {
  -webkit-transform: scale(5);
  transform: scale(5);
}

.imghvr-dive:hover > img {
  -webkit-transform: scale(5);
  transform: scale(5);
}

.imghvr-dive:hover .hover-theme {
  -webkit-transform: scale(1);
  transform: scale(1);
}

/* imghvr-dive-cc
   ----------------------------- */
.imghvr-dive-cc .hover-theme {
  -webkit-transform: rotate(-45deg) scale(5);
  transform: rotate(-45deg) scale(5);
}

.imghvr-dive-cc:hover > img {
  -webkit-transform: rotate(45deg) scale(5);
  transform: rotate(45deg) scale(5);
}

.imghvr-dive-cc:hover .hover-theme {
  -webkit-transform: rotate(0deg) scale(1);
  transform: rotate(0deg) scale(1);
}

/* imghvr-dive-ccc
   ----------------------------- */
.imghvr-dive-ccc .hover-theme {
  -webkit-transform: rotate(45deg) scale(5);
  transform: rotate(45deg) scale(5);
}

.imghvr-dive-ccc:hover > img {
  -webkit-transform: rotate(-45deg) scale(5);
  transform: rotate(-45deg) scale(5);
}

.imghvr-dive-ccc:hover .hover-theme {
  -webkit-transform: rotate(0deg) scale(1);
  transform: rotate(0deg) scale(1);
}

/* imghvr-switch-*
   ----------------------------- */
[class^='imghvr-switch-'] > img, [class^='imghvr-switch-'] .hover-theme, [class*=' imghvr-switch-'] > img, [class*=' imghvr-switch-'] .hover-theme {
  -webkit-transition-timing-function: cubic-bezier(0.645, 0.045, 0.355, 1);
  transition-timing-function: cubic-bezier(0.645, 0.045, 0.355, 1);
}

[class^='imghvr-switch-']:hover .hover-theme, [class*=' imghvr-switch-']:hover .hover-theme {
  -webkit-transform: translate(0 0);
  transform: translate(0 0);
}

/* imghvr-switch-up
   ----------------------------- */
.imghvr-switch-up .hover-theme {
  -webkit-transform: translateY(100%);
  transform: translateY(100%);
}

.imghvr-switch-up:hover > img {
  -webkit-transform: translateY(100%);
  transform: translateY(100%);
}

/* imghvr-switch-down
   ----------------------------- */
.imghvr-switch-down .hover-theme {
  -webkit-transform: translateY(-100%);
  transform: translateY(-100%);
}

.imghvr-switch-down:hover > img {
  -webkit-transform: translateY(-100%);
  transform: translateY(-100%);
}

/* imghvr-switch-left
   ----------------------------- */
.imghvr-switch-left .hover-theme {
  -webkit-transform: translateX(100%);
  transform: translateX(100%);
}

.imghvr-switch-left:hover > img {
  -webkit-transform: translateX(100%);
  transform: translateX(100%);
}

/* imghvr-switch-right
   ----------------------------- */
.imghvr-switch-right .hover-theme {
  -webkit-transform: translateX(-100%);
  transform: translateX(-100%);
}

.imghvr-switch-right:hover > img {
  -webkit-transform: translateX(-100%);
  transform: translateX(-100%);
}

/* imghvr-flash-*
   ----------------------------- */
[class^='imghvr-flash-']:before, [class^='imghvr-flash-']:after, [class*=' imghvr-flash-']:before, [class*=' imghvr-flash-']:after {
  opacity: 0.75;
  background-color: #135796;
  position: absolute;
  top: -50%;
  bottom: -50%;
  left: -50%;
  right: -50%;
  content: '';
}

[class^='imghvr-flash-']:after, [class*=' imghvr-flash-']:after {
  -webkit-transition-delay: 0.175s;
  transition-delay: 0.175s;
}

[class^='imghvr-flash-'] .hover-theme, [class*=' imghvr-flash-'] .hover-theme {
  opacity: 0;
  z-index: 1;
}

[class^='imghvr-flash-']:hover .hover-theme, [class*=' imghvr-flash-']:hover .hover-theme {
  -webkit-transition-delay: 0.35s;
  transition-delay: 0.35s;
  opacity: 1;
}

/* imghvr-flash-top-left
   ----------------------------- */
.imghvr-flash-top-left:before, .imghvr-flash-top-left:after {
  -webkit-transform: rotate(-45deg) translateY(-100%);
  transform: rotate(-45deg) translateY(-100%);
}

.imghvr-flash-top-left:hover:before {
  -webkit-transform: rotate(-45deg) translateY(100%);
  transform: rotate(-45deg) translateY(100%);
}

.imghvr-flash-top-left:hover:after {
  -webkit-transform: rotate(-45deg) translateY(0%);
  transform: rotate(-45deg) translateY(0%);
}

/* imghvr-flash-top-right
   ----------------------------- */
.imghvr-flash-top-right:before, .imghvr-flash-top-right:after {
  -webkit-transform: rotate(45deg) translateY(-100%);
  transform: rotate(45deg) translateY(-100%);
}

.imghvr-flash-top-right:hover:before {
  -webkit-transform: rotate(45deg) translateY(100%);
  transform: rotate(45deg) translateY(100%);
}

.imghvr-flash-top-right:hover:after {
  -webkit-transform: rotate(45deg) translateY(0%);
  transform: rotate(45deg) translateY(0%);
}

/* imghvr-flash-bottom-left
   ----------------------------- */
.imghvr-flash-bottom-left:before, .imghvr-flash-bottom-left:after {
  -webkit-transform: rotate(45deg) translateY(100%);
  transform: rotate(45deg) translateY(100%);
}

.imghvr-flash-bottom-left:hover:before {
  -webkit-transform: rotate(45deg) translateY(-100%);
  transform: rotate(45deg) translateY(-100%);
}

.imghvr-flash-bottom-left:hover:after {
  -webkit-transform: rotate(45deg) translateY(0%);
  transform: rotate(45deg) translateY(0%);
}

/* imghvr-flash-bottom-right
   ----------------------------- */
.nfnfnddfd:before, .imghvr-flash-bottom-right:after {
  -webkit-transform: rotate(-45deg) translateY(100%);
  transform: rotate(-45deg) translateY(100%);
}

.imghvr-flash-bottom-right:hover:before {
  -webkit-transform: rotate(-45deg) translateY(-100%);
  transform: rotate(-45deg) translateY(-100%);
}

.imghvr-flash-bottom-right:hover:after {
  -webkit-transform: rotate(-45deg) translateY(0%);
  transform: rotate(-45deg) translateY(0%);
}

.imghvr-center .hover-theme {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-align: center;
}

[class^='imghvr-']:hover .ih-delay-xs, [class*=' imghvr-']:hover .ih-delay-xs {0
  -webkit-transition-delay: 0.175s;
  transition-delay: 0.175s;
}

[class^='imghvr-']:hover .ih-delay-sm, [class*=' imghvr-']:hover .ih-delay-sm {
  -webkit-transition-delay: 0.35s;
  transition-delay: 0.35s;
}

[class^='imghvr-']:hover .ih-delay-md, [class*=' imghvr-']:hover .ih-delay-md {
  -webkit-transition-delay: 0.525s;
  transition-delay: 0.525s;
}

[class^='imghvr-']:hover .ih-delay-lg, [class*=' imghvr-']:hover .ih-delay-lg {
  -webkit-transition-delay: 0.7s;
  transition-delay: 0.7s;
}

[class^='imghvr-']:hover .ih-delay-xl, [class*=' imghvr-']:hover .ih-delay-xl {
  -webkit-transition-delay: 0.875s;
  transition-delay: 0.875s;
}

[class^='imghvr-']:hover .ih-delay-xxl, [class*=' imghvr-']:hover .ih-delay-xxl {
  -webkit-transition-delay: 1.05s;
  transition-delay: 1.05s;
}

[class^='imghvr-'] [class^='ih-'], [class^='imghvr-'] [class*=' ih-'], [class*=' imghvr-'] [class^='ih-'], [class*=' imghvr-'] [class*=' ih-'] {
  opacity: 0;
}

[class^='imghvr-'] .ih-fade-up, [class*=' imghvr-'] .ih-fade-up {
  -webkit-transform: translate(0, 25px);
  transform: translate(0, 25px);
}

[class^='imghvr-'] .ih-fade-down, [class*=' imghvr-'] .ih-fade-down {
  -webkit-transform: translate(0, -25px);
  transform: translate(0, -25px);
}

[class^='imghvr-'] .ih-fade-left, [class*=' imghvr-'] .ih-fade-left {
  -webkit-transform: translate(25px, 0);
  transform: translate(25px, 0);
}

[class^='imghvr-'] .ih-fade-right, [class*=' imghvr-'] .ih-fade-right {
  -webkit-transform: translate(-25px, 0);
  transform: translate(-25px, 0);
}

[class^='imghvr-'] .ih-fade-up-big, [class*=' imghvr-'] .ih-fade-up-big {
  -webkit-transform: translate(0, 300%);
  transform: translate(0, 300%);
}

[class^='imghvr-'] .ih-fade-down-big, [class*=' imghvr-'] .ih-fade-down-big {
  -webkit-transform: translate(0, -300%);
  transform: translate(0, -300%);
}

[class^='imghvr-'] .ih-fade-left-big, [class*=' imghvr-'] .ih-fade-left-big {
  -webkit-transform: translate(150%, 0);
  transform: translate(150%, 0);
}

[class^='imghvr-'] .ih-fade-right-big, [class*=' imghvr-'] .ih-fade-right-big {
  -webkit-transform: translate(-150%, 0);
  transform: translate(-150%, 0);
}

[class^='imghvr-'] .ih-zoom-in, [class*=' imghvr-'] .ih-zoom-in {
  -webkit-transform: scale(0.5);
  transform: scale(0.5);
}

[class^='imghvr-'] .ih-zoom-out, [class*=' imghvr-'] .ih-zoom-out {
  -webkit-transform: scale(1.5);
  transform: scale(1.5);
}

[class^='imghvr-'] .ih-flip-x, [class*=' imghvr-'] .ih-flip-x {
  -webkit-transform: rotateX(-90deg);
  transform: rotateX(-90deg);
}

[class^='imghvr-'] .ih-flip-y, [class*=' imghvr-'] .ih-flip-y {
  -webkit-transform: rotateY(90deg);
  transform: rotateY(90deg);
}

[class^='imghvr-']:hover [class^='ih-'], [class^='imghvr-']:hover [class*=' ih-'], [class*=' imghvr-']:hover [class^='ih-'], [class*=' imghvr-']:hover [class*=' ih-'] {
  -webkit-transform: translate(0, 0) scale(1) rotate(0);
  transform: translate(0, 0) scale(1) rotate(0);
  opacity: 1;
}
