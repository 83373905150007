/*
	  ============================================================
		   Font Color
	  ============================================================
*/ 
.city_forget:hover,
.city_checkout_list li a:hover,
.dl-menuwrapper li a:hover,
.city_news2_post.post2:hover .city_news2_detail h4,
.navigation ul li ul li a:before,
.blog_post_slide_text h6:hover a,
.blog_next_post ul li a i,
.detail_process_list li a:hover,
.city_mayor_text p,
.city_mayor_text h2,
.forum_user_meta .city_meta_list li a:hover,
.forum_detail_deta > a:hover,
.forum_detail_deta .city_meta_list li a:hover,
.forum_quote h5,
.forum_detail_deta a i,
.forum_author_text h6:hover a, 
.forum_month a,
.city_news2_detail .city_meta_list li a:hover,
.city_news2_detail:hover h2,
.city_team_fig:hover .city_team_text h4 a,
.information_list li a:hover,
.city_project_description .city_local_link li a:before,
.city_blog2_fig.fig2:hover .city_meta_list li a:hover,
.city_blog2_fig.fig2 .city_blog2_text h4:hover a,
.city_blog2_fig.fig2:hover .city_meta_list li a i,
.city_blog2_fig:hover .see_more_btn,
.city_blog2_text h5:hover a,
.city_blog2_list .city_meta_list li a i,
.city_event_tags span a:hover,
.event_counter_list .countdown li:before,
.venue_list ul li p:hover a,
.city_blog_text.event2 .social_icon li a:hover,
.city_event2_list2_row:hover .share_icon,
.city_blog_text.event2 .city_meta_list li a:hover,
.city_blog_text.event2 h4:hover a,
.city_blog_text.event2 span,
.event2_menu_list a:hover,
.event_video .paly_btn:hover,
.event_categories_text a:hover,
.categories_list ul li a:hover,
.section_heading h3,
.city_senior_team:hover .city_senior_team_text h5, 
.city_senior_team_text a:hover,
.city_local_link li a:hover,
.city_health2_service:hover span,
.city_health2_service a:hover,
.city_health2_service:hover h5 a,
.side_notice_text h6:hover a,
.side_notice_detail > a,
.side_contact_text a:hover,
.city_event2_text h4:hover a,
.city_event2_text span,
.city_event2_text ul li a:hover,
.city_visit_text p i,
.city_visit_text h3 span,
.see_more_btn:hover,
.city_busine_detail li a:hover,
.city_busine_detail li a i,
.city_blog_grid_text a:hover,
.emergency_service_item h4:hover a,
.city_emergency_slide_text h5:hover a,
.city_emergency_call ul li a:hover,
.special_service_text .theam_btn,
.city_service2_list > span,
.breadcrumb > li a:hover,
.city_project_mansory ul li a.active,
.city_request_link ul li a:hover:before,
.city_request_link ul li a:hover,
.city_document_list ul li a:hover,
.city_news_meta li a:hover,
.city_event_fig a:hover,
.city_event_fig > a:hover,
.city_event_fig:hover .city_date_text a i,
.city_event_fig:hover .custom_size,
.city_jobs_item span,
.ciy_jobs_row span,
.city_blog_fig.position:hover .share_icon,
.city_office_text a,
.city_department_text a:hover,
.city_about_link li a:hover,
.section_heading h2,
.city_service_text h5 a:hover,
.banner_search_field a
{
	color:#df193a;
}



/*blue color*/
.paly_btn:hover,
.top_user:hover,
.blog_post_author_text .city_top_social li a,
.blog_detail_row .city_event_meta li a,
.forum_fig.forum2 .forum_text span,
.side_news_text:hover p,
.side_news_text:hover span,
.tabs li.active a, 
.tabs li.active a:hover,
.city_health_text h2,
.city_service2_fig:hover .city_service2_text a,
.widget_service ul li a:hover:before,
.widget_service ul li a:hover,
.city_request_text span,
.city_request_row > span,
.social_icon li a,
.city_about_text h6,
.banner_search_field a
{
	color:#14284b;
}
/*
	  ============================================================
		   Background Color
	  ============================================================
*/
/**/
.city_comment_form_login .theam_btn,
.side_news_deatil:hover .theam_btn,
.city_news2_post.post2:hover .theam_btn,
.navigation ul li ul li a:hover,
.blog_post_slide .slick-prev:hover, 
.blog_post_slide .slick-next:hover,
.blog_next_post ul li a:hover,
.city_blog2_fig.fig2.detail .city_blog2_met:after,
.city_blog2_fig.fig2.detail .city_blog2_met:before,
.city_blog2_fig.fig2.detail .city_blog2_met,
.city_contact_text:hover,
.city_event_detail.question .event_booking_area .theam_btn.btn2,
.city_mayor_contact .theam_btn,
.sidebar_search_login a,
.forum_author_posts,
.forum_title,
.city_news2_detail:hover .theam_btn,
.city_404_wrap.coming.overlay:before,
.city_404_text .theam_btn:hover,
.city_team_fig .city_top_social li a:hover,
.blog_tags a:hover,
.blog_author_date li:before,
.city_blog2_fig.fig2 .theam_btn:hover,
.city_blog2_fig.fig2 .theam_btn:hover,
.city_blog2_fig .paly_btn:hover,
.city_blog2_fig:hover .city_blog2_met,
.city_blog2_fig:hover .city_blog2_met:after,
.city_blog2_fig:hover .city_blog2_met:before,
.city_blog2_list:hover .city_meta_list,
.list li:hover,
.list li.option.selected.focus,
.event_booking_form .theam_btn:hover,
.event_counter_list .countdown li,
.city_event2_year .list li:hover,
.city_event2_year .list li.option.selected.focus,
.city_event2_list2_row:hover .theam_btn,
.city_event2_list2_row:hover .event_categories_date,
.city_full_event_list:hover .theam_btn.btn2,
.city_special2_dream,
.city_treatment_wrap,
.city_treatment_text .paly_btn,
.city_about_list.list2 .theam_btn:hover,
.accordion-section-title.active,
.city_health2_text .slick-prev:hover,
.city_health2_text .slick-next:hover,
.city_emergency_slider .slick-prev:hover,
.city_emergency_slider .slick-next:hover,
.city_department_wrap .slick-prev:hover,
.city_department_wrap .slick-next:hover,
.side_submit_form,
.city_event2_fig:hover .theam_btn.btn2, 
.city_event2_date,
.city_visit_text .theam_btn:hover,
.city_business_fig .city_service2_list span,
.city_service2_btn.btn2 .theam_btn,
.pagination ul li a:hover,
.city_news2_text .theam_btn,
.special_service_text .theam_btn:hover,
.widget_wrap.overlay:before,
.widget_wrap,
.city_news_feild,
.city_client_wrap,
.city_event_fig:hover .event_date,
.city_project_wrap .slick-prev:hover, 
.city_project_wrap .slick-next:hover,
.city_project_fig .overlay:before,
.city_jobs_item.overlay:before,
.city_blog_fig.position:hover .theam_btn,
.city_department_wrap .slick-prev:hover,
.city_department_wrap .slick-next:hover,
.city_department_wrap.overlay:before,
.city_department_fig:hover,
.city_about_video .overlay:before,
.city_top_social ul li a,
.city_main_banner .slick-prev:hover, 
.city_main_banner .slick-next:hover,
.banner_text,
.city_top_navigation,
.city_top_news span
{
	background-color:#df193a;
}




/*background blue color*/
.dl-menuwrapper .dl-menu,
.dl-menuwrapper button:hover, 
.dl-menuwrapper button.dl-active,
.dl-menuwrapper button,
/**/
.city_contact_text,
.city_coming_feild button,
.city_404_wrap.overlay:before,
.event_booking_form .theam_btn,
.event_video:hover .overlay:before,
.city_about_list.list2 .theam_btn,
.side_notice_list .sidebar_title,
.side_submit_field .theam_btn.btn2,
.city_health_service2:hover .city_health_list > .overlay:before,
.city_health_service2:hover .see_more_btn,
.city_visit_text .theam_btn,
.box:before,
.box:after,
.box .box-layer,
.city_service2_btn.btn2 .theam_btn:hover,
.city_blog_grid_text span,
.city_side_info,
.city_award_wrap,
.special_service_text.overlay:before,
.sab_banner.overlay:before,
[class^="imghvr-"] .hover-theme, 
[class*=" imghvr-"] .hover-theme,
.city_project_fig:hover .overlay:before,
[class^="imghvr-shutter-out-"]::before,
[class*=" imghvr-shutter-out-"]::before,
.city_news_feild.feild2,
.city_news_search .theam_btn,
.city_client_row .bx-wrapper .bx-prev:hover,
.city_client_row .bx-wrapper .bx-next:hover,
.slick-slide.slick-current.slick-active.slick-center .city_project_fig .overlay:before,
.city_jobs_item.pull-right.overlay:before,
.city_office_list,
.city_triagle span,
.city_department_fig .overlay:before,
.city_top_social ul li a:hover,
.banner_search_field a:hover,
.theam_btn:hover
{
	background-color:#14284b;
}
/*
	  ============================================================
		   Border Color
	  ============================================================
*/
.city_comment_form_login .theam_btn,
.city_login h4:before,
.side_news_deatil:hover .theam_btn,
.navigation ul > li > ul,
.blog_post_slide .slick-prev:hover, 
.blog_post_slide .slick-next:hover,
.forum_quote,
.forum_card,
.city_news2_detail:hover .theam_btn,
.city_news2_detail:hover,
.city_team_fig .city_top_social li a:hover,
.project_title,
.city_blog2_fig.fig2 .theam_btn:hover,
.city_blog2_fig:hover .see_more_btn,
.city_blog2_fig .paly_btn:hover,
.city_blog2_list:hover .city_meta_list,
.city_blog2_list:hover .city_blog2_text,
.event_booking_area textarea:focus,
.event_booking_field input:focus,
.event_heading,
.city_event2_year .sidebar_search input:focus,
.city_event2_year .list,
.city_event2_list2_row:hover .theam_btn,
.city_event2_list2_row:hover .city_event2_list2_fig figure,
.sidebar_search input:focus,
.sidebar_heading,
.city_about_list.list2 .theam_btn:hover,
.city_senior_team > figure,
.city_health2_text .slick-prev:hover,
.city_health2_text .slick-next:hover,
.city_emergency_slider .slick-prev:hover,
.city_emergency_slider .slick-next:hover,
.city_department_wrap .slick-prev:hover,
.city_department_wrap .slick-next:hover,
.city_health2_service:hover,
.city_visit_text .theam_btn:hover,
.city_business_fig figure:after,
.city_service2_btn.btn2 .theam_btn,
.city_health_fig:after,
.city_news2_text .theam_btn,
.special_service_text .theam_btn:hover,
.city_request_list,
.city_project_wrap .slick-prev:hover, 
.city_project_wrap .slick-next:hover,
.section_heading.center:before,
.city_blog_fig.position:hover .theam_btn,
.city_office_list,
.city_department_wrap .slick-prev:hover,
.city_department_wrap .slick-next:hover,
.city_department_fig:hover,
.section_heading,
.city_top_social ul li a,
.city_top_social ul li a:hover
{
	border-color:#df193a;
}



/*border blue color*/
.blog_post_author_text .city_top_social li a,
.blog_detail_row .city_event_meta li a,
.city_service2_btn.btn2 .theam_btn:hover,
.tabs li.active a, 
.tabs li.active a:hover,
.city_service2_fig:hover,
.city_top_social ul li a:hover,
.banner_search_field input:focus,
.theam_btn:hover{
	border-color:#14284b;
}
/*
	  ============================================================
		   Transition
	  ============================================================
*/
.city_forget,
.city_checkout_list li a,
.paly_btn,
.dl-menuwrapper li a,
.side_news_deatil .theam_btn,
.city_news2_post.post2 .city_news2_detail h4,
.top_user,
.navigation ul li ul li a:before,
.navigation ul li ul li a,
.navigation ul ul li > ul,
.navigation > ul > li > ul,
.blog_post_slide_text h6 a,
.blog_next_post ul li a i,
.blog_next_post ul li a,
.detail_process_list li a,
.city_contact_text,
.sidebar_search_login a,
.city_meta_list li a,
.forum_detail_deta > a,
.city_meta_list li a,
.forum_author_text h6 a, 
.forum_fig figure:before,
.overlay:before,
.city_news2_detail .city_meta_list li a,
.city_news2_detail h2,
.city_news2_detail,
.city_team_fig .overlay:before,
.city_top_social,
.city_team_text h4 a,
.city_team_fig .city_top_social li a,
.information_list li a,
.city_project_text,
.blog_tags a,
.city_blog2_fig.fig2 .theam_btn,
.city_blog2_text h4 a,
.city_blog2_fig .overlay:before,
.see_more_btn,
.city_blog2_met,
.city_blog2_met,
.city_blog2_met:after,
.city_blog2_met:before,
.city_blog2_fig .paly_btn,
.city_blog2_text,
.city_meta_list li a,
.city_blog2_list .city_meta_list,
.city_blog2_text h5 a,
.event_categories_list.overlay:before,
.event_grid_list .event_categories_text a,
.list li,
.event_booking_form .theam_btn,
.city_event_tags span a,
.city_sponsor ul li,
.city_sponsor ul li a img,
.venue_list ul li p a,
.city_event2_year .sidebar_search input,
.city_event2_year .list li,
.city_blog_text.event2 .social_icon li a,
.share_icon,
.theam_btn,
.city_event2_list2_fig figure,
.event_categories_date:before,
.event_categories_date,
.city_blog_text.event2 .city_meta_list li a,
.city_blog_text.event2 h4 a,
.event2_menu_list a,
.event_video .overlay:before,
.event_categories_text a,
.categories_list ul li a,
.sidebar_search input,
.city_event2_meeting p,
.city_meta_list li a,
.city_event2_meeting h4,
.city_event2_calender ul li p,
.city_event2_calender ul li h4,
.city_full_event_list.overlay:before,
.theam_btn.btn2,
.city_about_list.list2 .theam_btn,
.accordion-section-title,
.city_senior_team_text h5, 
.city_senior_team_text a,
.city_local_link li a,
.city_health2_text .slick-prev,
.city_health2_text .slick-next,
.city_emergency_slider .slick-prev,
.city_emergency_slider .slick-next,
.city_department_wrap .slick-prev,
.city_department_wrap .slick-next,
.city_health2_service,
.city_health2_service span,
.city_health2_service a,
.city_health2_service h5 a,
.side_notice_text h6 a,
.side_news_text p,
.side_news_text span,
.side_contact_text a,
.side_submit_field .theam_btn.btn2,
input,
textarea,
.city_health_service2,
.see_more_btn,
.city_health_list,
.city_health_list > span.overlay:before,
.city_event2_fig,
.theam_btn.btn2, 
.city_event2_text h4 a,
.city_event2_text ul li a,
.city_visit_text .theam_btn,
.see_more_btn,
.city_busine_detail li a,
.city_project_text,
.city_jobs_item span i,
.city_service2_btn.btn2 .theam_btn,
.pagination ul li a,
.city_blog_grid_text a,
.emergency_service_item h4 a,
.city_emergency_slide_text h5 a,
.city_emergency_call ul li a,
.city_news2_text .theam_btn,
.city_news2_text p,
.special_service_text .theam_btn,
.city_service2_list > span,
.city_service2_text a,
.city_service2_fig,
.city_service2_fig .overlay:before,
.breadcrumb > li a,
.city_project_fig,
.city_project_fig .overlay:before,
.city_request_link ul li a:before,
.widget_service ul li a:before,
.widget_service ul li a,
.city_request_link ul li a,
.city_document_list ul li a,
.city_news_meta li a,
.city_client_row .bx-wrapper .bx-prev,
.city_client_row .bx-wrapper .bx-next,
.city_date_text a,
.city_event_text > a,
.city_date_text a i,
.custom_size,
.event_date,
.city_project_wrap .slick-prev:before, 
.city_project_wrap .slick-next:before,
.city_project_wrap .slick-prev, 
.city_project_wrap .slick-next,
.theam_btn,
.share_icon,
.city_blog_text,
.city_department_wrap .slick-prev:before,
.city_department_wrap .slick-next:before,
.city_department_wrap .slick-prev,
.city_department_wrap .slick-next,
.city_department_text h5,
.city_department_text p,
.city_department_text a,
.city_department_fig,
.city_department_fig .overlay:before,
.city_department_text a,
.city_about_link li a,
.city_top_social ul li a,
.paly_btn,
.city_service_text h5 a,
.city_main_banner .slick-prev:before, 
.city_main_banner .slick-next:before,
.banner_search_field input,
.banner_search_field a,
.theam_btn,
.city_top_social ul li a
{
	-webkit-transition:all 0.3s ease-in-out;
		-moz-transition:all 0.3s ease-in-out;
			-ms-transition:all 0.3s ease-in-out;
				-o-transition:all 0.3s ease-in-out;
					transition:all 0.3s ease-in-out;
} 
.city_blog_fig figure img,
.city_news2_post.post2 figure img,
.blog_post_author figure img,
.blog_post_slide_fig figure img,
.forum_author_fig figure img,
.city_team_fig figure img,
.city_project02_fig figure img,
.event_categories_list figure img,
.city_blog2_fig figure img,
.city_event2_list2_fig figure img,
.event_video figure img,
.city_treatment_list .overlay:before,
.accordion-section-content figure img,
.city_senior_team figure img,
.city_project_fig figure img,
.city_health_list > span i,
.city_event2_fig figure img,
.city_business_fig figure img,
.city_visit_fig figure img,
.city_news2_fig figure img,
.city_emergency_slide_fig figure img,
.city_service_tabs_list figure img,
.city_department2_fig figure img,
.city_service2_fig figure img,
.city_blog_grid .box img,
.city_news_list .box img,
.city_news_fig .box img,
.city_event_fig .box img,
.city_blog_fig .box img,
.city_department_fig .box img,
.box img,
.city_blog_social.social2 .social_icon,
.city_news2_text,
[class^="imghvr-shutter-out-"]::before,
[class*=" imghvr-shutter-out-"]::before,
[class^="imghvr-"] .hover-theme, 
[class*=" imghvr-"] .hover-theme,
.slick-slide.slick-current.slick-active.slick-center .city_project_fig .overlay:before,
.slick-slide.slick-current.slick-active.slick-center .city_project_fig,
.city_blog_fig,
.city_blog_fig.position{
-webkit-transition:all 0.6s ease-in-out;
		-moz-transition:all 0.6s ease-in-out;
			-ms-transition:all 0.6s ease-in-out;
				-o-transition:all 0.6s ease-in-out;
					transition:all 0.6s ease-in-out;
}
.city_news2_fig .overlay:before,
.about_fig figure img{
	-webkit-transition:all 1s ease-in-out;
		-moz-transition:all 1s ease-in-out;
			-ms-transition:all 1s ease-in-out;
				-o-transition:all 1s ease-in-out;
					transition:all 1s ease-in-out;
}
.bg-color{
	background-color:#333;
}
.border-color{
	border-color:#14284b;
}
.color{
	color:#14284b;
}