@import url('https://fonts.googleapis.com/css?family=Montserrat:300i,400,400i,500,500i,600,600i,700,700i,800,800i,900|Poppins:400,400i,500,500i,600,600i,700');	
	/*
    

    font-family: 'Montserrat', sans-serif;

    font-family: 'Poppins', sans-serif;


	*/
*{
	box-sizing:border-box;
	-moz-box-sizing:border-box;
	-webkit-box-sizing:border-box;
	-o-box-sizing:border-box;
	-ms-box-sizing:border-box;	
}
body {
    color: #666;
    font-size:14px;
    font-weight:400;
    background-color: #fff;
    font-family: 'Poppins', sans-serif;
	margin: 0px;
}
/* Heading Typo */
body h1, body h2, body h3, body h4, body h5, body h6 {
    color: #333333;
    font-weight: 400;
    line-height: 1.2;
    font-style: normal;
    margin: 0px 0 12px;
    font-family: 'Montserrat', sans-serif;
}

h1 {
    font-size:50px;
}

h2 {
    font-size: 40px;
}

h3 {
    font-size: 30px;
}

h4 {
    font-size: 26px;
}

h5 {
    font-size: 20px;
}
h6 {
    font-size: 18px;
}

h1 a, h2 a, h3 a, h4 a, h5 a, h6 a {
    color: #333333;
}
/* Paragraph Typo */
p {
	color:#666;
	font-size:15px;
    line-height:22px;
    letter-spacing: 0.2px;
	font-family: 'Poppins', sans-serif;
}
p a {
    color: #fff;
}
/* Link Typo */
a {
    color: #666;
    outline: none;
    text-decoration: none;
}
a:hover, a:focus, a:active {
    outline: none;
    text-decoration: none;
    color: #999999;
}
/* HTML-5 Element Typo */
header,
detail,
article,
aside,
hgroup,
nav,
section,
figure,
figcaption,
footer{
	display:block;	
}
audio,
video,
canvas{
	float:left;
	width:100%;	
}

ul {
   margin:0px;
   padding:0px;
}
li {
    
    list-style: none;
}
ul ul {
    margin:0px;
	padding:0px;
}
ol {
    float: none;
    padding-left: 15px;
    list-style: decimal;
}
ol ol {
    margin-left: 20px;
}
ol li {
	width: 100%;
    list-style: decimal;
}
.city_department_text a,
.section_heading span{
	font-family: 'Montserrat', sans-serif;
}
.city_office_text h6,
.city_about_text h6{
	font-family: 'Poppins', sans-serif;
}
.thumb,
figure {
	float:left;
	width:100%;
	overflow:hidden;
    position: relative;
}
.clear {
    clear: both;
    font-size: 0;
    line-height: 0;
}
.thumb img,
figure img{
	float:left;
	width:100%;
}
img {
    max-width: 100%;
}
strong {
    color: #3d3d3d;
}
iframe {
    float: left;
    width: 100%;
    border: none;
}
/* Form Input Typo */
select {
    width: 100%;
    height: 40px;
    color: #3d3d3d;
    cursor: pointer;
    font-size: 14px;
    font-weight: 300;
    padding: 8px 12px;
    display: inline-block;
    border: 1px solid #d2d2d2;
}
label {
    color: #3d3d3d;
    display: block;
    font-weight: 400;
    margin-bottom: 10px;
}
button {
    border: none;
}
textarea, input[type="text"], input[type="password"], input[type="datetime"], input[type="datetime-local"], input[type="date"], input[type="month"], input[type="time"], input[type="week"], input[type="number"], input[type="email"], input[type="url"], input[type="search"], input[type="tel"], input[type="color"], .uneditable-input {
    outline: none;
    font-size: 15px;
    font-weight: normal;
    line-height: normal;
    display: inline-block;
    vertical-align: middle;
    box-sizing: border-box;
    border: 1px solid #e4e3e3;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
	width:100%;
}
textarea,
input{
	width:100%;
	height:48px;
	padding:10px 15px;
	color:#666666;
}
textarea{
	height:140px;
	resize:none;
}
form p {
    float: left;
    width: 100%;
    position: relative;
}
form p span i {
    top: 13px;
    left: 16px;    
    color: #474747;
    position: absolute;
}
table {
    width: 100%;
    max-width: 100%;
    background-color: transparent;
}
th {
    text-align: left;
}
table > thead > tr > th, table > tbody > tr > th, table > tfoot > tr > th, table > thead > tr > td, table > tbody > tr > td, table > tfoot > tr > td {
    line-height: 2.5;
    padding-left: 7px;
    vertical-align: top;
    border: 1px solid #dddddd;
    border-top: 1px solid #dddddd;
}
table thead tr th {
    border-top: 1px solid #d2d2d2;
}
.table > caption + thead > tr:first-child > th, .table > colgroup + thead > tr:first-child > th, .table > thead:first-child > tr:first-child > th, .table > caption + thead > tr:first-child > td, .table > colgroup + thead > tr:first-child > td, .table > thead:first-child > tr:first-child > td {
	border-top: 1px solid #d2d2d2;
}
table > thead > tr > th {
    vertical-align: bottom;
    border-bottom: 2px solid #d2d2d2;
}
table > caption + thead > tr:first-child > th, table > colgroup + thead > tr:first-child > th, table > thead:first-child > tr:first-child > th, table > caption + thead > tr:first-child > td, table > colgroup + thead > tr:first-child > td, table > thead:first-child > tr:first-child > td 
{
	border-bottom: 0;
}
table > tbody + tbody {
    border-top: 2px solid #d2d2d2;
}
.table > thead > tr > th, .table > tbody > tr > th, .table > tfoot > tr > th, .table > thead > tr > td, .table > tbody > tr > td, .table > tfoot > tr > td {
    padding: 12px 16px;
}
p ins {
    color: #999;
}
dl dd {
    margin-left: 20px;
}
address {
    font-style: italic;
}
:-moz-placeholder { color: #666666; opacity: 1; }
::-moz-placeholder { color: #666666; opacity: 1; }
:-ms-input-placeholder {color: #666666; opacity: 1;}
::-webkit-input-placeholder {color: #666666; opacity: 1;}
