@font-face {
  font-family: 'icomoon';
  src:  url('fonts/icomoon.eot?k08dlq');
  src:  url('fonts/icomoon.eot?k08dlq#iefix') format('embedded-opentype'),
    url('fonts/icomoon.ttf?k08dlq') format('truetype'),
    url('fonts/icomoon.woff?k08dlq') format('woff'),
    url('fonts/icomoon.svg?k08dlq#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
}

[class^="icon2-"], [class*=" icon2-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon2-clock:before {
  content: "\e900";
}
.icon2-telephone:before {
  content: "\e901";
}
.icon2-shout:before {
  content: "\e902";
}
.icon2-fist:before {
  content: "\e903";
}
.icon2-news:before {
  content: "\e904";
}
.icon2-user:before {
  content: "\e905";
}
.icon2-law:before {
  content: "\e906";
}
.icon2-cursor:before {
  content: "\e907";
}
.icon2-trash:before {
  content: "\e908";
}
.icon2-play-button:before {
  content: "\e909";
}
.icon2-social:before {
  content: "\e90a";
}
.icon2-team:before {
  content: "\e90b";
}
.icon2-urban:before {
  content: "\e90c";
}
.icon2-portfolio:before {
  content: "\e90d";
}
.icon2-star:before {
  content: "\e90e";
}
.icon2-plastic:before {
  content: "\e90f";
}
.icon2-sun:before {
  content: "\e910";
}
.icon2-partner:before {
  content: "\e911";
}
.icon2-next:before {
  content: "\e912";
}
.icon2-quote:before {
  content: "\e913";
}
.icon2-mailbox:before {
  content: "\e914";
}
.icon2-document:before {
  content: "\e915";
}
.icon2-question:before {
  content: "\e916";
}
.icon2-speaker:before {
  content: "\e917";
}
.icon2-license:before {
  content: "\e918";
}
.icon2-bag:before {
  content: "\e919";
}
.icon2-home:before {
  content: "\e91a";
}
.icon2-gear:before {
  content: "\e91b";
}
.icon2-bid:before {
  content: "\e91c";
}
.icon2-classroom:before {
  content: "\e91d";
}
.icon2-graphic:before {
  content: "\e91e";
}
.icon2-comment:before {
  content: "\e91f";
}
.icon2-agenda:before {
  content: "\e920";
}
.icon2-help:before {
  content: "\e921";
}
.icon2-comment-1:before {
  content: "\e922";
}
.icon2-shout-1:before {
  content: "\e923";
}
.icon2-teamwork:before {
  content: "\e924";
}
.icon2-smile:before {
  content: "\e925";
}
.icon2-food:before {
  content: "\e926";
}
.icon2-travel:before {
  content: "\e927";
}
.icon2-idea:before {
  content: "\e928";
}
.icon2-calendar:before {
  content: "\e929";
}
.icon2-nuclear:before {
  content: "\e92a";
}
.icon2-brain:before {
  content: "\e92b";
}
.icon2-research:before {
  content: "\e92c";
}
.icon2-tractor:before {
  content: "\e92d";
}
.icon2-train:before {
  content: "\e92e";
}
.icon2-admin:before {
  content: "\e92f";
}
.icon2-human-resources:before {
  content: "\e930";
}
.icon2-health:before {
  content: "\e931";
}
.icon2-travel-1:before {
  content: "\e932";
}
.icon2-paw:before {
  content: "\e933";
}
.icon2-plane:before {
  content: "\e934";
}
.icon2-law-1:before {
  content: "\e935";
}
.icon2-immune-system:before {
  content: "\e936";
}
.icon2-hospital:before {
  content: "\e937";
}
.icon2-virus:before {
  content: "\e938";
}
.icon2-cancer:before {
  content: "\e939";
}
.icon2-pdf:before {
  content: "\e93a";
}
.icon2-doctor:before {
  content: "\e93b";
}
.icon2-health-1:before {
  content: "\e93c";
}
.icon2-charity:before {
  content: "\e93d";
}
.icon2-politician:before {
  content: "\e93e";
}
.icon2-cube:before {
  content: "\e93f";
}
.icon2-demographics:before {
  content: "\e940";
}
.icon2-law-2:before {
  content: "\e941";
}
.icon2-heart:before {
  content: "\e942";
}
.icon2-pie-chart:before {
  content: "\e943";
}
.icon2-green:before {
  content: "\e944";
}
.icon2-healthcare-and-medical:before {
  content: "\e945";
}
.icon2-park:before {
  content: "\e946";
}
.icon2-guard:before {
  content: "\e947";
}
.icon2-write:before {
  content: "\e948";
}
.icon2-train-1:before {
  content: "\e949";
}
.icon2-support:before {
  content: "\e94a";
}
.icon2-left-arrow:before {
  content: "\e94b";
}
.icon2-back:before {
  content: "\e94c";
}
.icon2-back-1:before {
  content: "\e94d";
}
.icon2-next-1:before {
  content: "\e94e";
}
.icon2-next-2:before {
  content: "\e94f";
}
