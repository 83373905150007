/* Layout for search container */
.search {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	text-align: center;
}

.js .search {
	position: fixed;
	z-index: 1000;
	top: 0;
	left: 0;
	width: 100%;
	height: 100vh;
}

.js .search::before {
	content: '';
	position: absolute;
	top: 0;
	right: 0;
	width: 100%;
	height: 100%;
	background: rgba(0,0,0,0.8);
}

.btn--search-close {
	font-size: 2em;
	position: absolute;
	top: 1.25em;
	right: 1.25em;
	display: none;
}

.js .btn--search-close {
	display: block;
}

.search__form {
	margin: 5em 0;
}

.search__input {
	font-family: inherit;
	font-size: 10vw;
	line-height: 1;
	display: inline-block;
	box-sizing: border-box;
	width: 75%;
	padding: 0.05em 0;
	color: #eaba82;
	border-bottom: 5px solid;
}

.search__input::-webkit-input-placeholder {
	/* WebKit, Blink, Edge */
	color: #4a319e;
}

.search__input::-moz-placeholder {
	opacity: 1;
	/* Mozilla Firefox 19+ */
	color: #4a319e;
}

.search__input:-ms-input-placeholder {
	/* Internet Explorer 10-11 */
	color: #4a319e;
}

.search__input::-webkit-search-cancel-button,
.search__input::-webkit-search-decoration {
	-webkit-appearance: none;
}

.search__input::-ms-clear {
	display: none;
}

.search__info {
	font-size: 90%;
	font-weight: bold;
	display: block;
	width: 75%;
	margin: 0 auto;
	padding: 0.85em 0;
	text-align: right;
	color: #eaba82;
}

.search__related {
	display: flex;
	width: 75%;
	text-align: left;
	pointer-events: none;
}

.search__suggestion {
	width: 50%;
}

.search__suggestion:first-child {
	padding: 0 2em 0 0;
}

.search__suggestion:last-child {
	padding: 0 0 0 2em;
}

.search__suggestion h3 {
	font-size: 1.35em;
	margin: 0;
}

.search__suggestion h3::before {
	content: '\21FE';
	display: inline-block;
	padding: 0 0.5em 0 0;
}

.search__suggestion p {
	font-size: 1.15em;
	line-height: 1.4;
	margin: 0.75em 0 0 0;
}

/************************/
/* Transitions 			*/
/************************/

.js .main-wrap {
	position: relative;
	transition: transform 0.5s;
}

.js .main-wrap--move {
	transform: scale3d(0.7,0.7,1);
}

.js .main-wrap--move .btn--search {
	pointer-events: none;
	opacity: 0;
}

.js .search {
	pointer-events: none;
}

.js .search--open {
	pointer-events: auto;
}

.js .search::before {
	opacity: 0;
	transition: opacity 0.5s;
}

.js .search--open::before {
	opacity: 1;
}

/* Close button */
.btn--search-close {
	opacity: 0;
	transition: opacity 0.5s;
}

.search--open .btn--search-close {
	opacity: 1;
}

/* Search form with input and description */

.search__suggestion,
.search__info {
	opacity: 0;
	transform: translate3d(0, 50px, 0);
	transition: opacity 0.4s, transform 0.4s;
}

.search--open .search__suggestion,
.search--open .search__info {
	opacity: 1;
	transform: translate3d(0, 0, 0);
}

.search--open .search__suggestion:first-child {
	transition-delay: 0.15s;
}

.search--open .search__suggestion:nth-child(2) {
	transition-delay: 0.2s;
}

.js .search__input {
	transform: scale3d(0,1,1);
	transform-origin: 0% 50%;
	transition: transform 0.3s;
}

.js .search--open .search__input {
	transform: scale3d(1,1,1);
	transition-duration: 0.5s;
}

@media screen and (max-width: 40em) {
	.btn--search-close {
		font-size: 1.25em;
	}
	.search__suggestion {
		font-size: 80%;
		width: 100%;
	}
	.search__suggestion:nth-child(2),
	.search__suggestion:nth-child(3) {
		display: none;
	}
}
